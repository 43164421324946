<div class="main-container">
  <div class="spacing">
    <app-page-title
      title="إصدار شهادة إعتماد"
      [customClass]="['application-details']"
    ></app-page-title>
  </div>

  <div class="certificate">
    <div class="gradient">
      <img src="assets/images/certificate-gradient.svg" />
    </div>
    <div style="margin-block: 40px">
      <img [src]="logo" />
    </div>
    <div class="flex">
      <div class="certifcate-text-container spacing">
        <div class="spacing">
          <h1
            style="text-align: center; font-size: 24px; font-weight: 700"
            class="certification-content"
          >
            شهادة اعتماد البرامج التدريبية في كفاءة الطاقة
          </h1>
        </div>
        <div class="spacing">
          <p class="certification-content">
            يشهد المركز السعودي لكفاءة الطاقة بأن برنامج:
          </p>
        </div>
        <div>
          <p class="certificate-title">
            {{ certificateData.programName }}
          </p>
          <div>
            <p class="certification-content">
              قد اجتاز متطلبات الاعتماد وفقاً للائحة اعتماد البرامج التدريبية في
              كفاءة الطاقة.
            </p>

            <p class="certification-content">
              حسب النموذج المعتمد، ولذا تم منحه هذه الشهادة لمدة
              <span class="dynamic-data"> 36 شهراً </span>
              تبدأ من تاريخ
              <span class="dynamic-data">
                {{ startDate }}
              </span>
              وتنتهي في تاريخ
              <span class="dynamic-data">
                {{ getCertificateExpiryDate() }}
              </span>
            </p>
          </div>
        </div>

        <div class="flex sign-section">
          <div class="sign-section-item">
            <div class="signature border">
              <span class="golden"> مدير عام المركز</span>
              <img
                src="assets/images/digital-stamp.png"
                alt="digital-stamp-image"
                class="digital-stamp-image"
              />
            </div>
          </div>
          <div class="sign-section-item border">
            <span class="golden"> الرقم المرجعي</span>
            {{ certificateData.referenceId }}
          </div>
          <div class="sign-section-item border">
            <span class="golden"> التاريخ</span>
            {{ certificateData.startDate }}
          </div>
        </div>
      </div>
      <div style="align-self: center">
        <img [src]="circleLogo" alt="circileLogo" />
        <qrcode
          qrdata="https://kld.sociumtech.com/"
          [width]="120"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>
    </div>
    <div class="golden-section"></div>
    <div class="green-section"></div>
  </div>

  <button [disabled]="submitButtonIsClicked" (click)="buttonClickHandler()">
    {{ buttonText }}
  </button>

  <app-modal
    [modalContent]="'submission'"
    [showModal]="showModal"
    (closeModal)="closeModel()"
    [customClass]="'success-modal'"
    [isCloseVisible]="!(isLoading$ | async)"
  >
    <loading-spinner>جاري إصدار الشهادة...</loading-spinner>
    <div *ngIf="!(isLoading$ | async)" class="modal-body">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span class="modal-text">تم إصدار الشهادة بنجاح.</span>
    </div>
  </app-modal>
</div>
