import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { ExpertApplicationService } from './expert-application.service';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'expert-application-view',
  templateUrl: './expert-application-view.component.html',
  styleUrl: './expert-application-view.component.scss',
})
export class ExpertApplicationView {
  showModal: boolean = true;
  @Input() id: string | number;
  @Input() files: any[];
  @Input() requestData: any;
  expertForm: FormGroup;
  fileURL: string = '';
  tabDataIndex: number = 0;
  fileIndex: number = 0;
  criteriaLookup: any;
  dataGroupFiles: any[];
  showTrainingData: boolean = true;
  fieldVisitForm: FormGroup;
  showSubmitModal: boolean = false;
  submittedSuccessfully: boolean = false;
  isSubmitButtonClicked: boolean = false;
  requestStatus: number;

  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;

  constructor(
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private submitService: ExpertApplicationService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.fetchCriteriaLookup();

    this.expertForm = this.formBuilder.group({
      needsFieldVisit: [
        this.requestData?.trainingOrganizationFieldVisitForm?.fieldVisitForms
          ?.length
          ? true
          : false,
      ],
      hasGeneralNotes: [false],
      generalNotes: [{ value: '', disabled: true }],
    });

    this.requestStatus =
      this.requestData?.workFlowTrainingOrganization
        ?.trainingOrganizationStatusLookupId;

    this.expertForm.get('hasGeneralNotes').valueChanges.subscribe((value) => {
      const generalNotesControl = this.expertForm.get('generalNotes');
      if (value) {
        generalNotesControl.addValidators(Validators.required);
        generalNotesControl.enable();
      } else {
        generalNotesControl.clearValidators();
        generalNotesControl.reset();
        generalNotesControl.disable();
      }
    });

    this.fieldVisitForm = this.formBuilder.group({
      criteria_1:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[0]?.standard || '',
      criteria_2:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[1]?.standard || '',
      criteria_3:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[2]?.standard || '',
      criteria_4:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[3]?.standard || '',
      criteria_5:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[4]?.standard || '',
      criteria_6:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[5]?.standard || '',
      criteria_7:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[6]?.standard || '',
      criteria_8:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[7]?.standard || '',
      criteria_9:
        this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[8]?.standard || '',
      criteria_1_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[0]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[0]?.evaluation) ||
        '',
      criteria_2_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[1]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[1]?.evaluation) ||
        '',
      criteria_3_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[2]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[2]?.evaluation) ||
        '',
      criteria_4_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[3]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[3]?.evaluation) ||
        '',
      criteria_5_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[4]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[4]?.evaluation) ||
        '',
      criteria_6_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[5]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[5]?.evaluation) ||
        '',
      criteria_7_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[6]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[6]?.evaluation) ||
        '',
      criteria_8_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[7]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[7]?.evaluation) ||
        '',
      criteria_9_evaluation:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[8]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[8]?.evaluation) ||
        '',
      criteria_1_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[0]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[0]?.notes) ||
        '',
      criteria_2_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[1]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[1]?.notes) ||
        '',
      criteria_3_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[2]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[2]?.notes) ||
        '',
      criteria_4_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[3]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[3]?.notes) ||
        '',
      criteria_5_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[4]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[4]?.notes) ||
        '',
      criteria_6_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[5]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[5]?.notes) ||
        '',
      criteria_7_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[6]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[6]?.notes) ||
        '',
      criteria_8_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[7]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[7]?.notes) ||
        '',
      criteria_9_notes:
        (this.requestData?.trainingOrganizationFieldVisitForm
          ?.fieldVisitForms?.[8]?.standard &&
          this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[8]?.notes) ||
        '',
    });

    this.dataGroupFiles =
      this.requestData.trainingProgramDocuments?.trainingProgramDocumentMapping;
  }

  getFileId() {
    return this.requestData.trainingProgramDocuments
      ?.trainingProgramDocumentMapping?.[this.tabDataIndex].files[
      this.fileIndex
    ].id;
  }

  getFileUrl() {
    return this.getFile(this.getFileId());
  }

  getFile(id: number) {
    this.auditService.getFile(id).subscribe({
      next: (data) => {
        this.fileURL = data;
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  hideModal(): void {
    this.showModal = false;
    this.fileURL = '';
  }

  fetchCriteriaLookup() {
    this.auditService.getCriteriaLookup(UserRole.TechnicalAdvisor).subscribe({
      next: (data) => {
        this.criteriaLookup = data.data;
      },
      error: (error) => {
        console.log('Error Retrieving Criteria', error);
      },
    });
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  hasCriteriaValue(): boolean {
    const first9CriteriaFields = Array.from(
      { length: 9 },
      (_, i) => `criteria_${i + 1}`,
    );

    return first9CriteriaFields.some(
      (field) => !!this.fieldVisitForm.get(field).value,
    );
  }

  isButtonDisabled(): boolean {
    return (
      !this.expertForm.valid ||
      (this.expertForm.get('needsFieldVisit').value &&
        !this.hasCriteriaValue()) ||
      this.isSubmitButtonClicked
    );
  }

  submitHandler() {
    this.isSubmitButtonClicked = true;
    const fieldVisitFilledData: any[] = [];
    for (let i = 1; i < 10; i++) {
      if (
        this.fieldVisitForm.value[`criteria_${i}`] ||
        this.requestData?.trainingOrganizationFieldVisitForm?.fieldVisitForms?.[
          i - 1
        ]?.id
      )
        fieldVisitFilledData.push({
          ...(this.requestData?.trainingOrganizationFieldVisitForm
            ?.fieldVisitForms?.[i - 1]?.id
            ? {
                id: this.requestData?.trainingOrganizationFieldVisitForm
                  ?.fieldVisitForms?.[i - 1]?.id,
              }
            : {}),
          standard: this.fieldVisitForm.value[`criteria_${i}`] || '',
          evaluation:
            this.fieldVisitForm.value[`criteria_${i}_evaluation`] || '',
          notes: this.fieldVisitForm.value[`criteria_${i}_notes`] || '',
        });
    }

    const evaluationData: any = {
      TrainingOrganizationStatusEnum:
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary,
      TrainingOrganizationId: this.id,
      HasGeneralComments: this.expertForm.value.hasGeneralNotes,
      HasFieldVisit: this.expertForm.value.needsFieldVisit,
      FieldVisitForms:
        fieldVisitFilledData.length && this.expertForm.value.needsFieldVisit
          ? fieldVisitFilledData
          : undefined,
      // FieldVisitFormFile: this.fieldVisitForm.value.fieldVisitFile,
      TrainingProgramDocumentNotes: this.expertForm.value.hasGeneralNotes
        ? [{ id: 0, note: this.expertForm.value.generalNotes }]
        : undefined,
    };
    this.submitService
      .submitEvaluation(evaluationData)
      .subscribe((response) => {
        if (response) {
          this.showSubmitModal = true;
          this.submittedSuccessfully = true;
        }
      });
  }

  handleSaveEvaluationData(data: any) {
    this.dataGroupFiles[
      data.tabDataIndex
    ].programDocumentLookup.programDocumentCriteriaMappings =
      data.body.programDocumentCriteriaMappings;

    this.dataGroupFiles[data.tabDataIndex].documentNotes = [
      ...this.dataGroupFiles[data.tabDataIndex].documentNotes,
      ...data.body.documentNotes,
    ];
  }

  accordionVisibilityHandler(criteriasArray: any[]) {
    return (
      criteriasArray?.length &&
      criteriasArray?.filter(
        (item) =>
          item.isPassed &&
          item?.documentCriteriaLookup?.roleId ===
            this.authService.getUserRole(),
      ).length
    );
  }

  criteriaFilter(criteriaArr) {
    return criteriaArr.filter(
      (item) =>
        item?.documentCriteriaLookup?.roleId === this.authService.getUserRole(),
    );
  }

  closeModel() {
    this.showSubmitModal = false;
    this.router.navigate(['/dashboard']);
  }

  viewFile(sectionIndex: number, currentFileIndex: number) {
    this.tabDataIndex = sectionIndex;
    this.fileIndex = currentFileIndex;
    this.showModal = true;
    this.showTrainingData = false;
    this.inspectorComponent.tabDataIndex = sectionIndex;
    this.inspectorComponent.fileIndex = currentFileIndex;
    this.inspectorComponent.getFileUrl(sectionIndex, currentFileIndex);
  }

  getDocumentNote(document: any) {
    return document.documentNotes.filter(
      (note: any) => note.user.role === 'المستشار الفني',
    );
  }
}
