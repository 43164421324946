<app-header includeSubHeader="true"></app-header>
<div class="container">
  <div class="title__container">سياسة الخصوصية</div>

  <div class="policy_container">
    <div class="policy_content">
      <div class="privacy_sub_title">سياسة الخصوصية</div>
      <div>
        <p class="privacy_sub">
          يرجى قراءة اتفاقية مستوى الخدمة وسياسة الخصوصية وأي اتفاقيات أخرى
          مذكورة في هذه الوثيقة بعناية.
        </p>
      </div>
      <div>
        <h3>الخصوصية وسرية المعلومات</h3>
        <p>
          تضع منصة المركز السعودي لكفاءة الطاقة سرية معلومات مستخدميها وزوارها
          على رأس قائمة الأولويات، وتبذل إدارة المنصة كل جهودها لتقديم خدمة
          عالية الجودة لجميع المستفيدين، والحفاظ على الخصوصية وسرية المعلومات
          الموضحة أدناه هي جزء من شروط وأحكام استخدام منصة المركز السعودي لكفاءة
          الطاقة.
        </p>
        <p>
          لا تقوم منصة المركز السعودي لكفاءة الطاقة بجمع معلومات شخصية عنك عندما
          تزور منصتها إلا إذا اخترت على وجه التحديد وعن علم تقديم هذه المعلومات
          إلينا. إذا اخترت تقديم معلوماتك، فإننا نستخدمها فقط لتلبية طلبك للحصول
          على معلومات أو خدمات وفقًا لهذه السياسات، وباستخدام منصة المركز
          السعودي لكفاءة الطاقة فإنك توافق على سياسة الخصوصية والسرية هذه.
        </p>
        <p>
          يجب على زوار المنصة والمستفيدين من خدماتها المراجعة المستمرة لشروط
          ومبادئ الخصوصية وسرية المعلومات لمعرفة أي تحديثات تطرأ عليهم، مع العلم
          أن إدارة المنصة غير مطالبة بالإعلان عن أي تحديثات لهذه الشروط والمبادئ
          واستخدامك للمنصة يعني أنك على علم وموافقة على تلك الشروط والمبادئ
          والتعديلات المستمرة التي تطرأ عليها.
        </p>
        <p>
          منصة المركز السعودي لكفاءة الطاقة ليست مسؤولة تحت أي ظرف من الظروف عن
          أي أضرار مباشرة أو غير مباشرة أو عرضية أو تبعية أو خاصة أو استثنائية
          تنشأ عن استخدام أو عدم القدرة على استخدام هذه المنصة.
        </p>
      </div>

      <div>
        <h3>أمن المعلومات الشخصية</h3>
        <p>
          تم إعداد وثيقة خصوصية وسرية المعلومات لمساعدة الزوار والمستخدمين على
          فهم طبيعة البيانات المجمعة منهم عند زيارة المنصة وكيفية التعامل معها.
        </p>
        <p>
          تتخذ إدارة المنصة الإجراءات والتدابير المناسبة والملائمة للحفاظ على
          المعلومات الشخصية الموجودة لديها بطريقة آمنة، بما يضمن حمايتها من
          الضياع أو الوصول غير المصرح به أو سوء الاستخدام أو التعديل والإفصاح
          غير المصرح به، ومن أهم الإجراءات المعمول بها في المركز لحماية
          المعلومات الشخصية للزائر:
        </p>
        <ol>
          <li>
            إجراءات وتدابير صارمة لحماية أمن المعلومات، والتكنولوجيا التي
            نستخدمها لمنع الاحتيال والوصول غير المصرح به إلى أنظمتنا.
          </li>
          <li>
            التحديث المنتظم والدوري لإجراءات وضوابط الحماية التي تلبي أو تتجاوز
            المعايير القياسية.
          </li>
          <li>
            موظفونا مؤهلون ومدربون على احترام سرية المعلومات الشخصية لزوارنا.
          </li>
        </ol>
      </div>
      <div>
        <h3>جمع المعلومات الشخصية</h3>
        <p>
          بمجرد زيارة المستخدم لمنصة المركز السعودي لكفاءة الطاقة، يقوم خادم
          المنصة بتسجيل عنوان IP الخاص بالمستخدم وتاريخ ووقت الزيارة والعنوان
          URL الخاص بأي موقع يتم إحالتك منه إلى منصة المركز السعودي لكفاءة
          الطاقة.
        </p>
        <p>
          تقوم معظم مواقع الويب، بمجرد زيارتها، بوضع ملف صغير على القرص الصلب
          لجهاز الزائر (المتصفح)، ويسمى هذا الملف "ملفات تعريف الارتباط"، وملفات
          تعريف الارتباط هي ملفات نصية، وتحتوي هذه الملفات النصية على معلومات
          تسمح الموقع الذي قام بإيداعها لاسترجاعها عند الحاجة إليها أثناء زيارة
          المستخدم التالية للموقع، ومن هذه المعلومات المحفوظة:
        </p>
        <ol>
          <li>تذكر اسم المستخدم وكلمة المرور الخاصة بك.</li>
          <li>حفظ إعدادات الصفحة إذا كانت متوفرة على المنصة.</li>
          <li>حفظ الألوان التي اختارها المستخدم.</li>
          <li>عدم السماح بإمكانية التصويت أكثر من مرة لنفس المستخدم.</li>
        </ol>
        <p>
          قد لا يضطر المتصفح (الزائر) إلى إدخال كلمة المرور في كل زيارة حيث
          سيتمكن نظام الموقع من اكتشافها من خلال ملفات تعريف الارتباط، أو قد
          يمنع المستخدم من تكرار عملية التصويت إذا كان قد قام بالتصويت
          بالالأجراء وهكذا. وعلى هذا الأساس فإن منصة المركز السعودي لكفاءة
          الطاقة ستستخدم المعلومات الموجودة في ملفات تعريف الارتباط لأغراض فنية
          خاصة بها عند زيارتك لها أكثر من مرة، ويمكن للمنصة تغيير المعلومات
          الموجودة داخل ملفات تعريف الارتباط أو إضافة معلومات جديدة كلما قمت
          بالزيارة منصة المركز السعودي لكفاءة الطاقة.
        </p>
        <p>
          إذا كنت تستخدم تطبيقًا مباشرًا أو ترسل لنا بريدًا إلكترونيًا عبر منصة
          المركز السعودي لكفاءة الطاقة تزودنا فيه ببيانات شخصية، فقد نشارك
          البيانات الضرورية مع جهات أو إدارات أخرى، وذلك لخدمتك بشكل أكثر
          فعالية، وسنقوم بذلك عدم مشاركة بياناتك الشخصية مع الجهات غير الحكومية
          إلا إذا تم ترخيصها من قبل السلطات المختصة لأداء خدمات حكومية محددة.
          ومن خلال تقديم بياناتك ومعلوماتك الشخصية عبر منصة المركز السعودي
          لكفاءة الطاقة، فإنك توافق بشكل كامل على تخزين تلك البيانات ومعالجتها
          واستخدامها من قبل السلطات السعودية. نحن نحتفظ بالحق في جميع الأوقات في
          الكشف عن أي معلومات إلى السلطات المختصة، عندما يكون ذلك ضروريًا
          للامتثال لأي قانون أو لائحة أو طلب حكومي.
        </p>
        <p>
          أنت وحدك المسؤول عن اكتمال وصحة وصدق البيانات التي ترسلها عبر هذه
          المنصة.
        </p>
      </div>
      <div>
        <h3>حماية خصوصيتك</h3>
        <p>ولمساعدتك على حماية معلوماتك الشخصية، نوصي بما يلي:</p>
        <ol>
          <li>
            اتصل بنا على الفور عندما تعتقد أن شخصًا ما قد حصل على كلمة المرور أو
            رمز الاستخدام أو غيرها من المعلومات السرية.
          </li>
          <li>
            لا تقم بإعطاء معلومات سرية عبر الهاتف أو الإنترنت إلا إذا كنت تعرف
            هوية الشخص أو الطرف الذي يتلقى المعلومات.
          </li>
          <li>
            استخدم متصفحًا آمنًا عند إتمام المعاملات عبر الإنترنت مع التطبيقات
            غير المستخدمة والمغلقة على الشبكة، وتأكد من تحديث برنامج مكافحة
            الفيروسات لديك دائمًا.
          </li>
          <li>
            وفي الحالة وجود أي استفسارات أو آراء حول مبادئ الخصوصية، يمكنكم
            التواصل مع إدارة المنصة عبر نموذج الاتصال الموجود في صفحة اتصل بنا.
          </li>
        </ol>
        <p>
          للحفاظ على بياناتك الشخصية، يتم تأمين التخزين الإلكتروني والبيانات
          الشخصية المنقولة باستخدام تقنيات الأمان المناسبة.
        </p>
        <p>
          قد تحتوي هذه المنصة على روابط إلكترونية لمواقع أو منصات قد تستخدم
          أساليب لحماية المعلومات والخصوصية تختلف عن الطرق المستخدمة من قبلنا،
          ونحن غير مسؤولين عن محتويات وطرق وخصوصية هذه المواقع الأخرى، وننصحك
          بما يلي: راجع إشعارات الخصوصية الخاصة بتلك المواقع.
        </p>
      </div>
      <div>
        <h3>إرسال رسائل البريد الإلكتروني</h3>
        <p>
          عندما تستفسر أو تطلب معلومات حول خدمة معينة أو إذا تم تقديم معلومات
          إضافية باستخدام أي من وسائل الاتصال مع المركز سواء كانت إلكترونية أو
          غير إلكترونية، مثل طلب الاستفسار على موقعنا، فسوف نستخدم عنوان بريدك
          الإلكتروني للرد على استفساراتك، ومن الممكن حفظ عنوان بريدك ورسالتك
          وإجابتنا عليها لأغراض مراقبة الجودة، وقد نقوم بذلك لأغراض قانونية
          وتنظيمية.
        </p>
      </div>
    </div>
  </div>
</div>
