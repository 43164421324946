<div class="container">
  <div class="evaluation-container">
    <app-document-inspector
      #inspectorComponent
      *ngIf="trainingProgram"
      [isCheckbox]="false"
      [openModal]="openModal"
      [fileIndex]="fileIndex"
      [dataGroupFiles]="trainingProgramDocuments"
      [tabDataIndex]="tabDataIndex"
      (closeModal)="openModal = false"
      [customClass]="'initial-document'"
      [showCriteriasWithoutNoteBox]="showCriteriasWithoutNoteBox"
      (showTrainingDataChange)="onShowTrainingDataChange($event)"
      (showPledgeChange)="onShowPledge($event)"
      [requestStatus]="requestStatus"
      (saveEvaluationData)="handleSaveEvaluationData($event)"
    >
      <div
        *ngIf="showTrainingData && !showPledge"
        class="tab-data training-program__data"
      >
        <div class="training-body__title">بيانات البرنامج التدريبي</div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">اسم البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameAr }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">
                اسم البرنامج التدريبي باللغة الإنجليزية
              </div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameEn }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
              <div class="field-name">نوع البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramType }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
              <div class="field-name">مستوى البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programLevelName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramDuration }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.totalTrainingHours }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="target-icon"
              ></app-svg-icon>
              <div class="field-name">الهدف العام من البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programObjective }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
              <div class="field-name">لغة البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.languageLookupName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
              <div class="field-name">الفئة المستهدفة</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.targetCategory }}
            </div>
          </div>
        </div>
        <div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
              <div class="field-name">وصف البرنامج التدريبي</div>
            </div>
            <div class="field-value training-program-description">
              {{ trainingProgram.description }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content__container tab-data"
        *ngIf="generalCommitment && showPledge"
      >
        <div class="training-body__title">التعهد العام</div>

        <div class="pledge-container">
          <span class="pledge-intro"
            >السادة المركز السعودي لكفاءة الطاقة حفظهم االله</span
          >
          <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
          <p class="pledge-para">
            نتعهد نحن {{ trainingOrganization.organizationName }}، بالالتزام
            بلائحة اعتماد البرامج التدريبية والدليل الإرشادي للائحة وكافة
            التعاميم والتعليمات التي يصدرها المركز السعودي لكفاءة الطاقة بهذا
            الشأن.
          </p>
          <span class="pledge-para salute">وتقبلوا وافر التحية والتقدير</span>
          <div class="applicant-container">
            <span class="applicant-name__text">اسم مقدم الطلب</span>
            <span class="applicant-name">{{
              generalCommitment.applicantsName
            }}</span>
          </div>
          <div *ngIf="imgUrl" class="electronic-signature__container">
            <span>التوقيع الإلكتروني</span>
            <div class="electronic-signature">
              <div class="signature-container">
                <img
                  [src]="imgUrl"
                  alt="Electronic Signature"
                  class="signature-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-document-inspector>

    <app-training-body-data [trainingOrganization]="trainingOrganization" />

    <app-svg-icon
      name="long-two-sided-arrow"
      class="two-sided-arrow"
    ></app-svg-icon>

    <app-page-title
      [title]="'مراحل التقييم'"
      [customClass]="'application-details'"
    ></app-page-title>

    <div>
      <app-page-title
        [title]="'internalAuditorStandards'"
        [customClass]="'medium-font'"
      ></app-page-title>
      <div
        *ngFor="
          let element of trainingProgramDocuments;
          let isFirst = first;
          let sectionIndex = index
        "
      >
        <div *ngIf="!isFirst" class="divider-custom"></div>
        <div class="application-wrapper">
          <div class="label-container">
            <div class="information">
              {{ element.programDocumentLookup.name }}
            </div>
            <ul
              class="field-subtitles"
              *ngIf="
                element.programDocumentLookup.programDocumentRequirements.length
              "
            >
              <li
                *ngFor="
                  let subtitle of element.programDocumentLookup
                    .programDocumentRequirements
                "
              >
                {{ subtitle }}
              </li>
            </ul>
          </div>
          <div class="files-container">
            <div class="active-tab">
              <file-icon
                *ngFor="let file of element.files; let currentFileIndex = index"
                (click)="viewFile(sectionIndex, currentFileIndex)"
                [file]="file"
                [index]="currentFileIndex"
                [readOnly]="true"
              ></file-icon>
              <div class="items-container" *ngIf="!element.files.length">
                <div
                  class="file-item__container"
                  (click)="
                    trainingProgramAndPledgeIconsClickHandler(sectionIndex)
                  "
                >
                  <app-svg-icon
                    name="file-icon"
                    class="file-icon"
                  ></app-svg-icon>
                  <div class="file-info">
                    <span class="file-name">{{
                      element.programDocumentLookup.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <file-download
              [files]="element.files"
              *ngIf="element.files.length && userCanDownloadFiles"
            ></file-download>
          </div>
          <div
            class="checked-section"
            *ngIf="
              filterCriteriaMapping(
                element.programDocumentLookup.programDocumentCriteriaMappings,
                internalAuditorRoleId
              ).length
            "
          >
            <div
              *ngFor="
                let item of filterCriteriaMapping(
                  element.programDocumentLookup.programDocumentCriteriaMappings,
                  internalAuditorRoleId
                )
              "
            >
              <div class="criteria-list" *ngIf="item.isPassed">
                <span>{{ item.documentCriteriaLookup.name }}</span>
                <img [src]="images.checked" />
              </div>
            </div>
          </div>

          <!-- <div *ngIf="item.isPassed" class="notes">
            <input
              class="criteria-note-field"
              disabled
              type="text"
              [value]="
                item.documentCriteriaLookup.criteriaNotes.at(-1)?.name || ''
              "
            />
          </div> -->
          <!-- <div
            *ngFor="
            let comment of item.documentCriteriaLookup.criteriaNotes.at(-1)
            "
            >
            <app-comment-section [data]="comment"></app-comment-section>
          </div> -->
          <div
            *ngIf="
              documentNotesFilter(element.documentNotes, internalAuditorRoleId)
                .length
            "
          >
            <accordion
              [customClass]="'notes-accordion notes-accordion-justified'"
              [header]="'ملاحظات أخرى'"
            >
              <div class="user-notes">
                <div
                  *ngFor="
                    let note of documentNotesFilter(
                      element.documentNotes,
                      internalAuditorRoleId
                    )
                  "
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note"></app-comment-section>
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showTechnicalAdvisorSection">
      <app-page-title
        title="معايير الخبير الفني"
        [customClass]="'medium-font'"
      ></app-page-title>
      <div
        *ngFor="
          let element of technicalAdvisorTrainingProgramDocuments;
          let isFirst = first
        "
      >
        <div *ngIf="!isFirst" class="divider-custom"></div>
        <div class="application-wrapper">
          <div class="label-container">
            <div class="information">
              {{ element.programDocumentLookup.name }}
            </div>
            <ul
              *ngIf="
                element.programDocumentLookup.programDocumentRequirements.length
              "
              class="field-subtitles"
            >
              <li
                *ngFor="
                  let subtitle of element.programDocumentLookup
                    .programDocumentRequirements
                "
              >
                {{ subtitle }}
              </li>
            </ul>
          </div>

          <div class="files-container">
            <div class="active-tab">
              <file-icon
                *ngFor="let file of element.files; let currentFileIndex = index"
                (click)="viewFile(element.index, currentFileIndex)"
                [file]="file"
                [index]="currentFileIndex"
                [readOnly]="true"
              ></file-icon>
              <div class="items-container" *ngIf="!element.files.length">
                <div
                  class="file-item__container"
                  (click)="
                    trainingProgramAndPledgeIconsClickHandler(element.index)
                  "
                >
                  <app-svg-icon
                    name="file-icon"
                    class="file-icon"
                  ></app-svg-icon>
                  <div class="file-info">
                    <span class="file-name">{{
                      element.programDocumentLookup.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <file-download
              [files]="element.files"
              *ngIf="element.files.length && userCanDownloadFiles"
            ></file-download>
          </div>
          <div
            class="checked-section"
            *ngIf="
              filterCriteriaMapping(
                element.programDocumentLookup.programDocumentCriteriaMappings,
                internalAuditorRoleId
              ).length
            "
          >
            <div
              *ngFor="
                let item of filterCriteriaMapping(
                  element.programDocumentLookup.programDocumentCriteriaMappings,
                  technicalAdvisorRoleId
                )
              "
            >
              <div class="criteria-list" *ngIf="item.isPassed">
                <span>{{ item.documentCriteriaLookup.name }}</span>
                <img [src]="images.checked" />
              </div>
            </div>
          </div>
          <div
            *ngIf="
              documentNotesFilter(element.documentNotes, technicalAdvisorRoleId)
                .length
            "
          >
            <accordion
              [customClass]="'notes-accordion notes-accordion-justified'"
              [header]="'ملاحظات أخرى'"
            >
              <div class="user-notes">
                <div
                  *ngFor="
                    let note of documentNotesFilter(
                      element.documentNotes,
                      technicalAdvisorRoleId
                    )
                  "
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note"></app-comment-section>
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        generalNotesFilter(requestData?.trainingProgramDocumentNote).length
      "
    >
      <div class="divider-custom"></div>

      <general-note
        *ngFor="
          let noteElement of generalNotesFilter(
            requestData?.trainingProgramDocumentNote
          )
        "
        [noteData]="noteElement"
        [trainingOrganizationId]="id"
        [requestStatus]="requestStatus"
      ></general-note>
    </div>

    <div class="divider-custom"></div>

    <app-page-title
      *ngIf="fieldVisitData?.length"
      [title]="'نموذج الزيارة الميدانية'"
      [customClass]="'application-details'"
    ></app-page-title>

    <div *ngIf="fieldVisitData?.length" class="field-visit-grid">
      <div class="field-visit-row">
        <h3 class="field-visit-criteria-column">المعيار</h3>
        <h3 class="field-visit-evaluation-column">التقييم</h3>
        <h3 class="field-visit-notes-column">الملاحظات</h3>
      </div>
      <div class="field-visit-row" *ngFor="let criteria of fieldVisitData">
        <p class="field-visit-criteria-column">
          {{ criteria.standard || '-' }}
        </p>
        <p class="field-visit-evaluation-column">
          {{ criteria.evaluation || '-' }}
        </p>
        <p class="field-visit-notes-column">
          {{ criteria.notes || '-' }}
        </p>
      </div>
    </div>

    <app-dialog
      [id]="id"
      [generalNotes]="generalNotes"
      [trainingOrganizationStatusEnum]="
        ApprovedByCommitteeSecrateryAndWaitingTechnicalAdvisor
      "
      [visible]="openAdvisorModal"
      (onHide)="onDialogHide($event)"
      [isChecked]="true"
      [userIsCommitteeSecretary]="true"
      [proposedTechnicalAdvisor]="requestData?.proposedTechnicalAdvisor?.user"
      [proposedWorkingHours]="
        requestData?.proposedTechnicalAdvisor?.workingHours
      "
      (onSuccess)="showSuccessModal = $event"
    ></app-dialog>

    <div class="success-modal__container">
      <app-modal
        [modalContent]="'submission'"
        [showModal]="showSuccessModal"
        (closeModal)="closeModel()"
        [customClass]="'success-modal'"
        [isCloseVisible]="!(isLoading | async)"
      >
        <loading-spinner>جاري ارسال الطلب...</loading-spinner>
        <div *ngIf="!(isLoading | async)" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{ modalText }}</span>
        </div>
      </app-modal>
    </div>

    <button
      [disabled]="isSubmitButtonDisabled"
      (click)="submitButtonClickHandler(requestStatus)"
      class="submit-button"
    >
      {{ submitButtonTextHandler(requestStatus) }}
    </button>
  </div>
</div>
