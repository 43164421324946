import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { Status } from '@app/dashboard/types/status';

@Component({
  selector: 'app-application-details-user-role',
  templateUrl: './application-details-user-role.component.html',
  styleUrl: './application-details-user-role.component.scss',
})
export class ApplicationDetailsUserRoleComponent implements OnInit {
  @Input() title: string = '';
  @Input() rowIndex: number;
  @Input() requestData: any;
  rowData: any;
  isClicked: boolean = false;
  openModal: boolean = false;
  fileURL: string = '';
  fileIndex: number = 0;
  isChecked: boolean = false;
  fieldVisit: boolean = false;
  trainingOrganization: any;
  trainingProgram: any;
  generalCommitment: any;
  trainingProgramDocuments: any;
  test: any;
  pdfUrl: any;
  base64String: string | ArrayBuffer;
  tabDataIndex: number = 0;
  showTrainingData: boolean = true;
  showPledge: boolean = false;
  imgUrl: string = '';
  criteriaLookup: any;
  id: number;
  openAdvisorModal: boolean = false;
  generalNotes: string = '';
  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;
  criteria: any;
  success: boolean = false;
  requestStatus: number;
  requestIsReviewedByInternalAuditor: boolean = false;
  sentToTrainingOrganization: boolean;
  sendToTrainingOrgButtonIsClicked: boolean = false;

  constructor(
    private bodyScrollService: BodyScrollService,
    private auditService: AuditService,
    private router: Router,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  ngOnInit() {
    this.trainingOrganization = this.requestData.trainingOrganization;
    this.id = this.trainingOrganization.id;
    this.trainingProgram = this.requestData.trainingProgram;
    this.generalCommitment = this.requestData.generalCommitment;
    this.requestStatus =
      this.requestData.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

    if (this.requestStatus > Status.NewOrderWaitingInternalAuditor)
      this.requestIsReviewedByInternalAuditor = true;
    this.trainingProgramDocuments =
      this.requestData.trainingProgramDocuments
        ?.trainingProgramDocumentMapping || [];
    this.getElecSignature();
    this.fetchCriteriaLookup();
  }

  handleGeneralNotesChange(value: string) {
    this.generalNotes = value;
  }
  getElecSignature() {
    if (this.generalCommitment?.generalCommitmentFile?.file.id)
      this.auditService
        .getFile(this.generalCommitment.generalCommitmentFile.file.id)
        .subscribe({
          next: (data) => {
            this.imgUrl = data;
          },
          error: (error) => {
            console.log('error', error);
          },
        });
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  fetchCriteriaLookup() {
    this.auditService.getCriteriaLookup(UserRole.InternalAuditor).subscribe({
      next: (data) => {
        this.criteriaLookup = data.data;
      },
      error: (error) => {
        console.log('Error Retrieving Criteria', error);
      },
    });
  }

  getFileId(tabIndex: number, fileIndex: number) {
    return this.trainingProgramDocuments[tabIndex].files[fileIndex].id;
  }

  getFileUrl(tabIndex: number, fileIndex: number): void {
    this.getFile(this.getFileId(tabIndex, fileIndex)).subscribe({
      next: (data) => {
        this.fileURL = data;
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  getFile(id: number): Observable<any> {
    return this.auditService.getFile(id);
  }

  showData() {
    this.isClicked = true;
    this.openModal = true;
    this.bodyScrollService.toggleBodyScroll(false);
    // this.getFileUrl(0, 0);
  }

  viewFile(activeTabIndex: number, index: number): void {
    this.tabDataIndex = activeTabIndex;
    this.fileIndex = index;
    this.showTrainingData = activeTabIndex == 0;
    this.inspectorComponent.showTrainingData = activeTabIndex == 0;
    this.inspectorComponent.tabDataIndex = activeTabIndex;
    this.inspectorComponent.fileIndex = index;
    this.inspectorComponent.showPledge =
      activeTabIndex == this.trainingProgramDocuments.length - 1;
    this.showPledge =
      activeTabIndex == this.trainingProgramDocuments.length - 1;
    this.openModal = true;
    this.inspectorComponent.getFileUrl(activeTabIndex, index);
  }

  hideModal(): void {
    this.openModal = false;
    this.fileURL = '';
    this.bodyScrollService.toggleBodyScroll(true);
  }

  handleCheckboxChange() {
    this.isChecked = !this.isChecked;
  }

  handleSaveEvaluationData(body: any) {
    this.trainingProgramDocuments[
      body.tabDataIndex
    ].programDocumentLookup.programDocumentCriteriaMappings =
      body.body.programDocumentCriteriaMappings;

    this.trainingProgramDocuments[body.tabDataIndex].documentNotes = [
      ...this.trainingProgramDocuments[body.tabDataIndex].documentNotes,
      ...body.body.documentNotes,
    ];
  }

  showFielVisit() {
    this.fieldVisit = !this.fieldVisit;
  }

  technicalAdvisorModal() {
    this.openAdvisorModal = true;
  }

  onDialogHide(status: boolean) {
    // Handle the variable status when the modal is closed
    this.openAdvisorModal = status;
  }

  handleSuccess(status: boolean): void {
    // Do something with the success status
    console.log('Success status:', status);
    this.success = status;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  accordionVisibilityHandler(criteriasArray: any[]) {
    return (
      criteriasArray?.length &&
      criteriasArray?.filter(
        (item) =>
          item.isPassed &&
          item.documentCriteriaLookup.roleId === UserRole.InternalAuditor,
      ).length
    );
  }

  closeModel() {
    this.success = false;
    this.router.navigate(['/dashboard']);
  }

  sendApplicationToTrainingOrg() {
    this.sendToTrainingOrgButtonIsClicked = true;
    const body = {
      id: this.id,
      IsEditable: true,
    };

    this.auditService.updateEditableStatus(body).subscribe({
      next: () => {
        this.sentToTrainingOrganization = true;
        this.success = true;
      },
      error: (error) => {
        console.log('Error sending the data', error);
      },
    });
  }

  filterAccordionCriterias(criteriaArray: any[]) {
    return criteriaArray.filter(
      (item) => item.documentCriteriaLookup.roleId === UserRole.InternalAuditor,
    );
  }

  getDocumentNote(document: any) {
    if (!document.length) return;
    return document.filter(
      (note: any) => note?.user?.role === 'المدقق الداخلي',
    )[0].note;
  }
}
