import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Assets } from '@assets/images';
import { AuditService } from '@app/dashboard/services/audit.service';
import { DataService } from '@app/dashboard/services/data.service';
import { TrainingOrganizationInvoiceStatusEnum } from '@app/dashboard/types/invoiceStatus';
import { Status } from '@app/dashboard/types/status';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-receipt-details',
  templateUrl: './payment-receipt-details.component.html',
  styleUrl: './payment-receipt-details.component.scss',
})
export class PaymentReceiptDetailsComponent implements OnInit {
  paymentData: any;
  image: string;
  imageSrc: string = '';
  imagesArr = [];
  file = Assets.pdf;
  closeImg = Assets.closeImg;
  downloadIcon = Assets.downloadIcon;
  submitClicked = true;
  reportId: string;
  invoiceId: number;
  organizationId: number;
  invoiceData: any;
  showModal: boolean = false;
  submittedSuccessfully: boolean = false;
  showInvoiceModal: boolean = false;
  fileImageSrc: string = '';
  isSubmitButtonClicked: boolean = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  getImageSrc(image: string): void {
    this.imageSrc = image;
  }

  getImagesArr(arr: any) {
    this.imagesArr = arr;
  }

  removeImage(index: number) {
    this.imagesArr.splice(index, 1);
  }

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
    {
      name: 'paymenteRecieptDetails',
      path: '',
    },
  ];

  ngOnInit(): void {
    this.invoiceId = this.route.snapshot.params['id'];
    this.reportId = this.route.snapshot.queryParams['reportId'];
    this.organizationId = this.route.snapshot.queryParams['organizationId'];
    this.getData();
  }

  openImage(): void {
    this.showModal = true;
    this.showInvoiceModal = true;
  }

  downloadFile() {
    const decodedData = atob(this.fileImageSrc);

    const uint8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uint8Array[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: 'image/jpeg' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'downloaded_image.jpg';
    link.click();
  }

  closeModal() {
    if (this.submittedSuccessfully) {
      this.router.navigate(['/dashboard']);
    }
    this.showModal = false;
    this.showInvoiceModal = false;
    this.submittedSuccessfully = false;
  }

  getData() {
    this.auditService.getInvoiceData(this.invoiceId).subscribe(
      (data) => {
        this.organizationId = data.data?.trainingOrganizationId;
        this.invoiceData = data.data;
        this.getFileImage(this.invoiceData?.file?.id);
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  updateInvoice() {
    this.isSubmitButtonClicked = true;
    const body = {
      Id: this.invoiceId,
      TrainingOrganizationId: this.organizationId,
      TrainingOrganizationInvoiceStatusLookupId:
        TrainingOrganizationInvoiceStatusEnum.Approved,
    };
    this.showModal = true;
    this.loadingService.startLoading();
    this.auditService.sendInvoiceData(body).subscribe(
      (response) => {
        if (response.data) {
          this.auditService
            .updateApplicationStatus({
              TrainingOrganizationStatusEnum:
                Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary,
              TrainingOrganizationId: this.organizationId,
            })
            .subscribe({
              next: () => {
                this.loadingService.stopLoading();
                this.submittedSuccessfully = true;
              },
              error: (error) => {
                console.log('Error sending the data', error);
              },
            });
        }
      },
      (error) => {
        console.error('data failed', error);
      },
    );
  }

  getFileImage(invoiceId: number) {
    if (invoiceId)
      this.auditService.getFile(invoiceId).subscribe({
        next: (data) => {
          this.fileImageSrc = data;
        },
        error: (error) => {
          console.log('error', error);
        },
      });
  }
}
