<div class="programs-container">
  <div class="menu">
    <p-tabView>
      <loading-spinner />
      <p-tabPanel
        header="البرامج التدريبية في مجال كفاءة الطاقة التي عمل المركز على إعدادها"
      >
        <div class="progress-indicator">
          <p class="no-program-paragraph">لا يوجد طلبات</p>
        </div>
      </p-tabPanel>
      <p-tabPanel
        header="البرامج التدريبية في مجال كفاءة الطاقة التي قامت الجهات التدريبية بتأليفها"
        *ngIf="!(isLoading | async)"
      >
        <div class="tab-data__container">
          <div class="cards-container">
            <app-application-cards
              title="طلبات الاعتماد المكتملة"
              number="{{ informationPanel?.totaltrainingOrganizationDone }}"
            ></app-application-cards>
            <app-application-cards
              title="طلبات الاعتماد قيد الإنتظار"
              number="{{ informationPanel?.totaltrainingOrganizationNotDone }}"
            ></app-application-cards>
            <app-application-cards
              title="أضف برنامجًا جديدًا إلى مسارك"
              link="تقديم طلب اعتماد جديد"
            ></app-application-cards>
          </div>

          <div
            *ngIf="informationPanel?.trainingProgramList.data"
            class="progress-indicator"
          >
            <p
              *ngIf="!informationPanel?.trainingProgramList.data.length"
              class="no-program-paragraph"
            >
              لا يوجد طلبات
            </p>
            <div
              *ngFor="let item of informationPanel?.trainingProgramList.data"
            >
              <div class="flex progress-item">
                <p class="program">
                  {{
                    item['programName'] === 'draftArNameForTesting'
                      ? ''
                      : item['programName']
                  }}
                </p>
                <p class="status">
                  <img
                    [src]="check"
                    alt="check"
                    *ngIf="
                      item['trainingOrganizationStatusId'] == isCertificated
                    "
                    class="img"
                  />
                  {{ item['trainingOrganizationStatusStr'] }}
                </p>
              </div>
              <div class="main-progress-bar">
                <div
                  class="progress-bar primary-green"
                  [style.width]="
                    item['trainingOrganizationStatusId'] == isDraft
                      ? '0%'
                      : (item['trainingOrganizationStatusId'] - 2) * 9.09 + '%'
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
