// modal.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BodyScrollService } from 'src/app/dashboard/services/body-scroll.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() showModal: boolean;
  @Input() customClass: string;
  @Input() modalContent: 'information' | 'submission' = 'information';
  @Input() isCloseVisible: boolean = true;
  @Output() closeModal = new EventEmitter<void>();

  constructor(private bodyScrollService: BodyScrollService) {}

  hideModal() {
    this.closeModal.emit(); // Emit the close event
    this.bodyScrollService.toggleBodyScroll(true); // Re-enable body scrolling
  }
}
