import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Status } from '@app/dashboard/types/status';
import { Assets } from '@assets/images';

@Component({
  selector: 'general-note',
  templateUrl: './general-note.component.html',
  styleUrl: './general-note.component.scss',
  providers: [LoadingService],
})
export class GeneralNoteComponent implements OnInit {
  @Input() noteData: any = {};
  @Input() trainingOrganizationId: number;
  @Input() requestStatus: number = null;
  editNotesSrc = Assets.editNotes;

  editMode: boolean = false;
  noteText: string = this.noteData.note;
  showModal: boolean = false;
  sentSuccessfully: boolean = false;
  showEditButton: boolean;
  constructor(
    private authService: AuthService,
    private auditService: AuditService,
    private loadingService: LoadingService,
  ) {}

  isLoading = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.noteText = this.noteData.note;
    this.showEditButton =
      this.userIsCommitteeSecretary &&
      !this.editMode &&
      this.requestStatus <=
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;
  }

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  clickHandler() {
    this.editMode = true;
  }

  saveHandler() {
    this.loadingService.startLoading();
    this.showModal = true;
    const body = {
      TrainingOrganizationStatusEnum: this.requestStatus,
      TrainingOrganizationId: this.trainingOrganizationId,
      TrainingProgramDocumentNotes: [
        { id: this.noteData.id, note: this.noteText },
      ],
    };

    this.auditService.updateApplicationStatus(body).subscribe(() => {
      this.loadingService.stopLoading();
      this.noteData.note = this.noteText;
      this.editMode = false;
    });
  }
}
