<div class="expert-comment">
  <img [src]="images.altImage" class="comment-img" />
  <div>
    <div class="comment-flex">
      <app-page-title
        [title]="data.user?.userName"
        [customClass]="['comment-title']"
      ></app-page-title>
      <p class="cool-gray menu-text">( {{ data.user?.role }})</p>

      <img [src]="images.calendar" />
      <p class="small-font margin">
        {{ commentDate }}
      </p>
      <img [src]="images.clock" />

      <p class="small-font margin time">
        {{ commentTime }}
      </p>
    </div>
    <p class="comment-style">
      {{ data.note }}
    </p>
  </div>
</div>
