<div class="container">
  <div class="header">
    <div class="header flex">
      <div class="flex next-flex">
        <app-page-title
          [title]="'طلب رقم '"
          [customClass]="['title-container', 'black-color']"
        ></app-page-title>
        <app-page-title
          [title]="'#' + reportId"
          [customClass]="['title-container', 'green-color']"
        ></app-page-title>
      </div>
    </div>
  </div>
  <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
  <div class="main-container">
    <app-page-title
      [title]="'recieptDetails'"
      [customClass]="['application-details']"
    ></app-page-title>
    <div class="new-section">
      <p class="title">
        {{ 'checkReciept' | translate }}
      </p>
    </div>

    <div class="new-section-border">
      <app-page-title
        [title]="'bill'"
        [customClass]="['application-details']"
      ></app-page-title>
      <div class="flex">
        <p class="payment-instruction-text">
          طلب إعتماد برنامج {{ invoiceData?.organizationName }}
        </p>
        <p class="payment-instruction-text">
          {{ invoiceData?.totalAmount }}

          <span class="green"> SAR </span>
        </p>
      </div>
      <div class="divider"></div>
      <div class="total-amount flex">
        <p>مجموع المبلغ</p>
        <p>
          {{ invoiceData?.totalAmount }}
          <span class="green"> SAR </span>
        </p>
      </div>
    </div>
    <div class="flex file-attachments">
      <div>
        <p class="payment-instruction-text">{{ 'theReciept' | translate }}</p>

        <div class="files-container">
          <div class="file-item" *ngIf="fileImageSrc">
            <div
              class="file-item__container"
              [ngClass]="{ border: !isLast }"
              (click)="openImage()"
            >
              <img [src]="file" />
              <div class="file-info">
                <span class="file-name">{{
                  invoiceData?.file['fileName']
                }}</span>
                <span class="file-size">{{
                  invoiceData?.file['fileSize']
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <file-download
        [files]="[invoiceData?.file]"
        [hasText]="false"
      ></file-download>
    </div>
    <div class="flex next-flex new-section">
      <button
        [disabled]="isSubmitButtonClicked"
        class="attach-button submit-button"
        (click)="updateInvoice()"
      >
        موافقة على الطلب
      </button>
    </div>
    <div class="success-modal__container">
      <app-modal
        [modalContent]="'submission'"
        [showModal]="showModal"
        (closeModal)="closeModal()"
        [customClass]="
          submittedSuccessfully || (isLoading | async)
            ? 'success-modal'
            : showInvoiceModal
              ? 'success-receipt-modal'
              : ''
        "
        [isCloseVisible]="!(isLoading | async)"
      >
        <loading-spinner>جاري إرسال الطلب...</loading-spinner>
        <div *ngIf="submittedSuccessfully" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{
            'تم اعتماد الطلب و إرساله إلى أمين اللجنة'
          }}</span>
        </div>
        <div *ngIf="showInvoiceModal" class="modal-body">
          <img [src]="fileImageSrc" class="invoice-modal-img" alt="Invoice" />
        </div>
      </app-modal>
    </div>
  </div>
</div>
