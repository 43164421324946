import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { Assets } from '@assets/images/index';
import { AuditService } from '@app/dashboard/services/audit.service';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { Status } from '@app/dashboard/types/status';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @Input() requestData: any = {};
  activeId: number = 1;
  activeTabIndex: number = 0;
  applicationData: any;
  divider = Assets.dividerTriangle;
  file = Assets.pdf;
  files: any;
  tableData: any;
  images = {
    checked: Assets.checkMark,
    clock: Assets.clock,
    altImage: Assets.altImage,
    calendar: Assets.calenadar,
  };
  allData;
  internalAuditComments;
  trainingOrganization: any;
  trainingProgram: any;
  generalCommitment: any;
  trainingProgramDocuments: any;
  id: number;
  openModal: boolean = false;
  fileIndex: number = 0;
  tabDataIndex: number = 0;
  showTrainingData: boolean = true;
  showPledge: boolean = false;
  imgUrl: string = '';
  openAdvisorModal: boolean = false;
  generalNotes: string = '';
  ApprovedByCommitteeSecrateryAndWaitingTechnicalAdvisor: number =
    Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert;
  technicalAdvisorTrainingProgramDocuments: any[] = [];
  requestStatus: number;
  showSuccessModal: boolean = false;
  showCriteriasWithoutNoteBox: boolean = false;
  technicalAdvisorRoleId = UserRole.TechnicalAdvisor;
  internalAuditorRoleId = UserRole.InternalAuditor;
  showTechnicalAdvisorSection: boolean = false;
  modalText: string;

  displayedColumns: string[] = ['notes', 'evaluation', 'standard'];

  fieldVisitData: any[];
  applicationIsReviewedByTechnicalAdvisor: boolean = false;

  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;

  isSubmitButtonDisabled: boolean = false;

  constructor(
    private authService: AuthService,
    private bodyScrollService: BodyScrollService,
    private auditService: AuditService,
    private router: Router,
    private loadingService: LoadingService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }
  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  userIsInternalAuditor: boolean = this.authService.hasRole(
    UserRole.InternalAuditor,
  );

  userCanDownloadFiles: boolean =
    this.userIsCommitteeSecretary || this.userIsInternalAuditor;

  ngOnInit(): void {
    this.trainingProgramDocuments =
      this.requestData.trainingProgramDocuments?.trainingProgramDocumentMapping;
    this.trainingOrganization = this.requestData.trainingOrganization;
    this.trainingProgram = this.requestData.trainingProgram;
    this.generalCommitment = this.requestData.generalCommitment;

    this.technicalAdvisorTrainingProgramDocuments =
      this.trainingProgramDocuments.reduce(
        (accumulator: any[], current: any, index: number) =>
          current.documentRoles.includes(UserRole.TechnicalAdvisor)
            ? [...accumulator, { ...current, index }]
            : accumulator,
        [],
      );
    this.getElecSignature();

    this.id = this.requestData.trainingOrganization.id;
    this.requestStatus =
      this.requestData.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;
    this.applicationIsReviewedByTechnicalAdvisor =
      this.requestStatus >=
      Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;

    this.showCriteriasWithoutNoteBox =
      this.requestStatus ===
      Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers;

    this.showTechnicalAdvisorSection =
      this.requestStatus !==
      Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary;

    this.modalText =
      this.requestStatus ===
      Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary
        ? 'تم إرسال الطلب إلى الخبير الفني بنجاح'
        : 'تم إرسال الطلب إلى أعضاء اللجنة بنجاح';

    this.fieldVisitData =
      this.requestData?.trainingOrganizationFieldVisitForm?.fieldVisitForms?.filter(
        (element: any) => element.standard,
      );
  }

  getElecSignature() {
    if (this.generalCommitment.generalCommitmentFile.file.id)
      this.auditService
        .getFile(this.generalCommitment.generalCommitmentFile.file.id)
        .subscribe({
          next: (data) => {
            this.imgUrl = data;
          },
          error: (error) => {
            console.log('error', error);
          },
        });
  }

  trainingProgramAndPledgeIconsClickHandler(id: number) {
    this.tabDataIndex = id;
    this.openModal = true;
    this.showTrainingData = !id;
    this.showPledge = !!id;
    this.inspectorComponent.tabDataIndex = id;
  }

  onDialogHide(status: boolean) {
    this.openAdvisorModal = status;
    this.isSubmitButtonDisabled = false;
  }

  filterCriteriaMapping(arr: any[], roleId: number) {
    return arr.filter(
      (element) =>
        element.documentCriteriaLookup.roleId === roleId && element.isPassed,
    );
  }

  submitButtonTextHandler(status: number) {
    switch (status) {
      case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
        return 'إرسال الطلب إلى الخبير الفني';

      case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
        return 'إرسال الطلب إلى أعضاء اللجنة';

      case Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers:
      case Status.ApprovedByCommitteeMembersWaitingCommitteeHead:
        return 'العودة إلى مراحل التقييم';

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
        return 'العودة إلى نموذج تصويت اللجنة';

      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        return 'العودة إلى تفاصيل التصويت';

      default:
        return '';
    }
  }

  submitButtonClickHandler(status: number) {
    switch (status) {
      case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
        this.isSubmitButtonDisabled = true;
        this.openAdvisorModal = true;
        break;

      case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
        this.showSuccessModal = true;
        this.loadingService.startLoading();
        this.isSubmitButtonDisabled = true;
        this.auditService
          .updateApplicationStatus({
            TrainingOrganizationStatusEnum:
              Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers,
            TrainingOrganizationId: this.id,
          })
          .subscribe({
            next: () => {
              this.loadingService.stopLoading();
            },
            error: (error) => {
              console.log('Error sending the data', error);
            },
          });
        break;

      case Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers:
      case Status.ApprovedByCommitteeMembersWaitingCommitteeHead:
      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        this.router.navigate(['/dashboard/voting', this.id]);
        break;

      default:
        break;
    }
  }

  documentNotesFilter(notes: any[], roleId: number) {
    return roleId === UserRole.InternalAuditor
      ? notes.filter(
          (note) =>
            note.user.roleId === UserRole.InternalAuditor ||
            (note.user.roleId === UserRole.CommitteeSecretary &&
              note.assignToRoleId === UserRole.InternalAuditor),
        )
      : notes.filter(
          (note) =>
            note.user.roleId === UserRole.TechnicalAdvisor ||
            (note.user.roleId === UserRole.CommitteeSecretary &&
              note.assignToRoleId === UserRole.TechnicalAdvisor),
        );
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  closeModel() {
    this.showSuccessModal = false;
    this.router.navigate(['/dashboard']);
  }

  handleSaveEvaluationData(body: any) {
    this.trainingProgramDocuments[
      body.tabDataIndex
    ].programDocumentLookup.programDocumentCriteriaMappings =
      body.body.programDocumentCriteriaMappings;

    this.trainingProgramDocuments[body.tabDataIndex].documentNotes = [
      ...this.trainingProgramDocuments[body.tabDataIndex].documentNotes,
      ...body.body.documentNotes,
    ];

    this.technicalAdvisorTrainingProgramDocuments =
      this.trainingProgramDocuments.reduce(
        (accumulator: any[], current: any, index: number) =>
          current.documentRoles.includes(UserRole.TechnicalAdvisor)
            ? [...accumulator, { ...current, index }]
            : accumulator,
        [],
      );
  }

  viewFile(sectionIndex: number, currentFileIndex: number) {
    this.tabDataIndex = sectionIndex;
    this.fileIndex = currentFileIndex;
    this.openModal = true;
    this.showTrainingData = false;
    this.showPledge = false;
    this.inspectorComponent.tabDataIndex = sectionIndex;
    this.inspectorComponent.fileIndex = currentFileIndex;
    this.inspectorComponent.showTrainingData = false;
    this.inspectorComponent.showPledge = false;
    this.inspectorComponent.getFileUrl(sectionIndex, currentFileIndex);
  }

  generalNotesFilter(generalNotes: any[]) {
    return generalNotes.filter((item) => item.note);
  }
}
