export const Formatter = {
  dateToString(
    date: Date,
    locale: string = 'en-UK',
    options: any = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  ): string {
    return date.toLocaleDateString(locale, options);
  },

  reverseDateString(inputString: string): string {
    const [month, day, year] = inputString.split('/');
    return `${year}/${day}/${month}`;
  },

  dateFormatConverter(date: string | Date) {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    } else {
      const parts = date.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day, 0, 0, 0, 0);
    }
  },
};
