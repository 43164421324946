<div class="container">
  <div>
    <app-page-title
      [title]="'applicationNumber'"
      [customClass]="'application-number__title'"
      ><span class="green-color">{{
        requestData?.trainingOrganization?.reportId
      }}</span></app-page-title
    >
    <div class="breadcrumbs-container">
      <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
      <app-custom-button
        customClass="transperantGreenButton"
        (click)="requestDetailsButtonHandler()"
        buttonText="تفاصيل الطلب"
      ></app-custom-button>
    </div>
  </div>
  <loading-spinner></loading-spinner>
  <committee-voting
    *ngIf="requestData?.trainingOrganization"
    [requestData]="requestData"
    [readOnly]="isCommitteeSecretary() || isGeneralManager()"
    [requestId]="id"
  ></committee-voting>
</div>
