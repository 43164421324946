<div class="file-item" *ngIf="showIcon">
  <div class="file-item__container">
    <svg
      class="file-icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <use [attr.xlink:href]="'#file-icon'"></use>
    </svg>
    <div class="file-info">
      <span class="file-name">{{ file.name || file.fileName }}</span>
      <span class="file-size">{{
        file.fileSize || getFileSize(file.size)
      }}</span>
    </div>
    <div *ngIf="!readOnly" class="close-icon" (click)="removeFile()">
      <img src="assets/images/close-filled.svg" />
    </div>
  </div>
</div>
