<div class="application-details__container">
  <div class="training-body__data">
    <div class="tab-data" *ngIf="trainingOrganization">
      <app-training-body-data [trainingOrganization]="trainingOrganization" />
    </div>
    <app-custom-button
      (click)="showData()"
      *ngIf="!isClicked && !requestIsReviewedByInternalAuditor"
      [buttonStyle]="'greenButton'"
      [buttonText]="'التقييم الأولي'"
      [customClass]="'add-criteria__btn'"
    ></app-custom-button>
    <app-document-inspector
      #inspectorComponent
      *ngIf="trainingProgram"
      [isCheckbox]="!requestIsReviewedByInternalAuditor"
      [openModal]="openModal"
      [fileIndex]="fileIndex"
      (saveEvaluationData)="handleSaveEvaluationData($event)"
      [fileURL]="fileURL"
      [dataGroupFiles]="trainingProgramDocuments"
      [tabDataIndex]="tabDataIndex"
      (closeModal)="hideModal()"
      (showTrainingDataChange)="onShowTrainingDataChange($event)"
      (showPledgeChange)="onShowPledge($event)"
      [customClass]="'initial-document'"
      [generalCommitment]="generalCommitment"
      [criteriaLookup]="criteriaLookup"
      [requestStatus]="requestStatus"
    >
      <div *ngIf="showTrainingData" class="tab-data training-program__data">
        <div class="training-body__title">بيانات البرنامج التدريبي</div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">اسم البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameAr }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="document-icon"
              ></app-svg-icon>
              <div class="field-name">
                اسم البرنامج التدريبي باللغة الإنجليزية
              </div>
            </div>
            <div class="field-value">
              {{ trainingProgram.nameEn }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
              <div class="field-name">نوع البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramType }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
              <div class="field-name">مستوى البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programLevelName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.trainingProgramDuration }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
              <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.totalTrainingHours }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon
                class="field-icon"
                name="target-icon"
              ></app-svg-icon>
              <div class="field-name">الهدف العام من البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.programObjective }}
            </div>
          </div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
              <div class="field-name">لغة البرنامج التدريبي</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.languageLookupName }}
            </div>
          </div>
        </div>
        <div class="row-data">
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
              <div class="field-name">الفئة المستهدفة</div>
            </div>
            <div class="field-value">
              {{ trainingProgram.targetCategory }}
            </div>
          </div>
        </div>
        <div>
          <div class="training-body__info">
            <div class="field-container">
              <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
              <div class="field-name">وصف البرنامج التدريبي</div>
            </div>
            <div class="field-value training-program-description">
              {{ trainingProgram.description }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content__container tab-data"
        *ngIf="generalCommitment && showPledge"
      >
        <div class="training-body__title">التعهد العام</div>

        <div class="pledge-container">
          <span class="pledge-intro"
            >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
          </span>
          <p class="pledge-intro">السلام عليكم ورحمة االله وبركاته</p>
          <p class="pledge-para">
            نتعهد نحن {{ trainingOrganization.organizationName }}، بالالتزام
            بلائحة اعتماد البرامج التدريبية والدليل الإرشادي للائحة وكافة
            التعاميم والتعليمات التي يصدرها المركز السعودي لكفاءة الطاقة بهذا
            الشأن.
          </p>
          <span class="pledge-para salute">وتقبلوا وافر التحية والتقدير</span>
          <div class="applicant-container">
            <span class="applicant-name__text">اسم مقدم الطلب</span>
            <span class="applicant-name">{{
              generalCommitment.applicantsName
            }}</span>
          </div>
          <div class="electronic-signature__container" *ngIf="imgUrl">
            <span>التوقيع الإلكتروني</span>
            <div class="electronic-signature">
              <div class="signature-container">
                <img
                  [src]="imgUrl"
                  alt="Electronic Signature"
                  class="signature-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-document-inspector>

    <div
      class="initial-evaluation__container"
      *ngIf="isClicked || requestIsReviewedByInternalAuditor"
    >
      <app-svg-icon
        name="long-two-sided-arrow"
        class="two-sided-arrow"
      ></app-svg-icon>
      <div class="training-body__title">التقييم الأولي</div>

      <div class="files-container">
        <div
          class="documents-container"
          *ngFor="let data of trainingProgramDocuments; let tabIndex = index"
        >
          <div class="file-item">
            <div class="field-download-container">
              <div class="field-item-container">
                <div class="field-title">
                  {{ data.programDocumentLookup.name }}
                </div>
                <ul class="field-subtitles">
                  <li
                    *ngFor="
                      let subtitle of data.programDocumentLookup
                        .programDocumentRequirements
                    "
                  >
                    {{ subtitle }}
                  </li>
                </ul>
              </div>
              <file-download
                *ngIf="data.files.length"
                [files]="data.files"
              ></file-download>
            </div>
            <div class="active-tab">
              <div
                *ngFor="let tab of data.files; let i = index"
                class="file-item__container"
                (click)="viewFile(tabIndex, i)"
              >
                <app-svg-icon name="file-icon" class="file-icon"></app-svg-icon>
                <div class="file-info">
                  <span class="file-name">{{ tab.fileName }}</span>
                  <span class="file-size">{{ tab.fileSize }}</span>
                </div>
              </div>
              <file-icon
                *ngIf="!data.files.length"
                [file]="{
                  name: data.programDocumentLookup.name,
                  size: 10000
                }"
                (click)="viewFile(tabIndex, null)"
                [readOnly]="true"
              ></file-icon>
            </div>
          </div>
          <div class="accordions-container">
            <accordion
              *ngIf="
                accordionVisibilityHandler(
                  data.programDocumentLookup.programDocumentCriteriaMappings
                )
              "
              [customClass]="'criteria-accordion'"
              [header]="'معايير ' + data.programDocumentLookup.name"
            >
              <div
                class="accordion-criteria-item"
                *ngFor="
                  let item of filterAccordionCriterias(
                    data.programDocumentLookup.programDocumentCriteriaMappings
                  );
                  let index = index
                "
              >
                <span
                  class="criteria-name"
                  *ngIf="
                    criteria
                      ? criteria.body.programDocumentCriteriaMappings[index]
                          ?.isPassed
                      : item.isPassed
                  "
                >
                  <div class="criteria-item">
                    {{ item.documentCriteriaLookup.name }}
                    <app-svg-icon
                      name="check-mark"
                      class="check-mark"
                    ></app-svg-icon>
                  </div>
                  <!-- <div class="notes">
                    <input
                      class="criteria-note-field"
                      disabled
                      type="text"
                      [value]="
                        item.documentCriteriaLookup.criteriaNotes.at(-1)
                          ?.name || ''
                      "
                    />
                  </div> -->
                  <!-- <div class="notes" *ngIf="criteria">
                    <span
                      *ngFor="
                        let note of criteria.body
                          .programDocumentCriteriaMappings[index].criteriaNotes
                      "
                    >
                      {{ note.note }}</span
                    >
                  </div> -->
                </span>
              </div>
            </accordion>
            <accordion
              [customClass]="'notes-accordion'"
              [header]="'ملاحظات أخرى'"
              *ngIf="data.documentNotes.length"
            >
              <div class="user-notes">
                <div
                  *ngFor="let note of data.documentNotes; let noteIndex = index"
                  class="user-note"
                  [class.empty]="!note.note"
                >
                  <app-comment-section [data]="note"></app-comment-section>
                </div>

                <div *ngIf="criteria">
                  <div
                    class="user-note"
                    *ngFor="let note of criteria.body.documentNotes"
                  >
                    <div class="user-image">
                      <img src="assets/images/user-image.png" alt="" />
                    </div>
                    <div class="user-role">
                      <div class="note">
                        {{ note.note }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </accordion>
          </div>
        </div>
      </div>
    </div>
    <div
      class="general-notes"
      *ngIf="isClicked || requestIsReviewedByInternalAuditor"
    >
      <div class="checkbox-wrapper" *ngIf="!requestIsReviewedByInternalAuditor">
        <input
          id="checkbox"
          type="checkbox"
          (change)="handleCheckboxChange()"
        />
        <label for="checkbox" class="checkbox-label">ملاحظات عامة</label>
      </div>
      <textarea
        *ngIf="!requestIsReviewedByInternalAuditor"
        [disabled]="!isChecked"
        [rows]="10"
        placeholder="الملاحظات"
        class="general-notes"
        [(ngModel)]="generalNotes"
      ></textarea>
    </div>
    <div
      class="buttons-container"
      *ngIf="isClicked || requestIsReviewedByInternalAuditor"
    >
      <app-custom-button
        [disabled]="
          (isChecked && !generalNotes) || requestIsReviewedByInternalAuditor
        "
        [buttonStyle]="'greenButton'"
        [buttonText]="'إرسال الطلب إلى أمين اللجنة'"
        (click)="technicalAdvisorModal()"
      ></app-custom-button>
      <app-custom-button
        [disabled]="sendToTrainingOrgButtonIsClicked"
        [buttonStyle]="'transperantGreenButton'"
        [buttonText]="'إعادة الطلب إلى الجهة التدريبية'"
        (click)="sendApplicationToTrainingOrg()"
      ></app-custom-button>
      <app-dialog
        [id]="requestData?.trainingOrganization?.trainingOrganizationId"
        [generalNotes]="
          isChecked
            ? generalNotes
            : getDocumentNote(requestData?.trainingProgramDocumentNote) || ''
        "
        [trainingOrganizationStatusEnum]="1"
        [visible]="openAdvisorModal"
        (onHide)="onDialogHide($event)"
        [isChecked]="isChecked"
        (onSuccess)="handleSuccess($event)"
        [proposedTechnicalAdvisor]="requestData?.proposedTechnicalAdvisor?.user"
        [proposedWorkingHours]="
          requestData?.proposedTechnicalAdvisor?.workingHours
        "
      ></app-dialog>
      <div class="success-modal__container">
        <app-modal
          [modalContent]="'submission'"
          [showModal]="success"
          (closeModal)="closeModel()"
          [customClass]="'success-modal'"
        >
          <div class="modal-body">
            <app-svg-icon
              class="success-icon"
              name="success-icon"
            ></app-svg-icon>
            <span class="modal-text">{{
              sentToTrainingOrganization
                ? 'تم إرسال الطلب إلى الجهة التدريبية بنجاح'
                : 'تم إرسال الطلب إلى أمين اللجنة بنجاح'
            }}</span>
          </div>
        </app-modal>
      </div>
    </div>
  </div>
</div>
