<div
  class="modal-overlay"
  [class.show-modal]="openModal"
  [ngClass]="customClass"
>
  <div class="modal">
    <div class="modal-content">
      <div class="pdf-container">
        <div class="pdf-container__content">
          <div class="pdf-title__container">
            <div class="title-container">
              <app-svg-icon
                (click)="
                  closeModal.emit();
                  this.fileIndex = 0;
                  this.criteriaListIsOpen = false
                "
                class="close-icon"
                name="close-icon"
              ></app-svg-icon>
              <div class="pdf-title">
                {{ dataGroupFiles[tabDataIndex].programDocumentLookup.name }}
                <svg
                  *ngIf="
                    !!dataGroupFiles[tabDataIndex].programDocumentLookup
                      .programDocumentRequirements.length
                  "
                  (click)="infoIconClickHandler()"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <use [attr.xlink:href]="'#info-icon'"></use>
                </svg>
              </div>
            </div>
            <app-modal
              [showModal]="showModal"
              (closeModal)="showModal = false"
              [customClass]="'documents-modal'"
            >
              <div class="modal-header">
                <h2>
                  {{ dataGroupFiles[tabDataIndex].programDocumentLookup.name }}
                </h2>
              </div>
              <div class="requirements-modal-body">
                <ul>
                  <li
                    *ngFor="
                      let subtitle of dataGroupFiles[tabDataIndex]
                        .programDocumentLookup.programDocumentRequirements
                    "
                  >
                    {{ subtitle }}
                  </li>
                </ul>
              </div>
            </app-modal>
            <div class="section-navigation">
              <div class="section-navigation__container">
                <app-svg-icon
                  *ngIf="!!startIndex"
                  name="circle-arrow"
                  class="arrow-prev arrow"
                  (click)="previousPage()"
                ></app-svg-icon>
                <div
                  class="section-index"
                  *ngFor="let item of getVisibleItems(); let i = index"
                  (click)="onSectionIndexClick(startIndex + i)"
                  [ngClass]="{ activeBtn: startIndex + i === tabDataIndex }"
                >
                  <div class="index">{{ startIndex + i + 1 }}</div>
                </div>
                <app-svg-icon
                  *ngIf="hasNextPage()"
                  name="circle-arrow"
                  class="arrow-next arrow"
                  (click)="nextSection()"
                ></app-svg-icon>
              </div>
            </div>
          </div>
          <app-svg-icon
            name="long-two-sided-arrow"
            class="two-sided-arrow__desktop"
            [desiredWidth]="'100%'"
            [desiredHeight]="'100%'"
          ></app-svg-icon>
          <div class="pdf-viewer__container">
            <ng-content *ngIf="!currentSectionHasPdfFiles()"></ng-content>
            <loading-spinner> جاري تحميل الملف... </loading-spinner>
            <pdf-viewer
              *ngIf="currentSectionHasPdfFiles() && !(isLoading | async)"
              [src]="fileURL"
              style="width: 100%; height: 100%"
            ></pdf-viewer>
          </div>

          <div class="documents-navigation__container">
            <div class="section-navigation__container">
              <app-svg-icon
                *ngIf="dataGroupFiles[tabDataIndex].files.length > 1"
                name="circle-arrow"
                class="arrow-prev arrow"
                (click)="prevPage()"
              ></app-svg-icon>
              <div
                class="section-index"
                *ngFor="let item of getFilesPaginationItems(); let i = index"
                (click)="viewFile(i)"
                [ngClass]="{ activeBtn: fileStartIndex + i === fileIndex }"
              >
                <div class="index">{{ fileStartIndex + i + 1 }}</div>
              </div>
              <app-svg-icon
                *ngIf="dataGroupFiles[tabDataIndex].files.length > 1"
                name="circle-arrow"
                class="arrow-next arrow"
                (click)="nextPage()"
              ></app-svg-icon>
            </div>
            <app-custom-button
              *ngIf="dataGroupFiles[tabDataIndex].files.length"
              [buttonText]="
                ' الملف ' +
                (fileIndex + 1) +
                ' من ' +
                dataGroupFiles[tabDataIndex].files.length
              "
              [buttonStyle]="'fadedGreenBtn'"
            ></app-custom-button>
          </div>
        </div>
      </div>

      <!-- criteria -->
      <div class="criteria-col">
        <div class="criteria-col-title">
          {{ dataGroupFiles[tabDataIndex].programDocumentLookup.name }}
        </div>
        <p-tabView
          *ngIf="showCriteriasInTabs()"
          [(activeIndex)]="activeTabIndex"
        >
          <p-tabPanel header="معايير المدقق الداخلي">
            <form
              [formGroup]="evaluationForms[tabDataIndex]"
              (ngSubmit)="saveEvaluation()"
            >
              <div class="criteria-content">
                <div
                  *ngIf="!criteriaList[tabDataIndex].length"
                  class="criteria-item file-is-reviewed"
                >
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [formControl]="
                      evaluationForms[tabDataIndex].get('fileHasBeenReviewed')
                    "
                  />
                  <span
                    *ngIf="
                      isCheckbox
                        ? true
                        : dataGroupFiles[tabDataIndex].fileHasBeenReviewed
                    "
                    >تم مراجعة الملف</span
                  >
                  <img
                    *ngIf="
                      !isCheckbox &&
                      dataGroupFiles[tabDataIndex].fileHasBeenReviewed
                    "
                    src="assets/images/checked.svg"
                  />
                </div>
                <div
                  class="criteria-items"
                  *ngIf="criteriaList[tabDataIndex].length"
                >
                  <div
                    class="criteria-item__container"
                    *ngFor="
                      let item of filterCriteriaMapping(
                        criteriaList[tabDataIndex],
                        internalAuditorRoleId
                      );
                      let i = index
                    "
                  >
                    <div class="criteria-item">
                      <input
                        *ngIf="isCheckbox"
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[tabDataIndex].get(
                            'criteriaItems.' + i + '.checkbox'
                          )
                        "
                      />

                      <span *ngIf="isCheckbox ? true : item.isPassed">{{
                        item.documentCriteriaLookup.name
                      }}</span>
                      <img
                        *ngIf="!isCheckbox && item.isPassed"
                        src="assets/images/checked.svg"
                      />
                    </div>
                    <input
                      *ngIf="
                        isCheckbox
                          ? true
                          : item.isPassed && showCriteriaNotesField
                      "
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[tabDataIndex].get(
                          'criteriaItems.' + i + '.note'
                        )
                      "
                      class="criteria-input"
                    />
                  </div>
                  <div
                    class="criteria-item__container"
                    *ngFor="let item of addedCriteria; let i = index"
                  >
                    <div class="criteria-item">
                      <input
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[tabDataIndex].get(
                            'criteriaItems.' +
                              (criteriaLastIndex + i) +
                              '.checkbox'
                          )
                        "
                      />
                      {{ item.documentCriteriaLookup.name }}
                    </div>
                    <input
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[tabDataIndex].get(
                          'criteriaItems.' + (criteriaLastIndex + i) + '.note'
                        )
                      "
                      class="criteria-input"
                    />
                  </div>
                </div>
                <!-- <ng-template #fileIsReviewd>
              <div class="criteria-items">
                <div class="criteria-item__container">
                  <div class="criteria-item">
                    <input *ngIf="isCheckbox" class="checkbox" type="checkbox" [checked]="item.isPassed"
                      [value]="item.documentCriteriaLookup.name" [formControl]="
                        evaluationForms[tabDataIndex].get(
                          'criteriaItems.' + i + '.checkbox'
                        )
                      " />

                    <span>{{ item.documentCriteriaLookup.name }}</span>
                    <img *ngIf="!isCheckbox && item.isPassed" src="assets/images/checked.svg" />
                  </div>
                  <input *ngIf="showCriteriasWithoutNoteBox" type="text" placeholder="الملاحظات" [formControl]="
                      evaluationForms[tabDataIndex].get(
                        'criteriaItems.' + i + '.note'
                      )
                    " class="criteria-input" />
                </div>
              </div>
            </ng-template> -->

                <!-- other notes -->
                <app-custom-button
                  *ngIf="isCheckbox"
                  [hasText]="false"
                  [buttonStyle]="'greenButton'"
                  [customClass]="'add-criteria__btn'"
                  (click)="criteriaDropdown($event)"
                >
                  <app-svg-icon
                    name="plus-icon"
                    class="plus-icon"
                  ></app-svg-icon
                  >إضافة معيار
                </app-custom-button>
                <div class="add-criteria__dropdown" *ngIf="criteriaListIsOpen">
                  <p-accordion
                    *ngFor="
                      let item of filterCriteriasDropDownData(criteriaLookup)
                    "
                  >
                    <p-accordionTab
                      [ngClass]="customClass"
                      [header]="item.programDocuments.value"
                    >
                      <div class="accordion-content">
                        <div
                          class="accordion-item"
                          (click)="selectItem(listItem)"
                          *ngFor="let listItem of item.programDocumentCriteria"
                        >
                          {{ listItem.value }}
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
                <app-svg-icon
                  *ngIf="showCriteriaNotesField"
                  name="two-sided-arrow"
                  class="two-sided-arrow"
                ></app-svg-icon>
                <div
                  class="other-notes__container"
                  *ngIf="showCriteriaNotesField"
                >
                  <label class="other-notes__title">ملاحظات أخرى</label>
                  <textarea
                    [rows]="10"
                    [cols]="30"
                    placeholder="الملاحظات"
                    class="criteria-input"
                    [formControl]="
                      evaluationForms[tabDataIndex].get('otherNotes')
                    "
                  ></textarea>
                </div>
                <app-custom-button
                  *ngIf="showCriteriaNotesField"
                  [disabled]="saveEvaluationIsClicked"
                  [buttonText]="'حفظ'"
                  [buttonStyle]="'greenButton'"
                  [type]="'submit'"
                ></app-custom-button>
              </div>
            </form>
          </p-tabPanel>
          <p-tabPanel header="معايير الخبير الفني">
            <form
              [formGroup]="evaluationForms[tabDataIndex]"
              (ngSubmit)="saveEvaluation()"
            >
              <div class="criteria-content">
                <div
                  *ngIf="!criteriaList[tabDataIndex].length"
                  class="criteria-item file-is-reviewed"
                >
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [formControl]="
                      evaluationForms[tabDataIndex].get('fileHasBeenReviewed')
                    "
                  />
                  <span
                    *ngIf="
                      isCheckbox
                        ? true
                        : dataGroupFiles[tabDataIndex].fileHasBeenReviewed
                    "
                    >تم مراجعة الملف</span
                  >
                  <img
                    *ngIf="
                      !isCheckbox &&
                      dataGroupFiles[tabDataIndex].fileHasBeenReviewed
                    "
                    src="assets/images/checked.svg"
                  />
                </div>
                <div
                  class="criteria-items"
                  *ngIf="criteriaList[tabDataIndex].length"
                >
                  <div
                    class="criteria-item__container"
                    *ngFor="
                      let item of filterCriteriaMapping(
                        criteriaList[tabDataIndex],
                        technicalAdvisorRoleId
                      );
                      let i = index
                    "
                  >
                    <div class="criteria-item">
                      <input
                        *ngIf="isCheckbox"
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[tabDataIndex].get(
                            'criteriaItems.' + i + '.checkbox'
                          )
                        "
                      />

                      <span *ngIf="isCheckbox ? true : item.isPassed">{{
                        item.documentCriteriaLookup.name
                      }}</span>
                      <img
                        *ngIf="!isCheckbox && item.isPassed"
                        src="assets/images/checked.svg"
                      />
                    </div>
                    <input
                      *ngIf="
                        isCheckbox
                          ? true
                          : item.isPassed && showCriteriaNotesField
                      "
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[tabDataIndex].get(
                          'criteriaItems.' + i + '.note'
                        )
                      "
                      class="criteria-input"
                    />
                  </div>
                  <div
                    class="criteria-item__container"
                    *ngFor="let item of addedCriteria; let i = index"
                  >
                    <div class="criteria-item">
                      <input
                        class="checkbox"
                        type="checkbox"
                        [checked]="item.isPassed"
                        [value]="item.documentCriteriaLookup.name"
                        [formControl]="
                          evaluationForms[tabDataIndex].get(
                            'criteriaItems.' +
                              (criteriaLastIndex + i) +
                              '.checkbox'
                          )
                        "
                      />
                      {{ item.documentCriteriaLookup.name }}
                    </div>
                    <input
                      *ngIf="
                        isCheckbox
                          ? true
                          : item.isPassed && showCriteriaNotesField
                      "
                      type="text"
                      placeholder="الملاحظات"
                      [formControl]="
                        evaluationForms[tabDataIndex].get(
                          'criteriaItems.' + (criteriaLastIndex + i) + '.note'
                        )
                      "
                      class="criteria-input"
                    />
                  </div>
                </div>
                <app-custom-button
                  *ngIf="isCheckbox"
                  [hasText]="false"
                  [buttonStyle]="'greenButton'"
                  [customClass]="'add-criteria__btn'"
                  (click)="criteriaDropdown($event)"
                >
                  <app-svg-icon
                    name="plus-icon"
                    class="plus-icon"
                  ></app-svg-icon
                  >إضافة معيار
                </app-custom-button>
                <div class="add-criteria__dropdown" *ngIf="criteriaListIsOpen">
                  <p-accordion
                    *ngFor="
                      let item of filterCriteriasDropDownData(criteriaLookup)
                    "
                  >
                    <p-accordionTab
                      [ngClass]="customClass"
                      [header]="item.programDocuments.value"
                    >
                      <div class="accordion-content">
                        <div
                          class="accordion-item"
                          (click)="selectItem(listItem)"
                          *ngFor="let listItem of item.programDocumentCriteria"
                        >
                          {{ listItem.value }}
                        </div>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
                <app-svg-icon
                  *ngIf="showCriteriaNotesField"
                  name="two-sided-arrow"
                  class="two-sided-arrow"
                ></app-svg-icon>
                <div
                  class="other-notes__container"
                  *ngIf="showCriteriaNotesField"
                >
                  <label class="other-notes__title">ملاحظات أخرى</label>
                  <textarea
                    [rows]="10"
                    [cols]="30"
                    placeholder="الملاحظات"
                    class="criteria-input"
                    [formControl]="
                      evaluationForms[tabDataIndex].get('otherNotes')
                    "
                  ></textarea>
                </div>
                <app-custom-button
                  *ngIf="showCriteriaNotesField"
                  [disabled]="saveEvaluationIsClicked"
                  [buttonText]="'حفظ'"
                  [buttonStyle]="'greenButton'"
                  [type]="'submit'"
                ></app-custom-button>
              </div>
            </form>
          </p-tabPanel>
        </p-tabView>
        <form
          *ngIf="!showCriteriasInTabs()"
          [formGroup]="evaluationForms[tabDataIndex]"
          (ngSubmit)="saveEvaluation()"
        >
          <div class="internal-auditor-criteria-title" *ngIf="!isCheckbox">
            معايير المدقق الداخلي
          </div>
          <div class="criteria-content">
            <div
              *ngIf="!criteriaList[tabDataIndex].length"
              class="criteria-item file-is-reviewed"
            >
              <input
                *ngIf="isCheckbox"
                class="checkbox"
                type="checkbox"
                [formControl]="
                  evaluationForms[tabDataIndex].get('fileHasBeenReviewed')
                "
              />
              <span
                *ngIf="
                  isCheckbox ? true : internalAuditorHasReviewedCurrentTab()
                "
                >تم مراجعة الملف</span
              >
              <img
                *ngIf="!isCheckbox && internalAuditorHasReviewedCurrentTab()"
                src="assets/images/checked.svg"
              />
            </div>
            <div
              class="criteria-items"
              *ngIf="criteriaList[tabDataIndex].length"
            >
              <div
                class="criteria-item__container"
                *ngFor="let item of criteriaList[tabDataIndex]; let i = index"
              >
                <div class="criteria-item">
                  <input
                    *ngIf="isCheckbox"
                    class="checkbox"
                    type="checkbox"
                    [checked]="item.isPassed"
                    [value]="item.documentCriteriaLookup.name"
                    [formControl]="
                      evaluationForms[tabDataIndex].get(
                        'criteriaItems.' + i + '.checkbox'
                      )
                    "
                  />

                  <span *ngIf="isCheckbox ? true : item.isPassed">{{
                    item.documentCriteriaLookup.name
                  }}</span>
                  <img
                    *ngIf="!isCheckbox && item.isPassed"
                    src="assets/images/checked.svg"
                  />
                </div>
                <input
                  *ngIf="
                    isCheckbox ? true : item.isPassed && showCriteriaNotesField
                  "
                  type="text"
                  placeholder="الملاحظات"
                  [formControl]="
                    evaluationForms[tabDataIndex].get(
                      'criteriaItems.' + i + '.note'
                    )
                  "
                  class="criteria-input"
                />
              </div>
              <div
                class="criteria-item__container"
                *ngFor="let item of addedCriteria; let i = index"
              >
                <div class="criteria-item">
                  <input
                    class="checkbox"
                    type="checkbox"
                    [checked]="item.isPassed"
                    [value]="item.documentCriteriaLookup.name"
                    [formControl]="
                      evaluationForms[tabDataIndex].get(
                        'criteriaItems.' + (criteriaLastIndex + i) + '.checkbox'
                      )
                    "
                  />
                  {{ item.documentCriteriaLookup.name }}
                </div>
                <input
                  *ngIf="showCriteriaNotesField"
                  type="text"
                  placeholder="الملاحظات"
                  [formControl]="
                    evaluationForms[tabDataIndex].get(
                      'criteriaItems.' + (criteriaLastIndex + i) + '.note'
                    )
                  "
                  class="criteria-input"
                />
              </div>
            </div>
            <app-custom-button
              *ngIf="isCheckbox"
              [hasText]="false"
              [buttonStyle]="'greenButton'"
              [customClass]="'add-criteria__btn'"
              (click)="criteriaDropdown($event)"
            >
              <app-svg-icon name="plus-icon" class="plus-icon"></app-svg-icon
              >إضافة معيار
            </app-custom-button>
            <div
              class="add-criteria__dropdown"
              *ngIf="criteriaListIsOpen"
              clickOutSide
              (clickOutside)="HideAdditionalCriteriasList()"
            >
              <p-accordion
                *ngFor="let item of filterCriteriasDropDownData(criteriaLookup)"
              >
                <p-accordionTab
                  [ngClass]="customClass"
                  [header]="item.programDocuments.value"
                >
                  <div class="accordion-content">
                    <div
                      class="accordion-item"
                      (click)="selectItem(listItem)"
                      *ngFor="let listItem of item.programDocumentCriteria"
                    >
                      {{ listItem.value }}
                    </div>
                  </div>
                </p-accordionTab>
              </p-accordion>
            </div>
            <app-svg-icon
              *ngIf="showCriteriaNotesField"
              name="two-sided-arrow"
              class="two-sided-arrow"
            ></app-svg-icon>
            <div class="other-notes__container" *ngIf="showCriteriaNotesField">
              <label class="other-notes__title">ملاحظات أخرى</label>
              <textarea
                [rows]="10"
                [cols]="30"
                placeholder="الملاحظات"
                class="criteria-input"
                [formControl]="evaluationForms[tabDataIndex].get('otherNotes')"
              ></textarea>
            </div>
            <app-custom-button
              *ngIf="showCriteriaNotesField"
              [disabled]="saveEvaluationIsClicked"
              [buttonText]="'حفظ'"
              [buttonStyle]="'greenButton'"
              [type]="'submit'"
            ></app-custom-button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-modal
    [modalContent]="'submission'"
    [showModal]="saveCriteriasSuccessfully"
    (closeModal)="
      saveCriteriasSuccessfully = false; saveEvaluationIsClicked = false
    "
    [customClass]="'success-modal'"
  >
    <div class="modal-body">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span class="modal-text">تم حفظ المعايير</span>
    </div>
  </app-modal>

  <app-modal
    [modalContent]="'submission'"
    [showModal]="criteriaAddedSuccessfully"
    (closeModal)="criteriaAddedSuccessfully = false"
    [customClass]="'success-modal'"
  >
    <div class="modal-body">
      <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
      <span class="modal-text">تم إضافة معيار</span>
    </div>
  </app-modal>
</div>
