import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ObjectToFormDataService } from 'src/app/services/object-to-form-data.service';
import { environment } from 'src/environments/environment';

export type NewAccreditationForm = {
  trainingOrganization: {
    organizationName: string;
    coordinatorName: string;
    trainingLicenseNumber: string | number;
    licenseDate: Date;
    licenseExpirationDate: Date;
    address: string;
    phoneNumber: string | number;
    email: string;
    isSubmitted: boolean;
    requestTypeId: number | string;
    cityId: number | string;
  };
  trainingProgram: {
    nameAr: string;
    nameEn: string;
    programLevelLookupId: number;
    trainingProgramTypeId: number;
    trainingProgramType?: number;
    trainingProgramDuration: number;
    totalTrainingHours: number;
    programObjective: string;
    languageLookupId: number;
    targetCategory: string;
    description: string;
    isSubmitted: boolean;
  };
  trainingProgramDocuments: {
    isSubmitted: boolean;
    programDocuments: {
      programDocumentLookupId: number;
      files: {
        trainingProgramDocumentMappingFileId: number;
        file: File[];
      }[];
    }[];
  };
  generalCommitment: {
    organizationName: string;
    applicantsName: string;
    file: File;
    generalCommitmentFileId: number;
    fileId: number;
    isSubmitted: boolean;
  };
};

export type UpdateAccreditationForm = {
  trainingOrganization: {
    id: number;
    organizationName: string;
    coordinatorName: string;
    trainingLicenseNumber: string | number;
    licenseDate: Date;
    licenseExpirationDate: Date;
    address: string;
    phoneNumber: string | number;
    email: string;
    isSubmitted: boolean;
    requestTypeId: number | string;
    cityId: number | string;
  };
  trainingProgram: {
    id: number;
    nameAr: string;
    nameEn: string;
    programLevelLookupId: number;
    trainingProgramTypeId: number;
    trainingProgramType?: number;
    trainingProgramDuration: number;
    totalTrainingHours: number;
    programObjective: string;
    languageLookupId: number;
    targetCategory: string;
    description: string;
    isSubmitted: boolean;
  };
  trainingProgramDocuments: {
    isSubmitted: boolean;
    id: number;
    programDocuments: {
      programDocumentLookupId: number;
      files: {
        trainingProgramDocumentMappingFileId: number;
        file: File[];
      }[];
    }[];
  };
  generalCommitment: {
    id: number;
    organizationName: string;
    applicantsName: string;
    file: File;
    generalCommitmentFileId: number;
    fileId: number;
    isSubmitted: boolean;
  };
};

@Injectable({
  providedIn: 'root',
})
export class AccreditationFormService {
  private url: string = `${environment.apiUrl}/api/TrainingProgramRequest/CreateOrUpdateTrainingOrganization`;

  constructor(
    private http: HttpClient,
    private objectToFormDataConverter: ObjectToFormDataService,
  ) {}

  sendNewApplicationForm(
    accreditationFormBody: NewAccreditationForm,
  ): Observable<any> {
    const response = this.http.post<any>(
      this.url,
      this.objectToFormDataConverter.convert(accreditationFormBody, true),
    );
    return response;
  }
}
