import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MenuModule } from 'primeng/menu';

import { DataService } from '../dashboard/services/data.service';

import { FooterComponent } from './footer/footer.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SigninFormComponent } from './signin-form/signin-form.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SvgDefinitionsComponent } from './svg-definitions/svg-definitions.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { HeaderComponent } from './header/header.component';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UsagePolicyComponent } from './usage-policy/usage-policy.component';
import { ProfileComponent } from './profile/profile.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FooterComponent,
    NewsletterComponent,
    SigninFormComponent,
    SignupFormComponent,
    ForgotPasswordComponent,
    SvgDefinitionsComponent,
    SvgIconComponent,
    HeaderComponent,
    VerifyOTPComponent,
    CustomButtonComponent,
    CreateNewPasswordComponent,
    SubHeaderComponent,
    UserGuideComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    UsagePolicyComponent,
    ProfileComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule,
    MenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfViewerModule,
  ],
  exports: [
    FooterComponent,
    NewsletterComponent,
    SigninFormComponent,
    SignupFormComponent,
    ForgotPasswordComponent,
    TranslateModule,
    ReactiveFormsModule,
    SvgDefinitionsComponent,
    SvgIconComponent,
    HeaderComponent,
    CustomButtonComponent,
    SubHeaderComponent,
    UserGuideComponent,
    PdfViewerModule,
    FaqComponent,
    PrivacyPolicyComponent,
    UsagePolicyComponent,
    ProfileComponent,
    MenuModule,
  ],
  providers: [DataService],
})
export class SharedModule {}
