import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { Status } from 'src/app/dashboard/types/status';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [LoadingService],
})
export class DialogComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() id: number;
  @Input() trainingOrganizationStatusEnum: number;
  @Input() generalNotes: string;
  @Input() isChecked: boolean;
  @Input() proposedTechnicalAdvisor: any = null;
  @Input() proposedWorkingHours: number = null;
  @Input() userIsCommitteeSecretary: boolean = false;
  @Output() onHide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  expertForm: FormGroup;
  variableStatus: string = '';
  expertOptions: any;
  expertSelectedOptionId: number;
  expertSelectedOptionValue: string;
  constructor(
    private auditService: AuditService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
  ) {
    this.expertForm = this.fb.group({
      hours: ['', Validators.required],
    });
  }

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit() {
    this.auditService.getExpertList().subscribe({
      next: (data) => {
        this.expertOptions = data.data;
      },
      error: (error) => {
        console.log('Error getting the experts list', error);
      },
    });
    this.expertForm.get('hours').patchValue(this.proposedWorkingHours);

    if (this.proposedTechnicalAdvisor?.id) {
      this.expertSelectedOptionId = this.proposedTechnicalAdvisor?.id;
      this.expertSelectedOptionValue = this.proposedTechnicalAdvisor?.userName;
    }
  }

  closeModal() {
    this.onHide.emit(this.visible);
  }

  cancel() {
    if (!this.proposedTechnicalAdvisor?.id) this.expertForm.reset();
    this.visible = false;
    this.onHide.emit(this.visible);
  }

  getExpert(expert: number) {
    this.expertSelectedOptionId = expert;
  }

  sendTechnicalAdvisor(userIsCommitteeSecretary: boolean) {
    if (this.expertForm.valid) {
      const body = {
        trainingOrganizationId: this.id,
        userAssignedId: this.expertSelectedOptionId,
        workingHours: this.expertForm.get('hours')!.value,
      };

      const statusBody = {
        TrainingOrganizationStatusEnum: userIsCommitteeSecretary
          ? Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert
          : Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary,
        TrainingOrganizationId: this.id,
        HasGeneralComments: userIsCommitteeSecretary
          ? undefined
          : this.isChecked,
        HasFieldVisit: userIsCommitteeSecretary ? undefined : false,
        FieldVisitFormFile: userIsCommitteeSecretary
          ? undefined
          : {
              File: '',
            },
        TrainingProgramDocumentNotes: userIsCommitteeSecretary
          ? undefined
          : [
              {
                id: 0,
                note: this.generalNotes,
              },
            ],
      };

      this.loadingService.startLoading();
      this.auditService.sendExpertData(body).subscribe({
        next: () => {
          this.auditService.updateApplicationStatus(statusBody).subscribe({
            next: () => {
              this.onHide.emit(false);
              this.loadingService.stopLoading();
              this.onSuccess.emit(true);
            },
            error: (error) => {
              console.log('Error sending the data', error);
            },
          });
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
    }
  }
}
