<div [id]="id" class="container">
  <div class="label-container">
    <div class="file-label">
      {{ title }}
      <small *ngIf="isRequired">*</small>

      <svg
        *ngIf="!!descriptionLines.length"
        (click)="infoIconClickHandler()"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <use [attr.xlink:href]="'#info-icon'"></use>
      </svg>
      <ul class="subtitles-list" *ngIf="subtitles.length">
        <li *ngFor="let subtitle of subtitles">
          {{ subtitle }}
        </li>
      </ul>
    </div>

    <app-modal
      [modalContent]="modalContent"
      [showModal]="showModal"
      (closeModal)="showModal = false"
      [customClass]="'documents-modal'"
    >
      <div class="modal-header">
        <h2>{{ title }}</h2>
        <p class="popup-subtitle">{{ popupSubtitle }}</p>
      </div>
      <div class="modal-body">
        <ul>
          <li *ngFor="let descriptionLine of descriptionLines">
            {{ descriptionLine.value }}
          </li>
        </ul>
      </div>
    </app-modal>

    <div
      *ngIf="!readonly"
      [id]="id + '-input-container'"
      class="input-container"
    >
      <label [for]="id + '-input-field'">أرفق الملف</label>
      <input
        #fileInput
        [id]="id + '-input-field'"
        accept=".pdf"
        type="file"
        (change)="onFileSelect($event)"
        multiple
      />
    </div>
  </div>
  <div
    class="file-icons-list-container"
    [id]="id + '-file-list'"
    *ngIf="files?.length"
  >
    <file-icon
      [readOnly]="readonly"
      *ngFor="let file of files; let i = index"
      [file]="file"
      [index]="i"
      showIcon="{{ file?.fileSize || file?.size }}"
      (removeFileEmmiter)="removeFile($event)"
    ></file-icon>
  </div>
  <small *ngIf="viewErrorMessage" [id]="id + '-file-upload-error-message'">{{
    errorMessage
  }}</small>
</div>
