import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { filesAssets } from '@assets/files';
import { MenuItem } from 'primeng/api';
import { ModalService } from 'src/app/dashboard/services/modal.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrl: './sub-header.component.scss',
})
export class SubHeaderComponent implements OnInit {
  @Output() resetIsUserLogin = new EventEmitter<boolean>();

  items: MenuItem[] | undefined;
  isLoggedIn: boolean;
  isMenuOpen = false;
  isModalOpen = false;
  pdfURL: string;
  title: string = '';
  showSelect = false;
  urlPathname = window.location.pathname;
  pathnameSegments = this.urlPathname.split('/');
  lastSegment = '/' + this.pathnameSegments[this.pathnameSegments.length - 1];

  links = [
    {
      title: 'home',
      routerLink: '/home',
    },
    {
      title: 'عن الاعتماد',
      routerLink: '/our-services',
    },
    {
      title: 'اللائحة',
      onClick: 'regulationsTab',
    },
    {
      title: 'الدليل الارشادي',
      onClick: 'guideTab',
    },
  ];

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  constructor(
    private authService: AuthService,
    public modalService: ModalService,
    private renderer: Renderer2,
    private router: Router,
    private authGuard: AuthGuard,
  ) {
    this.modalService.showModal$.subscribe((isOpen) => {
      if (isOpen) {
        this.isModalOpen = isOpen;

        this.renderer.addClass(document.body, 'modal-open'); // Add the class
      } else {
        this.isModalOpen = isOpen;

        this.renderer.removeClass(document.body, 'modal-open'); // Remove the class
      }
    });
    this.isLoggedIn = this.authService.isUserAuthenticated();
  }

  handleTabClick(value: string) {
    this.pdfURL =
      value === 'regulationsTab'
        ? filesAssets.trainingPrograms
        : filesAssets.userGuide;
    this.title =
      value === 'regulationsTab'
        ? 'لائحة اعتماد البرامج التدريبية'
        : 'الدليل الإرشادي';
    this.modalService.openModal();
  }

  ngOnInit() {
    this.items = [
      {
        label: 'لوحة المعلومات',
        icon: 'pi pi-table',
        command: () => {
          this.redirectToDashboard(event);
        },
      },
      {
        label: 'تسجيل الخروج',
        icon: 'pi pi-sign-out',
        command: () => {
          this.handleLogout(event);
        },
      },
    ];
  }

  handleLogout(event: Event) {
    this.authGuard.setRedirectionUrl('');
    event.stopPropagation();
    this.authService.logOut().subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.resetIsUserLogin.emit(!this.isLoggedIn);
          this.isLoggedIn = !this.isLoggedIn;
          localStorage.removeItem('auth_token');
          localStorage.removeItem('email');
          localStorage.removeItem('role');
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('userName');
        }
      },
    });
  }

  redirectToDashboard(event: Event) {
    event.stopPropagation();

    this.router.navigate(['/dashboard']);
  }

  handleDivClick(event: Event) {
    event.stopPropagation();
    this.pdfURL = filesAssets.userGuide;
    this.title = 'الدليل الارشادي';
    this.showSelect = false;
    this.modalService.openModal();
  }
}
