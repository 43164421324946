<div class="breadcrumbs-container">
  <div class="breadcrumb" *ngFor="let level of levels; let i = index">
    <a
      class="level-name"
      *ngIf="level.path; else titleWithoutAnchor"
      [routerLink]="level.path"
      >{{ level.name | translate }}</a
    >
    <ng-template #titleWithoutAnchor>
      <div class="level-name">{{ level.name | translate }}</div>
    </ng-template>
    <app-svg-icon
      *ngIf="levels.length - 1 !== i"
      name="arrow-down"
      class="arrow-left"
    ></app-svg-icon>
  </div>
</div>
