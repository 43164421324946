import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss'],
})
export class ApplicationDetailsComponent {
  @ViewChild('tabsContainer') tabsContainer: ElementRef;
  @Output() activeTabIndexChange = new EventEmitter<number>();
  id: number | string;
  activeTabIndex: number = 0;
  signatureImageURL: string = 'assets/images/electronicSignature.png';
  selectedFiles: File[] = [];
  openModal: boolean = false;
  fileIndex: number = 0;
  tabsData: tabsData[];
  rowData: number;
  fileURL: string = '';
  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
  ];

  requestData: any = {};
  requestStatus: number;
  showRequestDetails: boolean = false;
  userShouldNotSeeApplication: boolean = false;
  committeeSecretaryRelatedStatuses: number[] = [
    Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary,
    Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary,
    Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary,
    Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary,
  ];
  usersThatShouldSeeRequestDetailsButton: number[] = [
    UserRole.CommitteeMember,
    UserRole.CommitteeHead,
    UserRole.CommitteeSecretary,
    UserRole.GeneralManager,
  ];
  committeeHeadRelatedStatuses: number[] = [
    Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers,
    Status.ApprovedByCommitteeMembersWaitingCommitteeHead,
  ];
  showRequestDetailsButton: boolean;
  showVotingForm: boolean;

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private auditService: AuditService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.levels.push({
        name: 'orderDetails',
        path: '',
      });

      this.auditService.getApplicationData(this.id).subscribe((response) => {
        if (response.data) this.requestData = response.data;
        else this.router.navigate(['/error-page']);
        this.requestStatus =
          response.data.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

        switch (this.requestStatus) {
          case Status.NewOrderWaitingInternalAuditor:
            this.userShouldNotSeeApplication = !this.isInternalAuditor();
            break;
          case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
            this.userShouldNotSeeApplication =
              !this.isCommitteeSecretary() && !this.isInternalAuditor();
            break;
          case Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert:
            this.userShouldNotSeeApplication =
              !this.isTechnicalAdvisor() && !this.isInternalAuditor();
            break;
          case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
            this.userShouldNotSeeApplication =
              !this.isCommitteeSecretary() && !this.isInternalAuditor();
            break;
          case Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers:
            this.userShouldNotSeeApplication =
              !this.isCommitteeMember() && !this.isInternalAuditor();
            break;
          case Status.ApprovedByCommitteeMembersWaitingCommitteeHead:
            this.userShouldNotSeeApplication =
              !this.isCommitteeHead() &&
              !this.isInternalAuditor() &&
              !this.isCommitteeSecretary();
            break;
          case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
            this.userShouldNotSeeApplication =
              !this.isCommitteeSecretary() && !this.isInternalAuditor();
            break;
          case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
            this.userShouldNotSeeApplication = !this.isCommitteeSecretary();
            break;
          case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
            this.userShouldNotSeeApplication = !this.isGeneralManager();
            break;
          case Status.Draft:
          case Status.ApprovedByCommitteeSecretaryWaitingPayment:
          case Status.ThePaymentWasMadeWaitingFinancialAuditor:
          case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
          default:
            this.userShouldNotSeeApplication = true;
            break;
        }

        if (
          this.userShouldNotSeeApplication ||
          this.requestData.trainingOrganization.isEditable
        )
          this.router.navigate(['/error-page']);

        this.showRequestDetails =
          this.isCommitteeSecretary() ||
          (this.isCommitteeHead() &&
            this.requestStatus ===
              Status.ApprovedByCommitteeMembersWaitingCommitteeHead) ||
          (this.isCommitteeMember() &&
            this.requestStatus ===
              Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers) ||
          (this.isGeneralManager() &&
            this.requestStatus >=
              Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary);
      });
    });
  }

  isInternalAuditor(): boolean {
    return this.authService.hasRole(UserRole.InternalAuditor);
  }

  isTechnicalAdvisor(): boolean {
    return this.authService.hasRole(UserRole.TechnicalAdvisor);
  }

  isCommitteeSecretary(): boolean {
    return this.authService.hasRole(UserRole.CommitteeSecretary);
  }

  isCommitteeMember(): boolean {
    return this.authService.hasRole(UserRole.CommitteeMember);
  }

  isCommitteeHead(): boolean {
    return this.authService.hasRole(UserRole.CommitteeHead);
  }

  isGeneralManager(): boolean {
    return this.authService.hasRole(UserRole.GeneralManager);
  }

  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;
    if (kbSize < 1024) {
      return kbSize.toFixed(2) + ' KB';
    } else {
      const mbSize = kbSize / 1024;

      return mbSize.toFixed(2) + ' MB';
    }
  }

  navigateToPreviousTab = (): void => {
    if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
      this.scrollIntoView();
    }
  };

  scrollIntoView() {
    const element = this.el.nativeElement; // If you are using ElementRef
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  viewFile(index: number): void {
    this.openModal = true;
    this.fileURL =
      this.tabsData[this.activeTabIndex].tabData[index].files[index].file;
  }

  hideModal(): void {
    this.openModal = false;
    this.fileURL = '';
  }

  onNextFile(activeTab: number, tabIndex: number, index: number): void {
    if (index <= this.tabsData[activeTab].tabData[tabIndex].files.length - 1) {
      this.fileURL =
        this.tabsData[activeTab].tabData[tabIndex].files[index].file;
      this.fileIndex++;
    }
  }

  onPreviousFile(activeTab: number, tabIndex: number, index: number): void {
    if (tabIndex >= 0) {
      this.fileURL =
        this.tabsData[activeTab].tabData[tabIndex].files[index - 1].file;
      this.fileIndex--;
    }
  }

  hideRequestDetails(event: boolean) {
    this.showRequestDetails = event;
    this.showVotingForm = !event;
  }
}

export interface FileInputSetting {
  id: string;
  fileList: File[];
  text: string;
}

export interface tabData {
  icon: string;
  name: string;
  value: string;
  files: files[];
  rowName: string;
}

export interface files {
  name: string;
  size: number;
  file: string;
}

export interface tabsData {
  tabId: number;
  tabData: tabData[];
  rowTitle: string;
}
