<loading-spinner></loading-spinner>
<div *ngIf="!(isLoading$ | async)" class="container">
  <div class="header">
    <div class="header flex">
      <div class="flex next-flex">
        <app-page-title
          [title]="'طلب رقم  '"
          [customClass]="['title-container', 'black-color']"
        ></app-page-title>
        <app-page-title
          [title]="'#' + reportId"
          [customClass]="['title-container', 'green-color']"
        ></app-page-title>
      </div>
    </div>
  </div>
  <app-breadcrumbs [levels]="levels"></app-breadcrumbs>

  <div class="main-container">
    <app-page-title
      [title]="'تفاصيل الدفع'"
      [customClass]="['application-details']"
    ></app-page-title>
    <div class="new-section">
      <p class="title">
        مبروك لقد تم قبول طلبك من قبل المركز السعودي لكفاءة الطاقة!
      </p>
      <p class="payment-instruction-text">
        الرجاء تحويل المبلغ المطلوب ادناه إلى الحساب رقم
        <span class="account-number"> {{ paymentData.account }}</span>
        وإرفاق الإيصال في المكان المناسب في الصفحة.
      </p>
    </div>
    <p class="payment-instruction-text small-font">
      ملاحظة: يجب السداد خلال مدة لا تتجاوز (30) يوم من قبول اللجنة.
    </p>

    <div class="new-section-border">
      <app-page-title
        [title]="'الفاتورة'"
        [customClass]="['application-details']"
      ></app-page-title>
      <div *ngFor="let item of paymentData.billing" class="flex">
        <p class="payment-instruction-text">
          {{ item.title }} {{ organizationName }}
        </p>
        <p class="payment-instruction-text">
          {{ item.amount | number }}

          <span class="green"> SAR </span>
        </p>
      </div>
      <div class="divider"></div>
      <div class="total-amount flex">
        <p>مجموع المبلغ</p>
        <p>
          {{ paymentData.totalAmount | number }}
          <span class="green"> SAR </span>
        </p>
      </div>
    </div>
    <div class="flex file-attachments">
      <div>
        <p class="payment-instruction-text">رفع إيصال السداد بعد الدفع</p>

        <div class="files-container">
          <div
            class="file-item"
            *ngFor="let data of imagesArr; let isLast = last; let index = index"
            (mouseenter)="data['hovered'] = true"
            (mouseleave)="data['hovered'] = false"
          >
            <div class="file-item__container" [ngClass]="{ border: !isLast }">
              <img [src]="file" />
              <div class="file-info">
                <div
                  class="remove-button"
                  *ngIf="data['hovered']"
                  (click)="removeImage(index)"
                >
                  <img [src]="closeImg" />
                </div>
                <span class="file-name">{{ data['name'] }}</span>
                <span class="file-size">{{ data['size'] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-upload-invoice
        class="upload-button"
        title="رفع الإيصال"
        (image)="getImageSrc($event)"
        (imagesList)="getImagesArr($event)"
        (fileBinary)="handleFileData($event)"
      ></app-upload-invoice>
    </div>

    <div class="flex next-flex new-section">
      <button
        class="attach-button submit-button"
        (click)="createInvoice()"
        [disabled]="!(imagesArr.length > 0)"
      >
        إرسال الإيصال
      </button>
      <button class="attach-button previous-button">
        <a
          routerLink="/dashboard/accreditation-form/{{ organizationId }}"
          class="link"
        >
          العودة إلى تفاصيل الطلب
        </a>
      </button>
    </div>
    <div class="success-modal__container">
      <app-modal
        [modalContent]="'submission'"
        [showModal]="showSubmitModal"
        (closeModal)="closeSubmitModal()"
        [customClass]="'success-modal'"
        [isCloseVisible]="submittedSuccessfully"
      >
        <loading-spinner>جاري إرسال الطلب...</loading-spinner>
        <div *ngIf="submittedSuccessfully" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{ 'تم إرسال الإيصال  بنجاح' }}</span>
        </div>
      </app-modal>
    </div>
  </div>
</div>
