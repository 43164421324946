<nav class="navigation-container">
  <div class="navigation-items-container">
    <div class="burger" (click)="toggleMenu()">
      <i class="material-icons">{{ isMenuOpen ? 'close' : 'menu' }}</i>
    </div>
    <div class="modal-overlay" *ngIf="modalService.showModal$ | async">
      <app-user-guide [pdfURL]="pdfURL" [title]="title"></app-user-guide>
    </div>
    <div class="navigation-items" [class.is-open]="isMenuOpen">
      <div *ngFor="let link of links">
        <a
          [ngClass]="{ active: link.routerLink === lastSegment }"
          class="navigation-item"
          routerLink="{{ link.routerLink || lastSegment }}"
          (click)="link.onClick ? handleTabClick(link.onClick) : null"
          >{{ link.title | translate }}</a
        >
      </div>
    </div>
  </div>
  <div *ngIf="isLoggedIn">
    <div class="navigation-items" [class.is-open]="isMenuOpen">
      <button class="btn help-btn" (click)="handleDivClick($event)">
        <app-svg-icon class="help-icon" name="help-icon"></app-svg-icon>
      </button>
      <div class="modal-overlay" *ngIf="modalService.showModal$ | async">
        <app-user-guide [pdfURL]="pdfURL" [title]="title"></app-user-guide>
      </div>
      <div class="pipe"></div>
      <button class="btn notification-btn">
        <app-svg-icon
          class="notification-icon"
          name="notification-icon-with-circle"
        ></app-svg-icon>
      </button>
      <div class="pipe"></div>
      <div
        class="card flex justify-content-center"
        (click)="menu.toggle($event)"
      >
        <div class="down-arrow-container">
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L7 7.5L13 1.5"
              stroke="#9D9B9B"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p-menu #menu [model]="items" [popup]="true"></p-menu>
        <app-profile [isProfileVisible]="false"></app-profile>
      </div>
    </div>
  </div>
</nav>
