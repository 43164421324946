import { Component, OnInit } from '@angular/core';
import { Status } from '@app/dashboard/types/status';
import { UserRole } from 'src/app/types/roles';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AuditService } from '@app/dashboard/services/audit.service';

@Component({
  selector: 'voting-page',
  templateUrl: './voting-page.component.html',
  styleUrl: './voting-page.component.scss',
})
export class VotingPageComponent implements OnInit {
  id: number | string = null;

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
  ];

  requestData: any = {};
  requestStatus: number;
  userShouldNotSeeVoting: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private auditService: AuditService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.levels.push({
        name: 'committeVotingForm',
        path: '',
      });

      this.auditService.getApplicationData(this.id).subscribe((response) => {
        if (response.data) {
          this.requestData = response.data;
          this.requestStatus =
            response.data.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;
          switch (this.requestStatus) {
            case Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers:
              this.userShouldNotSeeVoting =
                !this.isCommitteeMember() && !this.isCommitteeHead();
              break;
            case Status.ApprovedByCommitteeMembersWaitingCommitteeHead:
              this.userShouldNotSeeVoting =
                !this.isCommitteeHead() && !this.isCommitteeSecretary();
              break;
            case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
              this.userShouldNotSeeVoting = !this.isCommitteeSecretary();
              break;
            case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
              this.userShouldNotSeeVoting = !this.isCommitteeSecretary();
              break;
            case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
              this.userShouldNotSeeVoting = !this.isGeneralManager();
              break;
            case Status.Draft:
            case Status.NewOrderWaitingInternalAuditor:
            case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
            case Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert:
            case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
            case Status.ApprovedByCommitteeSecretaryWaitingPayment:
            case Status.ThePaymentWasMadeWaitingFinancialAuditor:
            case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
            default:
              this.userShouldNotSeeVoting = true;
              break;
          }
        } else this.router.navigate(['/error-page']);
        if (
          this.userShouldNotSeeVoting ||
          this.requestData.trainingOrganization.isEditable
        )
          this.router.navigate(['/error-page']);
      });
    });
  }

  isInternalAuditor(): boolean {
    return this.authService.hasRole(UserRole.InternalAuditor);
  }

  isTechnicalAdvisor(): boolean {
    return this.authService.hasRole(UserRole.TechnicalAdvisor);
  }

  isCommitteeSecretary(): boolean {
    return this.authService.hasRole(UserRole.CommitteeSecretary);
  }

  isCommitteeMember(): boolean {
    return this.authService.hasRole(UserRole.CommitteeMember);
  }

  isCommitteeHead(): boolean {
    return this.authService.hasRole(UserRole.CommitteeHead);
  }

  isGeneralManager(): boolean {
    return this.authService.hasRole(UserRole.GeneralManager);
  }

  requestDetailsButtonHandler() {
    this.router.navigate(['/dashboard/application-details', this.id]);
  }
}
