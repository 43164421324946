import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUpload {
  @Input() id: string;
  @Input() title: string;
  @Input() popupSubtitle: string;
  @Input() subtitles: string[] = [];
  @Input() descriptionLines: any[] = [];
  @Input() control: FormControl;
  @Input() files: any[] = [];
  @Input() readonly: boolean = false;
  @Input() isRequired: boolean = true;
  @Input() errorMessage: string = 'هذا الحقل مطلوب';
  @Input() acceptPdfFilesOnly: boolean = true;
  @Input() modifyRemovedFiles: boolean = false;

  viewErrorMessage: boolean = false;

  @ViewChild('fileInput') fileInput: ElementRef;

  modalContent: 'information' | 'submission' = 'information'; // Set the default content to 'information'
  showModal = false;

  constructor() {}

  onFileSelect(event: any): void {
    const selectedFile = event.target.files[0];

    if (selectedFile.type !== 'application/pdf' && this.acceptPdfFilesOnly) {
      this.errorMessage = 'يرجى تحميل ملفات بصيغة PDF فقط.';
      this.viewErrorMessage = true;
      return;
    }

    this.files = [...this.files, ...event.target.files];
    this.control.patchValue(this.files);
    this.fileInput.nativeElement.value = null;
    this.viewErrorMessage = false;
  }

  infoIconClickHandler() {
    this.showModal = true;
  }

  removeFile(fileIndex: number): void {
    if (this.modifyRemovedFiles) {
      this.files[fileIndex] = {
        trainingProgramDocumentMappingFileId:
          this.files[fileIndex].trainingProgramDocumentMappingFileId,
        file: '',
      };
    } else this.files.splice(fileIndex, 1);

    this.control.patchValue(this.files.length ? this.files : []);
    if (!this.files?.length || this.control.errors?.['requiredFiles']) {
      this.errorMessage = 'هذا الحقل مطلوب';
      this.viewErrorMessage = true;
    }
  }
}
