import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AccreditationFormService,
  NewAccreditationForm,
  UpdateAccreditationForm,
} from 'src/app/dashboard/services/accreditation-form.service';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { BodyScrollService } from 'src/app/dashboard/services/body-scroll.service';
import { ModalService } from 'src/app/dashboard/services/modal.service';
import { LookupsTypeEnum } from 'src/app/dashboard/types/lookUps';
import { Status } from 'src/app/dashboard/types/status';
import { Tab } from 'src/app/dashboard/types/tabs.type';
import { GetLookupsService } from 'src/app/get-look-ups.service';
import { Formatter } from '@utils/formatters';
import { DropdownOption } from '../dropdown/dropdown.component';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { Rejex } from '@utils/rejex';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { Assets } from '@assets/images';

@Component({
  selector: 'app-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
})
export class FormTabsComponent implements OnInit {
  @ViewChild('signatureInput') signatureInput: ElementRef;
  @Input() tabs: Tab[] = [];
  @Input() activeTabIndex: number = 0;
  isReadOnly: boolean = false;
  @Output() activeTabIndexChange = new EventEmitter<number>();
  @Output() reportIdEmitter = new EventEmitter<number | string>();
  id: number;
  requestData: any = {};
  signatureImageUrl: string = Assets.signature;
  selectedFiles: File[] = [];
  showModal = false;
  savingAsDraft = false;
  cities: DropdownOption[] = [];
  accreditationApplicationTypes: DropdownOption[] = [];
  trainingProgramLevels: DropdownOption[] = [];
  trainingProgramType: DropdownOption[] = [];
  trainingProgramLanguages: DropdownOption[] = [];
  requiredDocuments: DropdownOption[] = [];
  submittedSuccessfully: boolean = false;
  certificateData: any = null;
  licenseDateError: boolean = false;
  licenseExpiryDateError: boolean = false;
  isAcceptedImgFormat: boolean = true;
  licenseExpiryMinDate: Date = new Date();
  licenseMaxDate: Date = new Date();
  isEditableApplication: boolean = false;
  numberOnlyKeyFilter: RegExp = Rejex.NumbersOnly;
  accreditationForm: FormGroup;
  isLoadingSignature: boolean;

  setActiveTab(index: number) {
    this.activeTabIndex = index;
  }
  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private fb: FormBuilder,
    private el: ElementRef,
    private bodyScrollService: BodyScrollService,
    public modalService: ModalService,
    private router: Router,
    private dropdownsOptionsService: GetLookupsService,
    private submitService: AccreditationFormService,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  getCurrentRoute(): string {
    return this.router.url;
  }

  onSubmit({ asDraft = false }): void {
    this.savingAsDraft = asDraft;
    const requestBody: NewAccreditationForm | UpdateAccreditationForm = {
      trainingOrganization: {
        id: this.id ? this.requestData?.trainingOrganization?.id : undefined,
        organizationName: this.accreditationForm.value.agencyName,
        coordinatorName: this.accreditationForm.value.cordinatorName,
        trainingLicenseNumber: this.accreditationForm.value.licenseNumber,
        licenseDate: this.accreditationForm.value.licenseDate,
        licenseExpirationDate: this.accreditationForm.value.licenseExpiryDate,
        address: this.accreditationForm.value.address,
        phoneNumber: this.accreditationForm.value.mobileNumber,
        email: this.accreditationForm.value.email,
        isSubmitted: !asDraft,
        requestTypeId: this.accreditationForm.value.applicationType,
        cityId: this.accreditationForm.value.city,
      },
      trainingProgram: {
        id: this.id ? this.requestData?.trainingProgram?.id : undefined,
        nameAr: asDraft
          ? this.accreditationForm.value.trainingProgramName ||
            'draftArNameForTesting'
          : this.accreditationForm.value.trainingProgramName,
        nameEn: asDraft
          ? this.accreditationForm.value.trainingProgramEnglishName ||
            'draftEnNameForTesting'
          : this.accreditationForm.value.trainingProgramEnglishName,
        programLevelLookupId: this.accreditationForm.value.trainingProgramLevel,
        trainingProgramTypeId: this.accreditationForm.value.trainingProgramType,
        trainingProgramDuration:
          this.accreditationForm.value.trainingProgramDays,
        totalTrainingHours: this.accreditationForm.value.trainingProgramHours,
        programObjective: this.accreditationForm.value.trainingProgramPurpose,
        languageLookupId: this.accreditationForm.value.trainingProgramLanguage,
        targetCategory: this.accreditationForm.value.targetedCategory,
        description: this.accreditationForm.value.programDescription,
        isSubmitted: !asDraft,
      },
      trainingProgramDocuments: {
        id: this.id
          ? this.requestData?.trainingProgramDocuments?.id
          : undefined,
        isSubmitted: !asDraft,
        programDocuments: this.getProgramDocuments(),
      },
      generalCommitment: {
        id: this.id ? this.requestData?.generalCommitment?.id : undefined,
        organizationName: this.accreditationForm.value.agencyName,
        applicantsName: this.accreditationForm.value.applicantName,
        file:
          this.accreditationForm.value.signature instanceof File
            ? this.accreditationForm.value.signature
            : '',
        fileId:
          this.accreditationForm.value.signature instanceof File
            ? ''
            : this.accreditationForm.value.signature
              ? this.requestData?.generalCommitment?.generalCommitmentFile?.file
                  ?.fileId
              : '',
        generalCommitmentFileId:
          this.requestData?.generalCommitment?.generalCommitmentFile?.file
            ?.generalCommitmentFileId || 0,
        isSubmitted: !asDraft,
      },
    };
    this.showModal = true;
    this.submitService
      .sendNewApplicationForm(requestBody)
      .subscribe((response) => {
        if (response.data) this.submittedSuccessfully = true;
      });
  }

  readOnly() {
    this.isReadOnly = false;
  }

  ngOnInit() {
    this.initializeComponentBasedOnRoute();
  }

  openModal() {
    this.showModal = true;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;

    return kbSize < 1024
      ? kbSize.toFixed(2) + ' KB'
      : (kbSize / 1024).toFixed(2) + ' MB';
  }

  openFileInput() {
    if (this.signatureInput) {
      this.signatureInput.nativeElement.click();
    }
  }

  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];
    const allowedImgExtensions = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
    ];

    if (selectedFile) {
      if (!allowedImgExtensions.includes(selectedFile?.type)) {
        this.isAcceptedImgFormat = false;
        return;
      }

      this.isAcceptedImgFormat = true;
      this.accreditationForm.patchValue({ signature: selectedFile });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.signatureImageUrl = e.target.result;
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  deleteSignature(event: Event) {
    event.stopPropagation();
    this.accreditationForm.get('signature').patchValue(null);
    this.signatureInput.nativeElement.value = '';
    this.signatureImageUrl = 'assets/images/signature.png';
  }

  navigateToPreviousTab = (): void => {
    if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
      this.scrollIntoView();
    }
  };

  navigateToNextTab = (): void => {
    if (this.activeTabIndex < this.tabs.length - 1) {
      this.activeTabIndex++;
      this.scrollIntoView();
    }
  };

  scrollIntoView() {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  userGuideManual() {
    this.modalService.openModal();
  }

  closeModel() {
    this.showModal = false;
    this.router.navigate(['/dashboard/my-applications']);
  }

  isTrainingOrganization() {
    return this.authService.hasRole(UserRole.TrainingOrganization);
  }

  getProgramDocuments() {
    const items = [];
    this.requiredDocuments.forEach((requiredDocument) => {
      const programDocumentLookupId = requiredDocument['programDocuments'].key;
      const fieldName = `fileField_${programDocumentLookupId}`;
      items.push({
        programDocumentLookupId,
        files: this.accreditationForm.value[fieldName].map((item) => ({
          trainingProgramDocumentMappingFileId:
            item?.trainingProgramDocumentMappingFileId || 0,
          file: item?.file == '' ? item?.file : item,
          fileId: item?.fileId || '',
        })),
      });
    });

    return items;
  }

  subscribeToFormChanges() {
    this.accreditationForm.valueChanges.subscribe((data) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (
        !this.id &&
        data.licenseDate &&
        today.getTime() < data?.licenseDate.getTime()
      ) {
        this.licenseDateError = true;
      } else {
        this.licenseDateError = false;
      }

      if (
        data.licenseDate &&
        data.licenseExpiryDate &&
        Formatter.dateFormatConverter(data.licenseDate) >=
          Formatter.dateFormatConverter(data.licenseExpiryDate)
      ) {
        this.licenseExpiryDateError = true;
      } else {
        this.licenseExpiryDateError = false;
      }
    });
  }

  filesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const files = control.value;

      if (!Array.isArray(files) || files.length === 0) {
        return { required: true };
      }

      const invalidFiles = files.filter((file) => {
        return file?.file === '';
      });

      if (invalidFiles?.length && invalidFiles.length == files.length) {
        return { requiredFiles: true };
      }

      return null;
    };
  }

  initializeForm() {
    this.accreditationForm = this.fb.group({
      agencyName: [
        this.requestData?.trainingOrganization?.organizationName || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      applicationType: [
        this.requestData?.trainingOrganization?.requestTypeId || '',
        Validators.required,
      ],
      licenseNumber: [
        this.requestData?.trainingOrganization?.trainingLicenseNumber || '',
        Validators.required,
      ],
      licenseDate: [
        this.requestData?.trainingOrganization?.licenseDate || '',
        Validators.required,
      ],
      licenseExpiryDate: [
        this.requestData?.trainingOrganization?.licenseExpirationDate || '',
        Validators.required,
      ],
      city: [
        this.requestData?.trainingOrganization?.cityId || '',
        Validators.required,
      ],
      address: [
        this.requestData?.trainingOrganization?.address || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      email: [
        this.requestData?.trainingOrganization?.email || '',
        [Validators.pattern(Rejex.emailOnly), Validators.required],
      ],
      mobileNumber: [
        this.requestData?.trainingOrganization?.phoneNumber || '',
        Validators.required,
      ],
      cordinatorName: [
        this.requestData?.trainingOrganization?.coordinatorName || '',
        Validators.required,
      ],
      trainingProgramName: [
        (this.requestData?.trainingProgram?.nameAr !==
          'draftArNameForTesting' &&
          this.requestData?.trainingProgram?.nameAr) ||
          '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      trainingProgramEnglishName: [
        (this.requestData?.trainingProgram?.nameEn !==
          'draftEnNameForTesting' &&
          this.requestData?.trainingProgram?.nameEn) ||
          '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      trainingProgramType: [
        this.requestData?.trainingProgram?.trainingProgramTypeId || '',
        Validators.required,
      ],
      trainingProgramLevel: [
        this.requestData?.trainingProgram?.programLevelLookupId || '',
        Validators.required,
      ],
      trainingProgramDays: [
        this.requestData?.trainingProgram?.trainingProgramDuration || '',
        Validators.required,
      ],
      trainingProgramHours: [
        this.requestData?.trainingProgram?.totalTrainingHours || '',
        Validators.required,
      ],
      trainingProgramPurpose: [
        this.requestData?.trainingProgram?.programObjective || '',
        Validators.required,
      ],
      trainingProgramLanguage: [
        this.requestData?.trainingProgram?.languageLookupId || null,
        Validators.required,
      ],
      targetedCategory: [
        this.requestData?.trainingProgram?.targetCategory || '',
        Validators.required,
      ],
      programDescription: [
        this.requestData?.trainingProgram?.description || '',
        Validators.required,
      ],
      applicantName: [
        this.requestData?.generalCommitment?.applicantsName || '',
        Validators.required,
      ],
      signature: [
        this.requestData?.generalCommitment?.generalCommitmentFile?.file?.id ||
          '',
      ],
      pledge: [
        this.requestData?.trainingOrganization?.isSubmitted || false,
        Validators.requiredTrue,
      ],
    });
  }

  fetchAndAddDynamicFileFields(): void {
    this.dropdownsOptionsService.getFileUploadData().subscribe({
      next: (res) => {
        this.requiredDocuments = res.data;
        this.addDynamicFileFields();
      },
    });
  }

  addDynamicFileFields() {
    this.requiredDocuments.forEach((document) => {
      const fieldName = `fileField_${document['programDocuments']?.key}`;
      const documentData =
        this.requestData?.trainingProgramDocuments?.trainingProgramDocumentMapping?.find(
          (mapping) =>
            mapping.programDocumentLookup.id ===
            document['programDocuments'].key,
        );

      this.accreditationForm.addControl(
        fieldName,
        this.fb.control(documentData?.files || [], [
          Validators.required,
          Validators.minLength(1),
          this.filesValidator(),
        ]),
      );
    });
  }

  initializeComponentBasedOnRoute() {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.isLoadingSignature = true;
        this.loadingService.startLoading();
        this.id = params['id'];
        this.auditService.getApplicationData(this.id).subscribe((response) => {
          if (response.data) {
            this.requestData = response.data;

            if (
              this.requestData.generalCommitment.generalCommitmentFile.file
                .fileId
            ) {
              this.auditService
                .getFile(
                  this.requestData.generalCommitment.generalCommitmentFile.file
                    .id,
                )
                .subscribe({
                  next: (data) => {
                    this.signatureImageUrl = data;
                    this.isLoadingSignature = false;
                  },
                  error: (error) => {
                    console.log('error', error);
                  },
                });
            }
            this.isEditableApplication =
              this.requestData?.trainingOrganization?.isEditable;

            if (
              this.requestData.workFlowTrainingOrganization
                ?.trainingOrganizationStatusLookupId ===
              Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued
            ) {
              this.activeTabIndex = 4;
              this.certificateData = {
                programName: this.requestData?.trainingProgram?.nameAr,
                startDate: Formatter.dateToString(
                  new Date(
                    this.requestData?.workFlowTrainingOrganization
                      ?.modificationDate,
                  ),
                ),
                referenceId: this.requestData?.trainingOrganization?.reportId,
                trainingOrganizationId: this.id,
              };
              this.tabs.push({
                label: 'certificate',
                content: 'certificate tab',
              });
            }

            this.reportIdEmitter.emit(
              this.requestData.trainingOrganization.reportId,
            );

            this.initializeForm();
            this.fetchAndAddDynamicFileFields();
            this.loadDropdownOptions();
            this.subscribeToFormChanges();

            if (
              this.requestData?.trainingOrganization?.isSubmitted &&
              !this.isEditableApplication
            ) {
              this.accreditationForm.disable();
              this.isReadOnly = true;
            }

            this.loadingService.stopLoading();
          }
        });
      } else {
        this.initializeForm();
        this.fetchAndAddDynamicFileFields();
        this.loadDropdownOptions();
        this.subscribeToFormChanges();
      }
    });
  }

  showLoadingSpinner(): boolean {
    return (
      this.isLoadingSignature &&
      !!this.requestData?.generalCommitment?.generalCommitmentFile?.file?.id
    );
  }

  showElectronicSignature(): boolean {
    return (
      !this.isLoadingSignature ||
      !this.requestData?.generalCommitment?.generalCommitmentFile?.file?.id
    );
  }

  loadDropdownOptions() {
    this.loadCitiesOptions();
    this.loadRequestTypeOptions();
    this.loadProgramLevelOptions();
    this.loadProgramTypeOptions();
    this.loadLanguageOPtions();
  }

  loadCitiesOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.CitiesEnum)
      .subscribe((res) => {
        this.cities = res.data;
      });
  }

  loadRequestTypeOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.RequestTypeEnum)
      .subscribe((res) => {
        this.accreditationApplicationTypes = res.data;
      });
  }

  loadProgramLevelOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.ProgramLevelEnum)
      .subscribe((res) => {
        this.trainingProgramLevels = res.data;
      });
  }

  loadProgramTypeOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.ProgramTypeEnum)
      .subscribe((res) => {
        this.trainingProgramType = res.data;
      });
  }

  loadLanguageOPtions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.LanguageEnum)
      .subscribe((res) => {
        this.trainingProgramLanguages = res.data;
      });
  }
}

export interface FileInputSetting {
  id: string;
  fileList: File[];
  text: string;
  data: string[];
}
