import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { setLanguage } from '../utils/languageConfig';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None, // Disable style encapsulation
})
export class AppComponent {
  router: string;

  constructor(
    private translate: TranslateService,
    private _router: Router,
  ) {
    // Set the default language and load translations
    translate.setDefaultLang('ar');
    // Set the language and direction
    setLanguage('ar', translate);
  }
}
