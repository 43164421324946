<div class="accreditation-application__container">
  <ng-container>
    <app-page-title
      *ngIf="!organizationId; else reportTitle"
      [title]="'applyForProgramAccreditation'"
      [customClass]="'black-color'"
    ></app-page-title>
  </ng-container>

  <ng-template #reportTitle>
    <app-page-title
      *ngIf="reportId"
      [title]="'applicationNumber'"
      [customClass]="'application-number__title'"
      ><span class="green-color">{{ reportId }}</span></app-page-title
    >
  </ng-template>

  <app-breadcrumbs [levels]="levels"></app-breadcrumbs>
  <app-form-tabs
    (reportIdEmitter)="setReportId($event)"
    [tabs]="tabs"
    [(activeTabIndex)]="activeTabIndex"
  >
  </app-form-tabs>
</div>
