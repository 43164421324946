import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrl: './upload-invoice.component.scss',
})
export class UploadInvoiceComponent {
  @Input() title;
  @Output() image = new EventEmitter<string>();
  selectedFile: string;

  @Output() imagesList = new EventEmitter<any>();
  imagesArr = [];

  @Output() fileBinary = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;

  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];
    const allowedImgExtensions = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
    ];

    if (
      this.imagesArr.length > 0 ||
      (selectedFile && !allowedImgExtensions.includes(selectedFile?.type))
    )
      return;

    this.fileInput.nativeElement.value = null;
    this.selectedFile = selectedFile.name;
    this.imagesArr.push({
      name: selectedFile.name,
      size: this.formatSize(selectedFile.size),
    });
    this.imagesList.emit(this.imagesArr);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.image.emit(e.target.result);
      };

      reader.readAsDataURL(selectedFile);
    }

    this.fileBinary.emit(selectedFile);
  }

  private formatSize(sizeInBytes: number): string {
    const sizeInMB = sizeInBytes / (1024 * 1024);
    return '' + sizeInMB.toFixed(2).toString() + ' MB ';
  }
}
