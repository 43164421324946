import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { Status } from 'src/app/dashboard/types/status';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserRole } from 'src/app/types/roles';

@Component({
  selector: 'app-document-inspector',
  templateUrl: './document-inspector.component.html',
  styleUrls: ['./document-inspector.component.scss'],
  providers: [LoadingService],
})
export class DocumentInspectorComponent {
  @Input() openModal: boolean;
  @Input() fileIndex: number;
  @Input() criteriaIndex: number = 0;
  @Input() fileURL: string;
  @Input() dataGroupFiles: any[]; // Update the type as per your data structure
  @Input() isCheckbox: boolean = false; // Update the type as per your data structure
  @Input() checkboxOptions: string[] = []; // Update the type as per your data structure
  @Input() tabDataIndex: number = 0;
  @Input() customClass: string;
  @Input() generalCommitment: any;
  @Input() criteriaLookup: any;
  @Input() showCriteriasWithoutNoteBox: boolean = true;
  @Input() requestStatus!: number;

  @Output() showTrainingDataChange = new EventEmitter<boolean>(); // Update the type as per your data structure
  @Output() showPledgeChange = new EventEmitter<boolean>(); // Update the type as per your data structure
  @Output() nextFile = new EventEmitter<void>();
  @Output() previousFile = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();
  @Output() changeFile = new EventEmitter<{ index: number }>();
  @Output() saveEvaluationData = new EventEmitter<any>();

  showTrainingData: boolean = true; // Update the type as per your data structure
  showPledge: boolean = false;
  criteriaListIsOpen: boolean = false;
  criteriaAddedSuccessfully: boolean = false;
  selectedListItem: string = '';
  criteriaList: any = [];
  evaluationForms: FormGroup[] = [];
  addedCriteria: any = [];
  criteriaLastIndex: number;
  criteriaKey: number = 0;
  saveCriteriasSuccessfully: boolean = false;
  activeTabIndex: number = 0;
  showModal = false;

  constructor(
    private auditService: AuditService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private authService: AuthService,
  ) {}

  userIsInternalAuditor: boolean = this.authService.hasRole(
    UserRole.InternalAuditor,
  );

  userIsTechnicalAdvisor: boolean = this.authService.hasRole(
    UserRole.TechnicalAdvisor,
  );

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  showCriteriaNotesField: boolean;

  technicalAdvisorRoleId = UserRole.TechnicalAdvisor;
  internalAuditorRoleId = UserRole.InternalAuditor;

  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  startIndex: number = 0;
  fileStartIndex: number = 0;
  pageSize: number = 6;
  currentPage: number = 1;
  applicationIsReviewedByTechnicalAdvisor: boolean;

  saveEvaluationIsClicked: boolean = false;

  ngOnInit() {
    this.showCriteriaNotesField =
      (this.userIsInternalAuditor &&
        this.requestStatus === Status.NewOrderWaitingInternalAuditor) ||
      (this.userIsTechnicalAdvisor &&
        this.requestStatus ===
          Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert) ||
      (this.userIsCommitteeSecretary &&
        this.requestStatus <
          Status.ApprovedByCommitteeSecretaryWaitingCommitteeMembers);

    this.getCriteria();
    this.dataGroupFiles.forEach((section, i) => {
      const controls = this.criteriaList[i].map((criteria) => {
        return this.fb.group({
          checkbox: [criteria.isPassed],
          note: [
            this.isCheckbox
              ? criteria?.documentCriteriaLookup?.criteriaNotes.at(-1)?.name ||
                ''
              : '',
          ],
        });
      });
      const form = this.fb.group({
        criteriaItems: this.fb.array(controls),
        otherNotes: [''], // Added initialization for otherNotes
        fileHasBeenReviewed:
          (section.documentFileHasBeenReviewes.length &&
            section.documentFileHasBeenReviewes.find((item: any) =>
              this.authService.getUserRole() === UserRole.CommitteeSecretary
                ? true
                : this.authService.getUserRole() === UserRole.InternalAuditor
                  ? item?.user?.role && item?.user?.role === 'المدقق الداخلي'
                  : item?.user?.role && item?.user?.role === 'المستشار الفني',
            )?.fileHasBeenReviewed) ||
          false,
      });

      this.evaluationForms.push(form);
    });

    this.applicationIsReviewedByTechnicalAdvisor =
      this.requestStatus &&
      this.requestStatus >=
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;
  }

  getVisibleItems(): any[] {
    this.startIndex =
      (this.tabDataIndex + 1) % this.pageSize
        ? Math.floor((this.tabDataIndex + 1) / this.pageSize) * this.pageSize
        : Math.floor(this.tabDataIndex / this.pageSize) * this.pageSize;

    return this.dataGroupFiles.slice(
      this.startIndex,
      this.startIndex + this.pageSize,
    );
  }

  getFilesPaginationItems(): any[] {
    this.fileStartIndex =
      (this.fileIndex + 1) % this.pageSize
        ? Math.floor((this.fileIndex + 1) / this.pageSize) * this.pageSize
        : Math.floor(this.fileIndex / this.pageSize) * this.pageSize;

    return this.dataGroupFiles[this.tabDataIndex].files.slice(
      this.fileStartIndex,
      this.fileStartIndex + this.pageSize,
    );
  }

  showPledgeButton(): boolean {
    const lastSetStartIndex =
      Math.floor(this.dataGroupFiles.length / this.pageSize) * this.pageSize;
    return this.startIndex + this.pageSize === lastSetStartIndex;
  }

  hasNextPage() {
    const lastSetStartIndex =
      Math.ceil(this.startIndex + 1 / this.pageSize) * this.pageSize;

    return lastSetStartIndex < this.dataGroupFiles.length - 1;
  }

  nextSection(): void {
    const lastSetStartIndex =
      Math.floor(this.dataGroupFiles.length / this.pageSize) * this.pageSize;

    if (this.startIndex + this.pageSize < lastSetStartIndex + this.pageSize) {
      this.startIndex += this.pageSize;
    }
    this.onSectionIndexClick(this.startIndex);
  }

  previousPage(): void {
    if (this.startIndex >= this.pageSize) {
      this.startIndex -= this.pageSize;
    }
    this.onSectionIndexClick(this.startIndex + this.pageSize - 1);
  }

  getCriteria() {
    this.criteriaList = this.dataGroupFiles.map((item) =>
      this.userIsInternalAuditor
        ? item.programDocumentLookup.programDocumentCriteriaMappings.filter(
            (item: any) =>
              item.documentCriteriaLookup.roleId === UserRole.InternalAuditor,
          )
        : this.userIsTechnicalAdvisor
          ? item.programDocumentLookup.programDocumentCriteriaMappings.filter(
              (item: any) =>
                item.documentCriteriaLookup.roleId ===
                UserRole.TechnicalAdvisor,
            )
          : item.programDocumentLookup.programDocumentCriteriaMappings,
    );

    this.criteriaLastIndex = this.criteriaList.length;
    return this.criteriaList;
  }

  criteriaDropdown(event: Event) {
    event.stopImmediatePropagation();
    this.criteriaListIsOpen = !this.criteriaListIsOpen;
  }

  showTrainingDataSection() {
    this.showTrainingData = true;
    this.tabDataIndex = 0;
    this.showTrainingDataChange.emit(this.showTrainingData);
    this.showPledge = false;
    this.showPledgeChange.emit(this.showPledge);
  }

  showPledgeSection() {
    this.tabDataIndex = this.dataGroupFiles.length - 1;
    this.showTrainingData = false;
    this.showTrainingDataChange.emit(this.showTrainingData);

    this.showPledge = true;
    this.showPledgeChange.emit(this.showPledge);
  }

  downloadPDF(): void {
    const link = document.createElement('a');
    link.href = this.fileURL;
    link.download = 'downloaded-file.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  selectItem(item: any): void {
    const exists = this.criteriaList[this.tabDataIndex].some(
      (element: any) =>
        element.documentCriteriaLookup?.documentCriteriaLookupId === item.key,
    );

    if (!exists) {
      const newCriteriaItem = {
        isPassed: false,
        documentCriteriaLookup: {
          name: item.value,
          documentCriteriaLookupId: item.key,
          criteriaNotes: [],
          roleId: this.authService.getUserRole(),
        },
        programDocumentCriteriaMappingsId: 0,
      };
      this.criteriaKey = item.key;

      const control = this.fb.group({
        checkbox: [false],
        note: [''],
      });

      const criteriaItemsArray = this.evaluationForms[this.tabDataIndex].get(
        'criteriaItems',
      ) as FormArray;
      criteriaItemsArray.push(control);
      this.criteriaList[this.tabDataIndex].push(newCriteriaItem);
      this.criteriaListIsOpen = false;
      this.criteriaAddedSuccessfully = true;
    }
  }

  viewFile(index: number) {
    this.fileIndex = this.fileStartIndex + index;
    this.getFileUrl(this.tabDataIndex, this.fileIndex);
  }

  onSectionIndexClick(index: number): void {
    if (!index) this.showTrainingDataSection();
    else {
      if (
        index === this.dataGroupFiles.length - 1 &&
        !this.authService.hasRole(UserRole.TechnicalAdvisor)
      ) {
        this.showTrainingData = false;
        this.showPledge = true;
        this.tabDataIndex = index;
        this.fileIndex = 0;
        this.showPledgeChange.emit(this.showPledge);
        this.showTrainingDataChange.emit(this.showTrainingData);
        return;
      } else {
        this.tabDataIndex = index;
        this.fileIndex = 0;
        this.getFileUrl(index, 0);
        this.showTrainingData = false;
        this.showTrainingDataChange.emit(this.showTrainingData);
        this.showPledge = false;
        this.showPledgeChange.emit(this.showPledge);
      }
    }
  }

  getFileId(tabIndex: number, fileIndex: number) {
    return this.dataGroupFiles[tabIndex].files[fileIndex]?.id;
  }

  getFileUrl(tabIndex: number, fileIndex: number) {
    return this.getFile(this.getFileId(tabIndex, fileIndex));
  }

  getFile(id: number) {
    if (id) {
      this.loadingService.startLoading();
      this.auditService.getFile(id).subscribe({
        next: (data) => {
          this.loadingService.stopLoading();
          this.fileURL = data;
        },
        error: (error) => {
          console.log('error', error);
        },
      });
    }
  }

  prevPage() {
    if (this.fileIndex > 0) {
      this.fileIndex--;
      this.getFileUrl(this.tabDataIndex, this.fileIndex);
    }
  }

  nextPage() {
    if (
      this.fileStartIndex + this.pageSize <
      Math.floor(
        this.dataGroupFiles[this.tabDataIndex].files.length / this.pageSize,
      ) *
        this.pageSize +
        this.pageSize
    ) {
      this.fileStartIndex += this.pageSize;
    }
    if (
      this.fileIndex <
      this.dataGroupFiles[this.tabDataIndex].files.length - 1
    ) {
      this.fileIndex++;
      this.getFileUrl(this.tabDataIndex, this.fileIndex);
    }
  }

  saveEvaluation() {
    this.saveEvaluationIsClicked = true;
    const sectionData = this.dataGroupFiles[this.tabDataIndex];
    const sectionHasCriterias =
      this.dataGroupFiles[this.tabDataIndex].programDocumentLookup
        .programDocumentCriteriaMappings.length;

    const formValues =
      this.evaluationForms[this.tabDataIndex].value.criteriaItems;
    const programDocumentCriteriaMappings = [];

    formValues.forEach((item: any, i: number) => {
      programDocumentCriteriaMappings.push({
        programDocumentCriteriaMappingId:
          this.criteriaList[this.tabDataIndex][i]?.id || 0,
        isPassed: item.checkbox || false,
        documentCriteriaLookup:
          this.criteriaList[this.tabDataIndex][i]?.documentCriteriaLookup,
        criteriaNotes: [{ id: 0, note: item.note }],
      });
    });

    const body = {
      trainingProgramDocumentMappingId:
        sectionData.trainingProgramDocumentMappingId || 0,
      programDocumentCriteriaMappings:
        programDocumentCriteriaMappings.map((item: any) => {
          return {
            ...item,
            documentCriteriaLookupId:
              item.documentCriteriaLookup.documentCriteriaLookupId,
            documentCriteriaLookup: undefined,
          };
        }) || [],
      fileHasBeenReviewes: [
        {
          id:
            this.dataGroupFiles[this.tabDataIndex]
              .documentFileHasBeenReviewes?.[0]?.id || 0,
          fileHasBeenReviewed: !sectionHasCriterias
            ? this.evaluationForms[this.tabDataIndex].value.fileHasBeenReviewed
            : true,
        },
      ],
      documentNotes: this.evaluationForms[this.tabDataIndex].value.otherNotes
        ? [
            {
              ...(this.userIsCommitteeSecretary
                ? { assignToRoleId: this.getCriteriaNoteRoleId() }
                : {}),
              note:
                this.evaluationForms[this.tabDataIndex].value.otherNotes || '',
            },
          ]
        : [],
    };

    this.auditService.saveCriteria(body).subscribe({
      next: () => {
        this.saveCriteriasSuccessfully = true;
        this.saveEvaluationData.emit({
          body: {
            trainingProgramDocumentMappingId:
              sectionData.trainingProgramDocumentMappingId || 0,
            programDocumentCriteriaMappings: programDocumentCriteriaMappings,
            fileHasBeenReviewes: [
              {
                id: 0,
                fileHasBeenReviewed: !sectionHasCriterias
                  ? this.evaluationForms[this.tabDataIndex].value
                      .fileHasBeenReviewed
                  : true,
              },
            ],
            documentNotes: this.evaluationForms[this.tabDataIndex].value
              .otherNotes
              ? [
                  {
                    ...(this.userIsCommitteeSecretary
                      ? { assignToRoleId: this.getCriteriaNoteRoleId() }
                      : {}),
                    note:
                      this.evaluationForms[this.tabDataIndex].value
                        .otherNotes || '',
                    user: {
                      role: this.authService.hasRole(UserRole.InternalAuditor)
                        ? 'المدقق الداخلي'
                        : this.authService.hasRole(UserRole.CommitteeSecretary)
                          ? 'امين اللجنة'
                          : 'المستشار الفني',
                      userName:
                        this.authService.getUserName() || 'Internal Auditor',
                      roleId: this.authService.getUserRole(),
                    },
                  },
                ]
              : [],
          },
          tabDataIndex: this.tabDataIndex,
        });
        this.evaluationForms[this.tabDataIndex]
          .get('otherNotes')
          .patchValue('');
      },
      error: (error) => {
        console.log('Error saving Evaluation', error);
      },
    });
  }

  filterCriteriaMapping(arr: any[], roleId: number) {
    return arr.filter(
      (element) => element.documentCriteriaLookup.roleId === roleId,
    );
  }

  HideAdditionalCriteriasList() {
    this.criteriaListIsOpen = false;
  }

  filterCriteriasDropDownData(criteriasArray: any[]) {
    return criteriasArray.filter(
      (item) =>
        item.programDocuments.key !==
        this.dataGroupFiles[this.tabDataIndex].programDocumentLookup.id,
    );
  }

  getCriteriaNoteRoleId() {
    switch (this.requestStatus) {
      case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
        return UserRole.InternalAuditor;

      case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
        return this.showCriteriasInTabs() && this.activeTabIndex === 1
          ? UserRole.TechnicalAdvisor
          : UserRole.InternalAuditor;

      default:
        return null;
    }
  }

  showCriteriasInTabs() {
    return (
      this.applicationIsReviewedByTechnicalAdvisor &&
      this.dataGroupFiles[this.tabDataIndex].documentRoles.includes(
        UserRole.TechnicalAdvisor,
      )
    );
  }

  currentSectionHasPdfFiles() {
    return this.dataGroupFiles[this.tabDataIndex].files.length;
  }

  infoIconClickHandler() {
    this.showModal = true;
  }

  internalAuditorHasReviewedCurrentTab() {
    return this.dataGroupFiles[
      this.tabDataIndex
    ].documentFileHasBeenReviewes.find(
      (item: any) => item?.user?.role === 'المدقق الداخلي',
    )?.fileHasBeenReviewed;
  }

  technicalAdvisorHasReviewedCurrentTab() {
    return this.dataGroupFiles[
      this.tabDataIndex
    ].documentFileHasBeenReviewes.find(
      (item: any) => item?.user?.role === 'المستشار الفني',
    )?.fileHasBeenReviewed;
  }
}
