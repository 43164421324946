import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent {
  activeRoute: string = '';
  userRole: UserRole = UserRole.TrainingOrganization;

  userItems = [
    {
      svg: 'home-icon',
      text: 'home',
      route: '/home',
    },
    {
      svg: 'squares-icon',
      text: 'dashboard',
      route: '/dashboard',
    },
    {
      svg: 'document-icon',
      text: 'myApplications',
      route: '/dashboard/my-applications',
      roles: [UserRole.TrainingOrganization],
      condition: true,
    },
    {
      svg: 'review-icon',
      text: 'مراجعة إيصالات السداد',
      route: '/dashboard/receipts',
      roles: [UserRole.CommitteeSecretary],

      condition: true,
    },
    {
      svg: 'waiting-vote-icon',
      text: 'طلبات في انتظار التصويت',
      route: '/dashboard/my-applications',
      roles: [UserRole.CommitteeSecretary],
      condition: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private authService: AuthService, // Add AuthService dependency
  ) {
    this.activeRoute = this.router.url;
  }

  getFilteredItems(): any[] {
    const userRolesSet = new Set([this.authService.getUserRole()]);
    return this.userItems.filter((item) => {
      if (item.roles) {
        const rolesCondition = item.roles.some((role) =>
          userRolesSet.has(role),
        );
        const additionalCondition = item.condition === true;

        return rolesCondition && additionalCondition;
      }

      return true;
    });
  }

  selectSidebarItem(route: string) {
    this.activeRoute = route;
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0);
    this.router.navigate([route]);
  }
}
