<div class="sidebar-container">
  <div class="navigation-items-container">
    <a class="logo" routerLink="/">
      <img
        class="logo-svg"
        src="https://www.seec.gov.sa/media/ytvb5gnn/new-logo.svg"
        alt=""
      />
    </a>
  </div>
  <div class="sidebar-items__container">
    <a
      class="sidebar-item"
      *ngFor="let item of getFilteredItems()"
      routerLink="{{ item.route }}"
      (click)="selectSidebarItem(item.route)"
      [ngClass]="{ active: activeRoute === item.route }"
    >
      <app-svg-icon class="side-bar-icon" [name]="item.svg"></app-svg-icon>
      <span class="sidebar-item__text">{{ item.text | translate }}</span>
    </a>
  </div>
</div>
