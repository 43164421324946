// auth.service.ts

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  private tokenKey = 'auth_token';
  private isAuthenticated: boolean = false;
  private userIdKey = 'user_id'; // Add a key for storing UserId
  private email = 'email';
  private userName = 'userName';
  constructor(private http: HttpClient) {}

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.isAuthenticated = true;
    localStorage.setItem('isAuthenticated', this.isAuthenticated.toString());
    const decodedToken: any = JSON.parse(atob(token.split('.')[1]));

    const userId = decodedToken.UserId;
    if (userId) {
      localStorage.setItem(this.userIdKey, token);
    }
  }

  setUserEmail(email: string): void {
    localStorage.setItem(this.email, email);
  }

  setUserName(userName: string): void {
    localStorage.setItem(this.userName, userName);
  }

  setUserRole(role: number): void {
    localStorage.setItem('role', role.toString());
  }

  getToken(): string {
    return localStorage.getItem(this.tokenKey);
  }

  getUserEmail(): string {
    return localStorage.getItem(this.email);
  }

  getUserName(): string {
    return localStorage.getItem('userName');
  }

  getUserRole(): number {
    return JSON.parse(localStorage.getItem('role'));
  }

  removeToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem('isAuthenticated');
  }

  removeuserEmail(): void {
    localStorage.removeItem(this.email);
  }

  removeUserName(): void {
    localStorage.removeItem(this.userName);
  }

  isUserAuthenticated(): boolean {
    return JSON.parse(localStorage.getItem('isAuthenticated'));
  }

  signIn(email: string, password: string): Observable<any> {
    this.removeToken();
    return this.http
      .post(`${this.apiUrl}/api/Account/login`, { email, password })
      .pipe(
        map((response: any) => {
          if (response.errorCode === 0) {
            const token = response.data.token;
            const email = response.data.email;
            const userName = response.data.userName;
            const role = response.data.permissions;
            const userId = response.data.userId;

            if (token) {
              this.setToken(token);
              this.setUserEmail(email);
              this.setUserName(userName);
              this.setUserRole(role);
              this.setUserId(userId);
            }
          }
          return response;
        }),
      );
  }

  signUp(user: any): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/api/Account/Register`, user)
      .pipe(map((response: any) => response));
  }

  uploadFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.apiUrl}/api/upload`, formData);
  }

  hasRole(role: number): boolean {
    const userRole = this.getUserRole();

    return userRole === role;
  }

  sendOtp(email: string): Observable<any> {
    this.removeToken();
    const url = `${this.apiUrl}/api/Account/ReSendOTP`;

    return this.http
      .post(url, { email })
      .pipe(map((response: any) => response));
  }

  verifyOtp(email: string, otp: string): Observable<any> {
    const url = `${this.apiUrl}/api/Account/CheckOtpCode`;

    return this.http
      .post(url, { email, otp })
      .pipe(map((response: any) => response));
  }

  changePassword(
    confirmNewPassword: string,
    newPassword: string,
  ): Observable<any> {
    const url = `${this.apiUrl}/api/Account/ResetPassword`;
    const token = localStorage.getItem('otp_token');

    return this.http.post(url, { confirmNewPassword, newPassword, token });
  }

  setUserId(userId: string): void {
    localStorage.setItem('userId', userId);
  }

  getUserId(): number {
    return Number(localStorage.getItem('userId'));
  }

  logOut(): Observable<any> {
    return this.http.get(`${this.apiUrl}/api/Account/Logout`);
  }
}
