<div class="container">
  <div *ngIf="!readOnly; else resultsBlock">
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel [header]="'evaluationStages' | translate">
        <app-page-title
          [title]="'evaluationStages'"
          [customClass]="['application-details']"
        ></app-page-title>
        <div class="evaluation-item">
          <div class="stages">
            <div class="stages-title">
              {{ 'firstStage' | translate }}
            </div>
          </div>
          <div class="flex">
            <app-page-title
              [title]="'انطباق جميع المعايير الأولية (المدقق الداخلي)'"
              [customClass]="['small-font', 'green-color']"
            ></app-page-title>
            <img src="assets/images/checkMark.svg" />
          </div>
          <p class="text">{{ internalAuditorNote || 'لا يوجد ملاحظات' }}</p>
        </div>
        <div class="divider"></div>

        <div class="evaluation-item">
          <div class="stages">
            <div class="stages-title">
              {{ 'secondStage' | translate }}
            </div>
          </div>
          <div class="flex">
            <app-page-title
              title="تقييم الخبير للبرنامج التدريبي"
              [customClass]="['small-font', 'green-color']"
            ></app-page-title>
            <img src="assets/images/checkMark.svg" />
          </div>
          <p class="text">{{ technicalAdvisorNote || 'لا يوجد ملاحظات' }}</p>

          <div *ngIf="applicationNeedsFieldVisit" class="flex margin">
            <app-page-title
              [title]="'needVisit'"
              [customClass]="['small-font', 'green-color']"
            ></app-page-title>
            <img src="assets/images/checkMark.svg" />
          </div>

          <p *ngIf="applicationNeedsFieldVisit" class="text">نموذج مطابق</p>
        </div>
        <div class="buttons">
          <button class="submit" (click)="activeTabIndex = 1">
            {{ 'nextPage' | translate }}
          </button>
        </div>
      </p-tabPanel>

      <p-tabPanel header="تصويت لجنة الاعتماد">
        <div class="flex">
          <app-page-title
            [title]="'accreditedVotes'"
            [customClass]="['application-details']"
          ></app-page-title>

          <div class="flex center" style="margin-bottom: 30px">
            <span class="vote-count">
              {{ 'voteCount' | translate }}
            </span>
            <div class="votecount">
              <span class="grey"> {{ getAcceptVotes() }} </span>
              /{{ getTotalVotes() }}
            </div>
          </div>
        </div>

        <div *ngIf="editMode; else elseBlock">
          <div class="flex center">
            <div>
              <app-page-title
                [title]="currentMemberVoteData?.member?.userName"
                [customClass]="['medium-font', 'green-color']"
              ></app-page-title>
              <p class="text">
                {{ currentMemberVoteData?.member?.permissionStr }}
              </p>
            </div>
            <div class="flex" style="gap: 5px">
              <button
                [ngClass]="
                  currentVote === 1 ? 'filled-submit' : 'outlined-submit'
                "
                (click)="handleVote('approve')"
              >
                قبول
              </button>
              <button
                [ngClass]="
                  currentVote === 2 ? 'filled-reject' : 'outlined-reject'
                "
                (click)="handleVote('reject')"
              >
                رفض
              </button>
            </div>
          </div>
          <div class="input-container">
            <textarea
              id="userNotes"
              [(ngModel)]="userNotes"
              class="userNotes"
            ></textarea>
          </div>
        </div>

        <ng-template #elseBlock>
          <div class="flex center">
            <div class="about">
              <div class="edit">
                <app-page-title
                  class="edit"
                  [title]="currentMemberVoteData?.member?.userName"
                  [customClass]="['small-font', 'green-color']"
                ></app-page-title>
              </div>
              <p class="text">
                {{ currentMemberVoteData?.member?.permissionStr }}
              </p>
              <p class="vote-notes">
                {{ currentMemberVoteData?.note }}
              </p>
            </div>
            <div
              [class]="
                getDecisionClass(
                  currentMemberVoteData?.commissionerVoteStatusId
                )
              "
            >
              {{
                getVoteString(currentMemberVoteData?.commissionerVoteStatusId)
              }}
            </div>
          </div>
          <div class="divider"></div>
        </ng-template>

        <div *ngFor="let vote of otherMembersVotes; let last = last">
          <div class="flex center">
            <div class="about">
              <app-page-title
                [title]="vote?.member?.userName"
                [customClass]="['small-font', 'green-color']"
              ></app-page-title>
              <p class="text">
                {{ vote?.member?.permissionStr }}
              </p>
              <p class="vote-notes">
                {{ vote.note }}
              </p>
            </div>
            <div
              class="submit"
              [class]="getDecisionClass(vote?.commissionerVoteStatusId)"
            >
              {{ getVoteString(vote?.commissionerVoteStatusId) }}
            </div>
          </div>
          <div *ngIf="!last" class="divider"></div>
        </div>
        <div>
          <div class="edit" style="margin-top: 50px">
            <button
              class="submit"
              (click)="editMode ? handleSubmit() : (editMode = true)"
              [disabled]="
                (editMode && currentVote === voteIsNotDecided) ||
                isSubmitButtonDisabled
              "
            >
              {{ editMode ? 'تأكيد التصويت' : 'تعديل التصويت' }}
            </button>
            <button (click)="activeTabIndex = 0" class="submit green-outline">
              السابقة
            </button>
          </div>
        </div>

        <app-modal
          [modalContent]="'submission'"
          [showModal]="submittedVote"
          (closeModal)="closeModel()"
          [customClass]="'success-modal'"
        >
          <div class="modal-body">
            <app-svg-icon
              class="success-icon"
              name="success-icon"
            ></app-svg-icon>
            <span class="modal-text">{{
              isCommitteeHead
                ? 'تم إرسال الطلب إلى أمين اللجنة'
                : 'تم اعتماد التصويت'
            }}</span>
          </div>
        </app-modal>
      </p-tabPanel>
    </p-tabView>
  </div>

  <ng-template #resultsBlock>
    <div class="results-container">
      <app-page-title
        [title]="'accreditedVotes'"
        [customClass]="['application-details']"
      ></app-page-title>

      <div *ngFor="let vote of otherMembersVotes; let last = last">
        <div class="flex center">
          <div class="about">
            <app-page-title
              [title]="vote?.member?.userName"
              [customClass]="['small-font', 'green-color']"
            ></app-page-title>
            <p class="text">
              {{ vote?.member?.permissionStr }}
            </p>
            <p class="vote-notes">
              {{ vote.note }}
            </p>
          </div>
          <div
            class="submit"
            [class]="getDecisionClass(vote?.commissionerVoteStatusId)"
          >
            {{ getVoteString(vote?.commissionerVoteStatusId) }}
          </div>
        </div>
        <div *ngIf="!last" class="divider"></div>
      </div>
      <div>
        <div class="edit" style="margin-top: 50px">
          <button
            *ngIf="votingResultsButtonText()"
            class="submit"
            (click)="votingResultsButtonHandler()"
          >
            {{ votingResultsButtonText() }}
          </button>
        </div>
      </div>
    </div>

    <app-modal
      [modalContent]="'submission'"
      [showModal]="showSendForForPaymentDialog"
      (closeModal)="closeModel()"
      [customClass]="'success-modal'"
      [isCloseVisible]="!(isLoading$ | async)"
    >
      <div
        class="modal-body modal-only-text"
        *ngIf="!sentForPaymentSuccessfully; else successPopup"
      >
        <span class="modal-text">{{
          'هل ترغب في تأكيد إرسال الطلب إلى الجهة التدريبية للدفع؟'
        }}</span>
        <div class="modal-buttons-container">
          <button
            [disabled]="isSubmitButtonDisabled"
            (click)="sendApplicationForPayment()"
            class="submit"
          >
            نعم, تأكيد
          </button>
          <button
            (click)="showSendForForPaymentDialog = false"
            class="submit green-outline"
          >
            إلغاء
          </button>
        </div>
      </div>
      <ng-template #successPopup>
        <loading-spinner>جاري ارسال الطلب...</loading-spinner>
        <div *ngIf="!(isLoading$ | async)" class="modal-body">
          <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
          <span class="modal-text">{{
            'تم إرسال الطلب إلى الجهة التدريبية بنجاح'
          }}</span>
        </div>
      </ng-template>
    </app-modal>
  </ng-template>
</div>
