import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { Votes } from 'src/app/types/votes';
import { AuditService } from '@app/dashboard/services/audit.service';
import { Status } from '@app/dashboard/types/status';
import { VotingService } from './voting.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'committee-voting',
  templateUrl: './committee-voting.component.html',
  styleUrls: ['./committee-voting.component.scss'],
})
export class CommitteeVotingComponent implements OnInit {
  @Input() requestData: any;
  @Input() readOnly: boolean;
  @Input() requestId: number;

  userNotes: string = '';
  editMode: boolean = false;
  trainingOrganizationId: number;
  activeTabIndex: number = 0;
  internalAuditorNote: string = '';
  technicalAdvisorNote: string = '';
  applicationNeedsFieldVisit: boolean;
  votingData: any;
  currentMemeberId: number;
  currentMemberVoteData: any;
  otherMembersVotes: any;
  currentMemberHasVoted: any = false;
  currentVote: number = Votes.NotDecided;
  submittedVote: boolean = false;
  showSendForForPaymentDialog: boolean = false;
  sentForPaymentSuccessfully: boolean = false;
  isCommitteeHead = this.authService.hasRole(UserRole.CommitteeHead);
  voteIsNotDecided = Votes.NotDecided;
  isSubmitButtonDisabled: boolean = false;

  levels = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
    {
      name: 'committeVotingForm',
      path: '/committee-voting',
    },
  ];

  constructor(
    private votingService: VotingService,
    private authService: AuthService,
    private auditService: AuditService,
    private router: Router,
    private loadingService: LoadingService,
  ) {}

  isLoading$: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.currentMemeberId = this.authService.getUserId();
    this.votingService
      .getVotesData(this.requestData?.trainingOrganization.id)
      .subscribe((response) => {
        this.trainingOrganizationId =
          this.requestData.trainingOrganization.trainingOrganizationId;
        this.votingData = response.data;
        this.currentMemberVoteData = this.votingData.commissionerVoteDtos.find(
          (vote: any) => vote?.member?.id === this.currentMemeberId,
        );
        this.otherMembersVotes = this.votingData.commissionerVoteDtos.filter(
          (vote: any) => vote?.member?.id !== this.currentMemeberId,
        );
        this.editMode = !this.currentMemberVoteData?.isVoted;
        if (this.currentMemberVoteData) {
          this.currentMemberHasVoted = this.currentMemberVoteData?.isVoted;
          this.currentVote =
            this.currentMemberVoteData?.commissionerVoteStatusId;
          this.userNotes = this.currentMemberVoteData.note;
        }
      });
    if (this.requestData?.trainingProgramDocumentNote.length) {
      this.internalAuditorNote =
        this.requestData?.trainingProgramDocumentNote?.find(
          (element: any) => element?.user?.role === 'المدقق الداخلي',
        )?.note;

      this.technicalAdvisorNote =
        this.requestData?.trainingProgramDocumentNote?.find(
          (element: any) => element?.user?.role === 'المستشار الفني',
        )?.note;
    }
    this.applicationNeedsFieldVisit =
      this.requestData.trainingOrganization.hasFieldVisit;
  }

  handleSubmit(): void {
    this.isSubmitButtonDisabled = true;
    this.votingService
      .sendVoteData(
        this.trainingOrganizationId,
        this.currentVote,
        this.userNotes,
        this.currentMemberVoteData?.isVoted
          ? this.currentMemberVoteData?.id
          : undefined,
      )
      .subscribe((response) => {
        if (response.errorCode === 0) {
          this.submittedVote = true;
          this.currentMemberVoteData.commissionerVoteStatusId =
            this.currentVote;
          this.currentMemberVoteData.note = this.userNotes;
          this.currentMemberVoteData.isVoted = true;

          if (this.authService.hasRole(UserRole.CommitteeHead))
            this.auditService
              .updateApplicationStatus({
                TrainingOrganizationStatusEnum:
                  Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary,
                TrainingOrganizationId:
                  this.requestData.trainingOrganization?.trainingOrganizationId,
              })
              .subscribe({
                next: () => {
                  this.submittedVote = true;
                },
                error: (error) => {
                  console.log('Error sending the data', error);
                },
              });
        }
      });

    if (this.authService.hasRole(UserRole.CommitteeHead))
      this.auditService
        .updateApplicationStatus({
          TrainingOrganizationStatusEnum:
            Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary,
          TrainingOrganizationId:
            this.requestData.trainingOrganization?.trainingOrganizationId,
        })
        .subscribe({
          next: () => {
            this.submittedVote = true;
          },
          error: (error) => {
            console.log('Error sending the data', error);
          },
        });

    this.editMode = false;
  }

  handleVote(status: string) {
    this.currentVote = status === 'approve' ? Votes.Accept : Votes.Reject;
  }

  getTotalVotes() {
    // if (this.otherMembersVotes)
    //   return this.otherMembersVotes.reduce(
    //     (votesNumber: number, currentVote: any) => {
    //       if (currentVote.isVoted) return votesNumber + 1;
    //       else return votesNumber;
    //     },
    //     this.currentVote === Votes.NotDecided ? 0 : 1,
    //   );
    return this.authService.hasRole(UserRole.CommitteeMember) ? 4 : 5;
  }

  getAcceptVotes() {
    if (this.otherMembersVotes)
      return this.otherMembersVotes.reduce(
        (votesNumber: number, currentVote: any) => {
          if (currentVote.commissionerVoteStatusId === Votes.Accept)
            return votesNumber + 1;
          else return votesNumber;
        },
        this.currentVote === Votes.Accept ? 1 : 0,
      );
  }

  getDecisionClass(decision: number) {
    switch (decision) {
      case 1:
        return 'agree';

      case 2:
        return 'disapproval';

      default:
        return 'neutral';
    }
  }

  getVoteString(decision: number) {
    switch (decision) {
      case 1:
        return 'موافق';
      case 2:
        return 'غير موافق';

      default:
        return 'لم يصوت بعد';
    }
  }

  votingResultsButtonText() {
    switch (
      this.requestData.workFlowTrainingOrganization
        .trainingOrganizationStatusLookupId
    ) {
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        return 'ارسال الطلب للجهة للدفع';

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
        return 'إصدار شهادة الاعتماد';

      default:
        return '';
    }
  }

  votingResultsButtonHandler() {
    switch (
      this.requestData.workFlowTrainingOrganization
        .trainingOrganizationStatusLookupId
    ) {
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        this.showSendForForPaymentDialog = true;
        break;

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
        this.router.navigate(['/dashboard/certificate/', this.requestId]);
        break;

      default:
        break;
    }
  }

  sendApplicationForPayment() {
    this.isSubmitButtonDisabled = true;
    this.sentForPaymentSuccessfully = true;
    this.loadingService.startLoading();
    this.auditService
      .updateApplicationStatus({
        TrainingOrganizationStatusEnum:
          Status.ApprovedByCommitteeSecretaryWaitingPayment,
        TrainingOrganizationId:
          this.requestData.trainingOrganization?.trainingOrganizationId,
      })
      .subscribe({
        next: () => {
          this.loadingService.stopLoading();
        },
        error: (error) => {
          console.log('Error sending the data', error);
        },
      });
  }

  closeModel() {
    this.showSendForForPaymentDialog = false;
    this.submittedVote = false;
    this.isSubmitButtonDisabled = false;
    if (this.sentForPaymentSuccessfully || this.isCommitteeHead)
      this.router.navigate(['/dashboard']);
  }
}
