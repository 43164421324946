<div class="application-details__container">
  <div class="application-details__header">
    <app-page-title [title]="'applicationNumber'" [customClass]="'application-number__title'"><span>{{
        requestData?.trainingOrganization?.reportId
        }}</span></app-page-title>
    <div class="breadcrumbs-container">
      <app-breadcrumbs [levels]="levels"/>
    </div>
  </div>
  <loading-spinner/>
  <app-application-details-user-role [requestData]="requestData"
    *ngIf="requestData?.trainingProgramDocuments && isInternalAuditor()" [title]="'معلومات الجهة'"
    [rowIndex]="rowData"/>
  <order-details *ngIf="showRequestDetails" [requestData]=" requestData"/>
  <expert-application-view [id]="id" *ngIf="requestData?.trainingProgramDocuments && isTechnicalAdvisor()"
    [requestData]="requestData"/>
</div>