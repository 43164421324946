<loading-spinner></loading-spinner>
<div class="form-tabs__containr">
  <form
    *ngIf="!(isLoading | async)"
    [formGroup]="accreditationForm"
    class="custom-form"
  >
    <div class="tab-content" tabContent>
      <p-tabView [(activeIndex)]="activeTabIndex">
        <p-tabPanel header="بيانات الجهة التدريبية">
          <span class="user-guide" *ngIf="isTrainingOrganization()"
            >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
              (click)="userGuideManual()"
            >
              الدليل الإرشادي</a
            >)</span
          >
          <div class="tab-content__container">
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="agencyName"
                  [label]="'trainingBodyName' | translate"
                  [control]="accreditationForm?.get('agencyName')"
                  [isRequired]="true"
                  [errorMessage]="
                    accreditationForm?.get('agencyName').errors?.pattern
                      ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                      : 'هذا الحقل المطلوب'
                  "
                ></text-field>
              </div>

              <div class="form-col">
                <dropdown
                  [options]="accreditationApplicationTypes"
                  id="applicationType"
                  [isRequired]="true"
                  [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                  [label]="'نوع الطلب' | translate"
                  [control]="accreditationForm?.get('applicationType')"
                >
                </dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="licenseNumber"
                  [label]="
                    'رقم رخصة المؤسسة العامة للتدريب التقني والمهني' | translate
                  "
                  [control]="accreditationForm?.get('licenseNumber')"
                  [isRequired]="true"
                />
              </div>
              <div class="form-col">
                <date-picker
                  id="licenseDate"
                  [label]="
                    'تاريخ رخصة المؤسسة العامة للتدريب التقني والمهني'
                      | translate
                  "
                  [control]="accreditationForm?.get('licenseDate')"
                  [isRequired]="true"
                  [customErrorMessage]="
                    licenseDateError ? 'هذا الحقل مطلوب' : ''
                  "
                  [maxDate]="licenseMaxDate"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <date-picker
                  id="licenseExpiryDate"
                  [label]="
                    'تاريخ انتهاء رخصة المؤسسة العامة للتدريب التقني والمهني'
                      | translate
                  "
                  [control]="accreditationForm?.get('licenseExpiryDate')"
                  [isRequired]="true"
                  [minDate]="licenseExpiryMinDate"
                  [customErrorMessage]="
                    licenseExpiryDateError ? 'هذا الحقل مطلوب' : ''
                  "
                />
              </div>
              <div class="form-col">
                <dropdown
                  [options]="cities"
                  id="city"
                  [label]="'المدينة' | translate"
                  [control]="accreditationForm?.get('city')"
                  [isRequired]="true"
                  [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="address"
                  [label]="'العنوان' | translate"
                  [control]="accreditationForm?.get('address')"
                  [isRequired]="true"
                  [errorMessage]="
                    accreditationForm?.get('address').errors?.pattern
                      ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                      : 'هذا الحقل المطلوب'
                  "
                ></text-field>
              </div>
              <div class="form-col">
                <text-field
                  id="email"
                  [label]="'البريد الالكتروني' | translate"
                  [control]="accreditationForm?.get('email')"
                  [isRequired]="true"
                  placeholder="example@example.com"
                  errorMessage="الرجاء إدخال بريد إلكتروني صحيح"
                ></text-field>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="mobileNumber"
                  keyFilter="int"
                  [label]="'رقم جوال' | translate"
                  [control]="accreditationForm?.get('mobileNumber')"
                  [isRequired]="true"
                ></text-field>
              </div>
              <div class="form-col">
                <text-field
                  id="cordinatorName"
                  [label]="'اسم المنسق' | translate"
                  [control]="accreditationForm?.get('cordinatorName')"
                  [isRequired]="true"
                ></text-field>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="بيانات البرنامج التدريبي">
          <span class="user-guide" *ngIf="isTrainingOrganization()"
            >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
              (click)="userGuideManual()"
            >
              الدليل الإرشادي</a
            >)</span
          >
          <div class="tab-content__container">
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="trainingProgramName"
                  [label]="'اسم البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('trainingProgramName')"
                  [isRequired]="true"
                  [errorMessage]="
                    accreditationForm?.get('trainingProgramName').errors
                      ?.pattern
                      ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                      : 'هذا الحقل المطلوب'
                  "
                ></text-field>
              </div>
              <div class="form-col">
                <text-field
                  id="trainingProgramEnglishName"
                  [label]="
                    'اسم البرنامج التدريبي باللغة الإنجليزية' | translate
                  "
                  [control]="
                    accreditationForm?.get('trainingProgramEnglishName')
                  "
                  [isRequired]="true"
                  [errorMessage]="
                    accreditationForm?.get('trainingProgramEnglishName').errors
                      ?.pattern
                      ? 'ادخل حروفاً بالعربية و الإنجليزية فقط'
                      : 'هذا الحقل المطلوب'
                  "
                ></text-field>
              </div>
            </div>

            <div class="form-row">
              <div class="form-col">
                <dropdown
                  [options]="trainingProgramType"
                  id="trainingProgramType"
                  [label]="'نوع البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('trainingProgramType')"
                  [isRequired]="true"
                  [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                >
                </dropdown>
              </div>
              <div class="form-col">
                <dropdown
                  [options]="trainingProgramLevels"
                  id="trainingProgramLevel"
                  [label]="'مستوى البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('trainingProgramLevel')"
                  [isRequired]="true"
                  [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                >
                </dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="trainingProgramDays"
                  [label]="'مدة البرنامج التدريبي (بالأيام )' | translate"
                  [control]="accreditationForm?.get('trainingProgramDays')"
                  [keyFilter]="numberOnlyKeyFilter"
                  [isRequired]="true"
                ></text-field>
              </div>
              <div class="form-col">
                <text-field
                  id="trainingProgramHours"
                  [label]="'عدد الساعات التدريبية الإجمالية' | translate"
                  [control]="accreditationForm?.get('trainingProgramHours')"
                  [keyFilter]="numberOnlyKeyFilter"
                  [isRequired]="true"
                ></text-field>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="trainingProgramPurpose"
                  [label]="'الهدف العام من البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('trainingProgramPurpose')"
                  [isRequired]="true"
                ></text-field>
              </div>
              <div class="form-col">
                <dropdown
                  [options]="trainingProgramLanguages"
                  id="trainingProgramLanguage"
                  [label]="'لغة البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('trainingProgramLanguage')"
                  [isRequired]="true"
                  [placeholder]="'الرجاء الاختيار من القائمة المنسدلة'"
                >
                </dropdown>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col">
                <text-field
                  id="targetedCategory"
                  [label]="'الفئة المستهدفة' | translate"
                  [control]="accreditationForm?.get('targetedCategory')"
                  [isRequired]="true"
                ></text-field>
              </div>
            </div>
            <div class="form-row">
              <div class="form-col text-area">
                <text-area
                  id="programDescription"
                  rows="5"
                  [label]="'وصف البرنامج التدريبي' | translate"
                  [control]="accreditationForm?.get('programDescription')"
                  [isRequired]="true"
                ></text-area>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="مستندات البرنامج">
          <span class="user-guide" *ngIf="isTrainingOrganization()"
            >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
              (click)="userGuideManual()"
            >
              الدليل الإرشادي</a
            >)</span
          >
          <div class="tab-content__container">
            <div class="file-uploads-container">
              <file-upload
                modifyRemovedFiles="{{ true }}"
                [readonly]="isReadOnly"
                *ngFor="
                  let documentObject of requiredDocuments;
                  let idx = index
                "
                [id]="idx"
                [title]="documentObject.programDocuments.value"
                [subtitles]="documentObject.programDocumentRequirements"
                [popupSubtitle]="
                  'سيتم تقييم هذا الملف بناء على المعايير التالية، يرجى الاطلاع عليها للتأكد من مطابقتها في الملف المرفق.'
                "
                [files]="
                  accreditationForm?.get(
                    'fileField_' + documentObject.programDocuments.key
                  )?.value
                "
                [descriptionLines]="documentObject.programDocumentDescriptions"
                [control]="
                  accreditationForm?.get(
                    'fileField_' + documentObject.programDocuments.key
                  )
                "
              ></file-upload>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="التعهد العام">
          <span class="user-guide" *ngIf="isTrainingOrganization()"
            >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
              (click)="userGuideManual()"
            >
              الدليل الإرشادي</a
            >)</span
          >
          <div class="tab-content__container">
            <div class="oath-container">
              <span class="oath-intro"
                >السادة المركز السعودي لكفاءة الطاقة حفظهم االله
              </span>
              <p class="oath-intro">السلام عليكم ورحمة االله وبركاته</p>
              <p class="oath-para">
                نتعهد نحن
                {{
                  accreditationForm?.value?.agencyName ||
                    requestData?.trainingOrganization?.organizationName
                }}، بالالتزام بلائحة اعتماد البرامج التدريبية في مجال كفاءة
                الطاقة والدليل الإرشادي للائحة وكافة التعاميم والتعليمات الخاصة
                التي يصدرها المركز السعودي لكفاءة الطاقة بهذا الشأن.
              </p>
              <p class="oath-para">وتقبلوا وافر التحية و التقدير.</p>
              <div class="form-col">
                <text-field
                  id="applicantName"
                  [label]="'اسم مقدم الطلب' | translate"
                  [control]="accreditationForm?.get('applicantName')"
                  [isRequired]="true"
                ></text-field>
              </div>
              <div class="electronic-signature__container">
                <label>التوقيع الإلكتروني </label>
                <div class="loading-spinner" *ngIf="showLoadingSpinner()">
                  <div class="spinner"></div>
                </div>

                <div
                  class="electronic-signature"
                  *ngIf="showElectronicSignature()"
                >
                  <div (click)="openFileInput()" class="signature-container">
                    <img
                      [src]="signatureImageUrl"
                      alt="Electronic Signature"
                      class="signature-img"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      #signatureInput
                      style="display: none"
                      [disabled]="isReadOnly"
                      (change)="onImageSelected($event)"
                    />
                    <small *ngIf="!isAcceptedImgFormat"
                      >يرجى تحميل ملف من نوع صورة فقط.</small
                    >
                    <button
                      type="button"
                      *ngIf="
                        accreditationForm?.get('signature').value && !isReadOnly
                      "
                      class="delete-btn"
                      (click)="deleteSignature($event)"
                    >
                      حذف
                    </button>
                  </div>
                  <div
                    [ngClass]="{
                      'add-margin': !isAcceptedImgFormat,
                      'checkbox-container': true
                    }"
                  >
                    <check-box
                      id="pledgeCheckbox"
                      [control]="accreditationForm?.get('pledge')"
                    ></check-box>
                    <span>
                      أؤكد بأنني قرأت وفهمت ووافقت على جميع محتويات
                      <a (click)="userGuideManual()"> الدليل الارشادي </a></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel *ngIf="certificateData" header="شهادة الاعتماد">
          <span class="user-guide" *ngIf="isTrainingOrganization()"
            >الرجاء الاطلاع على الدليل الإرشادي قبل تعبئة الحقول (<a
              (click)="userGuideManual()"
            >
              الدليل الإرشادي</a
            >)</span
          >
          <certificate [certificateData]="certificateData"></certificate>
        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="buttons-container">
      <app-navigation-buttons
        [tabs]="tabs"
        [activeTabIndex]="activeTabIndex"
        (nextTab)="navigateToNextTab()"
        (previousTab)="navigateToPreviousTab()"
      >
        [tabs]="tabs" ></app-navigation-buttons
      >
      <app-custom-button
        *ngIf="activeTabIndex === 3 && !isReadOnly"
        [buttonText]="isReadOnly ? 'إعادة إرسال الطلب' : 'تقديم الطلب'"
        [buttonStyle]="'greenButton'"
        [disabled]="
          !accreditationForm?.valid ||
          licenseDateError ||
          licenseExpiryDateError
        "
        (click)="onSubmit({ asDraft: false })"
      ></app-custom-button>
      <div class="success-modal__container">
        <app-modal
          [modalContent]="'submission'"
          [showModal]="showModal"
          (closeModal)="closeModel()"
          [customClass]="'success-modal'"
          [isCloseVisible]="submittedSuccessfully"
        >
          <div *ngIf="!submittedSuccessfully" class="submit-loading-spinner">
            <div class="submit-spinner"></div>
            {{ savingAsDraft ? 'جاري حفظ الطلب...' : 'جاري إرسال الطلب...' }}
          </div>
          <div *ngIf="submittedSuccessfully" class="modal-body">
            <app-svg-icon
              class="success-icon"
              name="success-icon"
            ></app-svg-icon>
            <span class="modal-text">{{
              savingAsDraft ? 'تم حفظ الطلب كمسودة' : 'تم إرسال الطلب بنجاح'
            }}</span>
          </div>
        </app-modal>
      </div>
      <app-custom-button
        *ngIf="!isReadOnly && !isEditableApplication"
        [buttonText]="
          getCurrentRoute() === '/update-application' ? 'تعديل' : 'حفظ كمسودة'
        "
        [buttonStyle]="'transperantGreenButton'"
        (click)="isReadOnly ? readOnly() : onSubmit({ asDraft: true })"
        [disabled]="
          !accreditationForm?.get('agencyName').valid ||
          accreditationForm?.get('agencyName').errors?.pattern ||
          accreditationForm?.get('address').errors?.pattern ||
          accreditationForm?.get('trainingProgramName').errors?.pattern ||
          accreditationForm?.get('trainingProgramEnglishName').errors
            ?.pattern ||
          accreditationForm?.get('email')?.errors?.pattern
        "
      ></app-custom-button>
    </div>
  </form>
</div>
