import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TrainingProgramsComponent } from './components/training-programs/training-programs.component';
import { MyApplicationsComponent } from './components/my-applications/my-applications.component';
import { AccreditationApplicationComponent } from './components/accreditation-application/accreditation-application.component';
import { ApplicationDetailsComponent } from './components/application-details/application-details.component';
import { DashboardRootcomponent } from './dashboard-root.component';
import { AuditorTableComponent } from './components/auditor-table/auditor-table.component';
import { CommitteeVotingComponent } from './components/committee-voting/committee-voting.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { InformationPanelComponent } from './components/information-panel/information-panel.component';
import { PaymentDetailsComponent } from './components/payment-details/payment-details.component';
import { AuthGuard } from '../guards/auth.guard';
import { ReceiptsTableComponent } from './components/receipts-table/receipts-table.component';
import { CertificatePageComponent } from './components/certificate-page/certificate-page.component';
import { VotingPageComponent } from './components/voting-page/voting-page.component';
import { PaymentReceiptDetailsComponent } from './components/payment-receipt-details/payment-receipt-details.component';

const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardRootcomponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard], // Use the AuthGuard for this route
      },
      {
        path: 'application-details',
        pathMatch: 'full',
        redirectTo: '/dashboard', // Redirect to dashboard
      },
      {
        path: 'programs',
        component: TrainingProgramsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'my-applications',
        component: MyApplicationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'receipts',
        component: ReceiptsTableComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'application-details/:id',
        component: ApplicationDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'voting/:id',
        component: VotingPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'accreditation-form',
        component: AccreditationApplicationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'accreditation-form/:id',
        component: AccreditationApplicationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'internal-auditor',
        component: AuditorTableComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'committee-voting',
        component: CommitteeVotingComponent,
        // canActivate: [AuthGuard],
      },
      {
        path: 'order-details',
        component: OrderDetailsComponent,
        // canActivate: [AuthGuard],
      },
      { path: 'information-panel', component: InformationPanelComponent },
      {
        path: 'certificate/:id',
        component: CertificatePageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-details/:id',
        component: PaymentDetailsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'payment-receipt-details/:id',
        component: PaymentReceiptDetailsComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
