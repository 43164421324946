<div class="container">
  <div *ngIf="noteData.note" class="general-note-title">
    {{ 'ملاحظات عامة' + ' ' + '(' + noteData.user.role + ')' }}
    <img
      (click)="clickHandler()"
      class="edit-general-notes"
      *ngIf="showEditButton"
      [src]="editNotesSrc"
    />
  </div>
  <div *ngIf="!editMode" class="general-note-text">
    {{ noteText || 'لا يوجد ملاحظات' }}
  </div>
  <div class="edit-container" *ngIf="editMode">
    <textarea rows="10" [(ngModel)]="noteText"></textarea>
    <button class="save" (click)="saveHandler()">حفظ</button>
    <button class="cancel" (click)="editMode = false; noteText = noteData.note">
      إلغاء
    </button>
  </div>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showModal"
      (closeModal)="showModal = false"
      [customClass]="'success-modal'"
      [isCloseVisible]="!(isLoading | async)"
    >
      <loading-spinner>جاري تحديث الملاحظة...</loading-spinner>
      <div class="modal-body" *ngIf="!(isLoading | async)">
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">تم تعديل الملاحظة بنجاح</span>
      </div>
    </app-modal>
  </div>
</div>
