<div class="footer-subscribtion">
  <h2 class="footer-links__title">{{ 'newsletter' | translate }}</h2>
  <div class="email-container">
    <input
      class="email"
      type="text"
      placeholder="{{ 'enterYourEmail' | translate }}"
      required="required"
      aria-label="Email"
    />
    <form class="subscribe-wrapper">
      <button class="subscribe" type="button">
        {{ 'subscribe' | translate }}
      </button>
    </form>
  </div>
</div>
