import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from '@app/dashboard/services/modal.service';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { filesAssets } from '@assets/files';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  items: MenuItem[] | undefined;
  isMenuOpen = false;
  showSelect = false;
  searchQuery: string = '';
  isModalOpen = false; // Add this variable
  pdfURL = filesAssets.userGuide;

  constructor(
    private authService: AuthService,
    private router: Router,
    public modalService: ModalService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
  ) {
    this.modalService.showModal$.subscribe((isOpen) => {
      if (isOpen) {
        this.isModalOpen = isOpen;

        this.renderer.addClass(document.body, 'modal-open'); // Add the class
      } else {
        this.isModalOpen = isOpen;

        this.renderer.removeClass(document.body, 'modal-open'); // Remove the class
      }
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: 'لوحة المعلومات',
        icon: 'pi pi-table',
        command: () => {
          this.redirectToDashboard(event);
        },
      },
      {
        label: 'تسجيل الخروج',
        icon: 'pi pi-sign-out',
        command: () => {
          this.handleLogout(event);
        },
      },
    ];
  }

  handleLogout(event: Event) {
    this.authGuard.setRedirectionUrl('');
    event.stopPropagation();
    this.authService.logOut().subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.router.navigate(['/home']);
          localStorage.removeItem('auth_token');
          localStorage.removeItem('email');
          localStorage.removeItem('role');
          localStorage.removeItem('isAuthenticated');
          localStorage.removeItem('userName');
        }
      },
    });
  }

  redirectToDashboard(event: Event) {
    event.stopPropagation();

    this.router.navigate(['/dashboard']);
  }

  onUserGuideClick() {
    this.showSelect = false;
    this.modalService.openModal();
  }

  onSearch() {
    console.log('search');
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  handleDivClick(event: Event) {
    event.stopPropagation();
    this.onUserGuideClick();
  }
}
