import { Component } from '@angular/core';
import { Tab } from '@app/dashboard/types/tabs.type';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';
import { ScrollToTopService } from '@app/dashboard/services/scroll-to-top.service';

@Component({
  selector: 'app-accreditation-application',
  templateUrl: './accreditation-application.component.html',
  styleUrls: ['./accreditation-application.component.scss'],
})
export class AccreditationApplicationComponent {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private scrollService: ScrollToTopService,
  ) {}
  organizationId: number;
  reportId: number;
  activeTabIndex: number = 0;
  page: object = {};
  levels: any;

  tabs: Tab[] = [
    { label: 'trainingBodyData', content: 'TAB 1rrrr' },
    { label: 'trainingProgramData', content: 'TAB 2rrrr' },
    { label: 'programDocuments', content: 'TAB 3rrrr' },
    { label: 'pledge', content: 'TAB 4 rrrrx' },
  ];

  initialFormData: { [key: string]: string } = {
    trainingBodyName: '',
    trainingBodyNumber: '',
    // Add more properties as needed
  };

  ngOnInit() {
    this.scrollService.scrollToTop();

    if (!this.authService.hasRole(UserRole.TrainingOrganization))
      this.router.navigateByUrl('/dashboard');

    this.organizationId = this.route.snapshot?.params['id'];

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectFrom = urlParams.get('page');

    if (redirectFrom) {
      this.page = {
        name: 'الرئيسية',
        path: '/home',
      };
    } else {
      this.page = {
        name: 'لوحة المعلومات',
        path: '/dashboard',
      };
    }

    this.levels = [this.page];
    if (this.organizationId)
      this.levels.push({
        name: 'تفاصيل الطلب',
        path: '',
      });
    else
      this.levels.push({
        name: 'تقديم طلب اعتماد',
        path: '',
      });
  }

  setReportId(reportId) {
    if (this.organizationId) this.reportId = reportId;
  }
}
