<app-document-inspector
  [isCheckbox]="true"
  [openModal]="showModal"
  [fileIndex]="fileIndex"
  [fileURL]="fileURL"
  [dataGroupFiles]="dataGroupFiles"
  [tabDataIndex]="tabDataIndex"
  (closeModal)="hideModal()"
  (showTrainingDataChange)="onShowTrainingDataChange($event)"
  [customClass]="'initial-document'"
  [criteriaLookup]="criteriaLookup"
  (saveEvaluationData)="handleSaveEvaluationData($event)"
  [requestStatus]="requestStatus"
  #inspectorComponent
>
  <div *ngIf="showTrainingData" class="tab-data training-program__data">
    <div class="training-body__title">بيانات البرنامج التدريبي</div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="document-icon"></app-svg-icon>
          <div class="field-name">اسم البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.nameAr }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="document-icon"></app-svg-icon>
          <div class="field-name">اسم البرنامج التدريبي باللغة الإنجليزية</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.nameEn }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="note-icon"></app-svg-icon>
          <div class="field-name">نوع البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.trainingProgramType }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="badge-icon"></app-svg-icon>
          <div class="field-name">مستوى البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.programLevelName }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
          <div class="field-name">مدة البرنامج التدريبي (بالأيام)</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.trainingProgramDuration }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="clock-icon"></app-svg-icon>
          <div class="field-name">عدد الساعات التدريبية الإجمالية</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.totalTrainingHours }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="target-icon"></app-svg-icon>
          <div class="field-name">الهدف العام من البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.programObjective }}
        </div>
      </div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="globe-icon"></app-svg-icon>
          <div class="field-name">لغة البرنامج التدريبي</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.languageLookupName }}
        </div>
      </div>
    </div>
    <div class="row-data">
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="users-icon"></app-svg-icon>
          <div class="field-name">الفئة المستهدفة</div>
        </div>
        <div class="field-value">
          {{ requestData.trainingProgram.targetCategory }}
        </div>
      </div>
    </div>
    <div>
      <div class="training-body__info">
        <div class="field-container">
          <app-svg-icon class="field-icon" name="book-icon"></app-svg-icon>
          <div class="field-name">وصف البرنامج التدريبي</div>
        </div>
        <div class="field-value training-program-description">
          {{ requestData.trainingProgram.description }}
        </div>
      </div>
    </div>
  </div>
</app-document-inspector>
<div class="container" *ngIf="!showModal">
  <h1>التقييم الأولي</h1>
  <div
    class="documents-container"
    *ngFor="let data of dataGroupFiles; let dataIndex = index"
  >
    <div class="files-container">
      <div class="label-container">
        <div class="file-label">{{ data.programDocumentLookup.name }}</div>
        <ul class="field-subtitles">
          <li
            *ngFor="
              let subtitle of data.programDocumentLookup
                .programDocumentRequirements
            "
          >
            {{ subtitle }}
          </li>
        </ul>
      </div>
      <div class="file-icons-list-container">
        <file-icon
          *ngIf="!data.files.length"
          [file]="{ name: data.programDocumentLookup.name, size: 10000 }"
          (click)="
            tabDataIndex = dataIndex;
            showTrainingData = true;
            showModal = true;
            inspectorComponent.tabDataIndex = dataIndex
          "
          [readOnly]="true"
        ></file-icon>
        <file-icon
          *ngFor="let file of data.files; let i = index"
          (click)="viewFile(dataIndex, i)"
          [file]="file"
          [index]="i"
          [readOnly]="true"
        ></file-icon>
      </div>
    </div>
    <accordion
      *ngIf="
        accordionVisibilityHandler(
          data.programDocumentLookup.programDocumentCriteriaMappings
        )
      "
      [header]="'معايير ' + data.programDocumentLookup.name"
      customClass="criteria"
    >
      <div
        class="criteria-container"
        *ngFor="
          let criteria of criteriaFilter(
            data.programDocumentLookup.programDocumentCriteriaMappings
          )
        "
      >
        <div *ngIf="criteria.isPassed" class="criteria-label">
          {{ criteria.documentCriteriaLookup?.name }}
          <img src="assets/images/check.svg" *ngIf="criteria.isPassed" />
        </div>
      </div>
    </accordion>
    <accordion
      [customClass]="'notes-accordion'"
      *ngIf="getDocumentNote(data)?.length"
      [header]="'ملاحظات أخرى'"
    >
      <div class="user-notes">
        <div
          *ngFor="let note of getDocumentNote(data)"
          class="user-note"
          [class.empty]="!note.note"
        >
          <app-comment-section [data]="note"></app-comment-section>
        </div>
      </div>
    </accordion>
  </div>
  <check-box
    label="هذا الطلب بحاجة زيارة ميدانية"
    [control]="expertForm.get('needsFieldVisit')"
  ></check-box>

  <div
    class="field-visit-container"
    *ngIf="expertForm.get('needsFieldVisit').value"
  >
    <div class="field-visit-grid">
      <div class="field-visit-criteria-column">
        <h3>
          المعيار
          <span class="red-star"> * </span>
        </h3>

        <text-field [control]="fieldVisitForm.get('criteria_1')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_2')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_3')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_4')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_5')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_6')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_7')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_8')"></text-field>
        <text-field [control]="fieldVisitForm.get('criteria_9')"></text-field>
      </div>
      <div class="field-visit-evaluation-column">
        <h3>التقييم</h3>
        <text-field
          [control]="fieldVisitForm.get('criteria_1_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_2_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_3_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_4_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_5_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_6_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_7_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_8_evaluation')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_9_evaluation')"
        ></text-field>
      </div>
      <div class="field-visit-notes-column">
        <h3>الملاحظات</h3>
        <text-field
          [control]="fieldVisitForm.get('criteria_1_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_2_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_3_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_4_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_5_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_6_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_7_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_8_notes')"
        ></text-field>
        <text-field
          [control]="fieldVisitForm.get('criteria_9_notes')"
        ></text-field>
      </div>
    </div>
  </div>
  <check-box
    label="ملاحظات عامة"
    [control]="expertForm.get('hasGeneralNotes')"
  ></check-box>
  <text-area [control]="expertForm.get('generalNotes')"></text-area>
  <app-custom-button
    [disabled]="isButtonDisabled()"
    (click)="submitHandler()"
    [buttonText]="'إرسال الطلب إلى أمين اللجنة'"
    [buttonStyle]="'greenButton'"
  ></app-custom-button>
  <div class="success-modal__container">
    <app-modal
      [modalContent]="'submission'"
      [showModal]="showSubmitModal"
      (closeModal)="closeModel()"
      [customClass]="'success-modal'"
      [isCloseVisible]="submittedSuccessfully"
    >
      <loading-spinner>جاري إرسال الطلب...</loading-spinner>
      <div *ngIf="submittedSuccessfully" class="modal-body">
        <app-svg-icon class="success-icon" name="success-icon"></app-svg-icon>
        <span class="modal-text">{{ 'تم إرسال الطلب إلى أمين اللجنة' }}</span>
      </div>
    </app-modal>
  </div>
</div>
