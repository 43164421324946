import { Component } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinner {
  isLoading$ = this.loadingService.isLoading$();

  constructor(private loadingService: LoadingService) {}
}
