export enum Status {
  Draft = 1,
  NewOrderWaitingInternalAuditor = 2,
  ApprovedByTheInternalAuditorWaitingCommitteeSecretary = 3,
  ApprovedByCommitteeSecretaryWaitingTechnicalExpert = 4,
  ApprovedByTechnicalExpertWaitingCommitteeSecretary = 5,
  ApprovedByCommitteeSecretaryWaitingCommitteeMembers = 6,
  ApprovedByCommitteeMembersWaitingCommitteeHead = 7,
  ApprovedByCommitteeHeadWaitingCommitteeSecretary = 8,
  ApprovedByCommitteeSecretaryWaitingPayment = 9,
  ThePaymentWasMadeWaitingFinancialAuditor = 10,
  ApprovedByTheFinancialAuditorWaitingCommitteeSecretary = 11,
  ApprovedByCommitteeSecretaryWaitingGeneralManager = 12,
  ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued = 13,
}
