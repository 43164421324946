import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { Assets } from '@assets/images/index';
import { AuditService } from '@app/dashboard/services/audit.service';
import { DataService } from '@app/dashboard/services/data.service';
import { Status } from '@app/dashboard/types/status';

@Component({
  selector: 'app-information-panel',
  templateUrl: './information-panel.component.html',
  styleUrls: ['./information-panel.component.scss'],
})
export class InformationPanelComponent implements OnInit {
  check = Assets.check;
  activeId = 1;
  informationPanel: any;
  isCertificated =
    Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued;
  isDraft = Status.Draft;

  constructor(
    private auditService: AuditService,
    public dataService: DataService,
    private loadingService: LoadingService,
  ) {}

  isLoading: Observable<boolean>;

  ngOnInit(): void {
    this.loadingService.startLoading();
    this.auditService
      .getTrainingProgramsData({
        pageCount: 1,
        pageSize: 10,
      })
      .subscribe({
        next: (data) => {
          if (data.errorCode === 0) {
            this.informationPanel = data.data;
            this.loadingService.stopLoading();
          }
        },
      });
  }

  setActive(id: number) {
    this.activeId = id;
  }
}
