import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'file-icon',
  templateUrl: './file-icon.component.html',
  styleUrl: './file-icon.component.scss',
})
export class FileIcon {
  @Input() file!: File | any;
  @Input() index: number;
  @Input() readOnly: boolean = false;
  @Input() showIcon: boolean = true;
  @Output() removeFileEmmiter = new EventEmitter<any>();

  constructor() {}
  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;

    return kbSize < 1024
      ? kbSize.toFixed(2) + ' KB'
      : (kbSize / 1024).toFixed(2) + ' MB';
  }
  removeFile() {
    this.removeFileEmmiter.emit(this.index);
  }
}
